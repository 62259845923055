import { cn } from '@/utils';
import { type PropsWithChildren } from 'react';

interface ScreenContainerProps extends PropsWithChildren {
  className?: string;
}

export function ScreenContainer({
  children,
  className,
}: ScreenContainerProps): JSX.Element {
  return (
    <div className={cn('flex h-full w-full flex-col gap-6', className)}>
      {children}
    </div>
  );
}
