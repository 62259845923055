import { PageHeading } from '@/components/base/page-heading';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

export function Summary({
  profitSplit,
  withdrawable,
}: {
  profitSplit?: number | null;
  withdrawable: number;
}): JSX.Element {
  const intl = useIntl();
  const { t } = useTranslation();
  const currency = 'USD';

  function calculateProfitSplit(): number {
    return withdrawable * (profitSplit ?? 0);
  }
  return (
    <div>
      <PageHeading>{t('payout.summary')}</PageHeading>
      <div className="flex w-full flex-col">
        <div
          data-testid="total-profit"
          className="flex w-full items-center justify-between border-b border-default-gray-600 py-2"
        >
          <span className="font-bold">{t('payout.totalProfit')}</span>
          <span>
            {intl.formatNumber(withdrawable ?? 0, {
              style: 'currency',
              currency,
            })}
          </span>
        </div>
        <div
          data-testid="profit-with-profit-split"
          className="flex w-full items-center justify-between border-b border-default-gray-600 py-2"
        >
          <span className="font-bold">{t('payout.profitSplitAmount')}</span>
          <span>
            {intl.formatNumber(calculateProfitSplit() ?? 0, {
              style: 'currency',
              currency,
            })}
          </span>
        </div>
        <div
          data-testid="profit-split"
          className="flex w-full items-center justify-between border-b border-default-gray-600 py-2"
        >
          <span className="font-bold">{t('payout.profitSplit')}</span>
          <span>
            {intl.formatNumber(profitSplit ?? 0, {
              style: 'percent',
              maximumFractionDigits: 2,
            })}
          </span>
        </div>
      </div>
    </div>
  );
}
