import { cn } from '@/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import React from 'react';

export const TableBadgeVariants = cva(
  'flex items-center justify-center rounded-full px-2 text-white text-[10px] font-medium w-fit',
  {
    variants: {
      variant: {
        default: 'bg-default-gray-500',
        red: 'bg-danger',
        orange: 'bg-warning',
        yellow: 'bg-yellow-500',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface TableBadgeProps
  extends VariantProps<typeof TableBadgeVariants> {
  children: React.ReactNode;
  className?: string;
}

export function TableBadge({
  variant,
  children,
  className,
}: TableBadgeProps): JSX.Element {
  return (
    <div>
      <div className={cn(TableBadgeVariants({ variant }), className)}>
        {children}
      </div>
    </div>
  );
}
