import { Badge } from '@/components/base/badge';
import { FormattedDate } from '@/components/base/formatted-date';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CertificateType, type CertificateFragment } from '@graphql/index';
import { createColumnHelper } from '@tanstack/react-table';
import { type IntlShape } from 'react-intl';

const certificateMap = {
  [CertificateType.FundedAccountAchievement]: 'Funded Account Achievement',
  [CertificateType.TotalWithdrawal]: 'Total Withdrawal Achievement',
  [CertificateType.Withdrawal]: 'Withdrawal Achievement',
  [CertificateType.Phase1Achievement]: 'Phase 1 Achievement',
};

const columnHelper = createColumnHelper<CertificateFragment | null>();
export const defaultColumns = (
  intl: IntlShape,
  { t }: { t: (key: string) => string },
) => [
  columnHelper.display({
    id: 'certificate',
    header: t('table.Certificate'),
    cell: (info) => {
      const { type, id } = info.row.original ?? {};
      return (
        <div className="flex flex-col gap-1">
          <span className="text-default-gray-950 dark:text-white">
            {type && certificateMap[type]}
          </span>
          <span className="text-xs">
            {t('table.CertificateID')} {id}
          </span>
        </div>
      );
    },
  }),
  columnHelper.accessor('createdAt', {
    id: 'createdAt',
    header: () => t('table.Date'),
    cell: (info) => <FormattedDate value={info.getValue()} />,
    enableGlobalFilter: false,
    enableColumnFilter: false,
  }),

  columnHelper.accessor('metadata.amount', {
    id: 'metadata.amount',
    header: () => t('table.Amount'),
    cell: (info) => (
      <Badge variant="green" className="px-2">
        <span className="text-lg font-bold text-default-gray-950 dark:text-white">
          {' '}
          {info.getValue() !== undefined
            ? intl.formatNumber(info.getValue() ?? 0, {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
              })
            : '-'}
        </span>
      </Badge>
    ),
  }),

  columnHelper.display({
    id: 'icons',
    header: () => t('table.Certificate'),
    cell: (props) => (
      <a
        className="text-default-gray-950 dark:text-white"
        rel="noopener"
        target="_blank"
        href={props.row.original?.privateUrl}
        download="certificate.png"
      >
        <FontAwesomeIcon className="h-4" icon={faArrowUpRightFromSquare} />
      </a>
    ),
  }),
];
