import { useTranslation } from 'react-i18next';

export interface Resource {
  id: string;
  title: string;
  pages?: number;
  youtube?: string;
  type: ResourceType;
  duration?: number;
  application?: string;
  mb?: number;
  downloadUrl?: string;
}

export enum ResourceType {
  EBook = 'E-Book',
  Video = 'Video',
  Metatrader = 'Metatrader',
  Podcast = 'Podcast',
}

export type TabsData = Record<string, Resource[]>;

export type OpenSections = Record<string, boolean>;

export const useResourceObject = () => {
  const { t } = useTranslation();
  return {
    tabsData: {
      [t('All')]: [] as Resource[],
      Metatrader5: [
        {
          id: '1',
          title: 'Metatrader for Android',
          type: ResourceType.Metatrader,
          application: 'Application',
          downloadUrl:
            'https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&referrer=ref_id%3D5189196426676839094%26server%3DMonevisBrokers-Live&pli=1',
        },
        {
          id: '2',
          title: 'Metatrader for iPhone',
          type: ResourceType.Metatrader,
          application: 'Application',
          downloadUrl: 'https://apps.apple.com/us/app/metatrader-5/id413251709',
        },
        {
          id: '3',
          title: 'Metatrader for Mac',
          type: ResourceType.Metatrader,
          application: 'Application',
          downloadUrl: 'https://apps.apple.com/us/app/metatrader-5/id413251709',
        },
        {
          id: '4',
          title: 'Metatrader for Windows',
          type: ResourceType.Metatrader,
          application: 'Application',
          downloadUrl:
            'https://download.metatrader.com/cdn/web/monevis.brokers.ltd/mt5/monevisbrokers5setup.exe',
        },
      ],
      [t('Videos')]: [
        {
          id: '1',
          title:
            '$8446.68 Monevis Payout With Live Withdrawal & Proof [MUST WATCH] (FUNDED TRADER]',
          youtube:
            'https://www.youtube.com/embed/29CvVSY1gI8?si=ZcwEtMR2Hqpm3eOl',
          type: ResourceType.Video,
          duration: 9,
          downloadUrl: 'https://example.com/placeholder.pdf',
        },
        {
          id: '2',
          title:
            '$10,227.90 Payout Request + $5000 W/$475K Challenges Giveaway ($2M Funding W/Monevis) [EP 3]:',
          youtube:
            'https://www.youtube.com/embed/Y5Nh2c7Cr4Q?si=Ru16Vz7Rc-GX8UDE',
          type: ResourceType.Video,
          duration: 9,
          downloadUrl: 'https://example.com/placeholder.pdf',
        },
        {
          id: '3',
          title:
            '$200K Passed W/Monevis, EURUSD Trade Breakdown And Monevis Brand Ambassador [Forex Trading]',
          youtube:
            'https://www.youtube.com/embed/MiurRiu54Gc?si=GOco61Qh72Ah4x5F',
          type: ResourceType.Video,
          duration: 8,
          downloadUrl: 'https://example.com/placeholder.pdf',
        },
        {
          id: '4',
          title:
            'I Made $62,000 Trading GBPJPY W/Monevis + Trade Break-down (Swing Trading Strategy) [EP 4]',
          youtube:
            'https://www.youtube.com/embed/mcLE4ESkrVs?si=5v24b2C2gTLLeUCH',
          type: ResourceType.Video,
          duration: 10,
          downloadUrl: 'https://example.com/placeholder.pdf',
        },
        {
          id: '5',
          title:
            '7% Gains XAUUSD/GOLD + Full Trade Analysis EURUSD & GBPUSD ($2M Funding W/Monevis)[EP 2]',
          youtube:
            'https://www.youtube.com/embed/IchM8lcDlkE?si=HxFJqS1kIQXGJywu',
          type: ResourceType.Video,
          duration: 12,
          downloadUrl: 'https://example.com/placeholder.pdf',
        },
        {
          id: '6',
          title:
            'XAUUSD/GOLD Full Trade Analysis With Live Trading [$2M Funding W/Monevis]',
          youtube:
            'https://www.youtube.com/embed/tmvHMWxnXRM?si=aGyohu5zYIvMawrs',
          type: ResourceType.Video,
          duration: 11,
          downloadUrl: 'https://example.com/placeholder.pdf',
        },
        {
          id: '7',
          title:
            'A Week In A Life Of A Millionaire Funded Trader [Monevis Funding]',
          youtube:
            'https://www.youtube.com/embed/HT4IORNrMiM?si=I03yipPVhqSZIdAw',
          type: ResourceType.Video,
          duration: 35,
          downloadUrl: 'https://example.com/placeholder.pdf',
        },
        {
          id: '8',
          title:
            '$2M FUNDING UPDATE- $100K FUNDED WITH MONEVIS + CPI RESULTS AND PAYOUT WITH PROOF',
          youtube:
            'https://www.youtube.com/embed/4WcHniffVn0?si=hUAIlShLyJdRf8H4',
          type: ResourceType.Video,
          duration: 9,
          downloadUrl: 'https://example.com/placeholder.pdf',
        },
      ],
    } as TabsData,
    openSections: {
      [t('Videos')]: true,
      Metatrader5: true,
    } as OpenSections,
  };
};
