import { Button } from '@/components/base/button';
import { Command, CommandGroup, CommandItem } from '@/components/base/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/base/popover';
import { cn } from '@/utils';
import { faCheck, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

interface ComboboxProps {
  items: { value: string; label: string }[];
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}
export function Combobox({
  items,
  value,
  onChange,
  placeholder,
}: ComboboxProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const selectedLabel = value
    ? items.find(
        (item) => item.value.toLocaleLowerCase() === value.toLocaleLowerCase(),
      )?.label
    : placeholder;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          data-testid="combobox"
          role="combobox"
          aria-expanded={open}
          className="w-full min-w-24 gap-2 border-none px-3 py-2 dark:bg-divider-900 hover:dark:bg-divider-900"
        >
          {selectedLabel}
          <FontAwesomeIcon
            className="ml-2 h-4 w-4 shrink-0 opacity-50"
            icon={faChevronDown}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        data-testid="combobox-content"
        className="w-full min-w-24 p-0"
      >
        <Command>
          <CommandGroup>
            {items.map((item) => (
              <CommandItem
                key={item.value}
                value={item.value}
                onSelect={(currentValue) => {
                  onChange(currentValue);
                  setOpen(false);
                }}
              >
                <FontAwesomeIcon
                  className={cn(
                    'mr-2 h-4 w-4',
                    value.toLocaleLowerCase() === item.value.toLocaleLowerCase()
                      ? 'block'
                      : 'hidden',
                  )}
                  icon={faCheck}
                />

                {item.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
