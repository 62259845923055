import { Badge } from '@/components/base/badge';
import Section from '@/components/base/section';
import { iconVariantMap } from '@/utils/challenge-config';
import i18n from '@/utils/i18n';
import {
  MonevisAnalysisResultOutcome,
  type MonevisAnalysisRuleFragment,
} from '@graphql/index';
import { formatDuration } from 'date-fns';
import * as locales from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import {
  ChallengeProgressBar,
  type ProgressVariant,
} from './challenge-progress-bar';

export interface ChallengeCardProps {
  analysis: MonevisAnalysisRuleFragment;
  outcome: MonevisAnalysisResultOutcome;
  trialDays?: number;
}

type AnalysisType = 'LIMIT' | 'TARGET';

export function CountdownTimer(): JSX.Element {
  const [timeLeft, setTimeLeft] = useState<string>('');
  useEffect(() => {
    function calculateTimeLeft(): void {
      const now = new Date();
      const _localTime = new Date();
      const targetTime = Date.UTC(
        _localTime.getFullYear(),
        _localTime.getMonth(),
        _localTime.getDate(),
        21,
        5,
      );

      let difference = Number(targetTime) - Number(now);

      if (difference < 0) {
        difference += 24 * 60 * 60 * 1000;
      }

      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTimeLeft(
        `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
          2,
          '0',
        )}:${String(seconds).padStart(2, '0')}`,
      );
    }

    const interval = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <>{timeLeft}</>;
}

export function ChallengeCard({
  analysis,
  outcome,
  trialDays,
}: ChallengeCardProps): JSX.Element {
  const intl = useIntl();
  const currency = 'USD';
  const { t } = useTranslation();

  const isDrawdown =
    analysis.identifier === 'MaxBalanceDailyDrawdown' ||
    analysis.identifier === 'MaxStaticDrawdown';
  const currentPercentage = Number(
    isDrawdown
      ? 0 - analysis.currentPercentageValue
      : analysis.currentPercentageValue,
  );
  const targetPercentage = Number(
    isDrawdown
      ? 0 - analysis.targetPercentageValue
      : analysis.targetPercentageValue,
  );
  const currentValue = Number(
    isDrawdown
      ? 0 - analysis.currentRelativeValue
      : analysis.currentRelativeValue,
  );
  const targetValue = Number(
    isDrawdown
      ? 0 - analysis.targetRelativeValue
      : analysis.targetRelativeValue,
  );

  function getData(): {
    title: string;
    percentage: number;
    type: AnalysisType;
    maxPercentage: number;
    basePercentage: number;
    progressVariant: ProgressVariant;
  } {
    const fullPercentage = Number(
      (
        (analysis.currentPercentageValue / analysis.targetPercentageValue) *
        100
      ).toFixed(2),
    );

    let progressVariant: ProgressVariant = 'green';

    switch (analysis.identifier) {
      case 'MaxBalanceDailyDrawdown':
      case 'MaxStaticDrawdown':
        if (fullPercentage > 75) {
          progressVariant = 'red';
        } else if (fullPercentage > 25) {
          progressVariant = 'yellow';
        } else {
          progressVariant = 'green';
        }
        return {
          title:
            analysis.identifier === 'MaxBalanceDailyDrawdown'
              ? t('challengeCard.maxDailyDrawdown')
              : t('challengeCard.maxDrawdown'),
          type: 'LIMIT',
          percentage: 100 - fullPercentage < 0 ? 0 : 100 - fullPercentage,
          maxPercentage: 0,
          basePercentage: targetPercentage,
          progressVariant,
        };

      case 'TargetReturn':
        if (fullPercentage > 75) {
          progressVariant = 'green';
        } else if (fullPercentage > 25) {
          progressVariant = 'blue';
        } else {
          progressVariant = 'red';
        }
        return {
          title: t('challengeCard.targetProfit'),
          percentage: fullPercentage,
          type: 'TARGET',
          maxPercentage: analysis.targetPercentageValue,
          basePercentage: 0,
          progressVariant,
        };
      default:
        return {
          title: '',
          percentage: 0,
          type: 'LIMIT',
          maxPercentage: 0,
          basePercentage: 0,
          progressVariant: 'green',
        };
    }
  }

  const { badgeVariant, badgeIcon } = iconVariantMap[analysis.result];

  return (
    <div data-testid={analysis.identifier}>
      <Section variant="secondary">
        <div className="flex h-fit w-full flex-col gap-3">
          <div className="flex w-full items-center gap-2">
            <Badge
              className="px-1 py-1"
              variant={badgeVariant}
              iconRight={badgeIcon}
              iconRightClassName="ml-0"
              data-testid={badgeVariant}
            />
            <span className="flex-1 text-sm font-medium text-default-gray-600 dark:text-default-gray-200">
              {getData().title}
            </span>
            {analysis.identifier === 'MaxBalanceDailyDrawdown' &&
              outcome === MonevisAnalysisResultOutcome.Evaluating && (
                <div data-testid="restart-countdown">
                  <Badge className="px-2 py-1" variant="yellow">
                    {t('challengeCard.resetsIn')} <CountdownTimer />
                  </Badge>
                </div>
              )}
            {Boolean(trialDays) &&
              analysis.identifier === 'TargetReturn' &&
              outcome === MonevisAnalysisResultOutcome.Evaluating && (
                <div data-testid="trial-countdown">
                  <Badge variant="yellow" className="text-white">
                    <span className="px-2 py-1 text-xs">
                      {t('challengeCard.trialEnds')}
                      {formatDuration(
                        { days: trialDays },
                        {
                          locale: (
                            locales as Record<
                              string,
                              locales.Locale | undefined
                            >
                          )[i18n.language],
                        },
                      )}
                    </span>
                  </Badge>
                </div>
              )}
          </div>

          <div className="grid grid-cols-3 gap-3 sm:gap-7">
            <div className="col-span-2 flex w-full flex-col">
              <div className="flex w-full justify-between text-sm font-medium">
                <div className="flex flex-col gap-1 text-start">
                  <span className="text-default-gray-500">
                    {t('challengeCard.current')}
                  </span>
                  <span className="text-black dark:text-white">
                    {intl.formatNumber(currentValue, {
                      style: 'currency',
                      currency,
                    })}
                  </span>
                  <span className="text-default-gray-500">
                    {intl.formatNumber(Number(currentPercentage / 100), {
                      style: 'percent',
                      minimumFractionDigits: 1,
                    })}
                  </span>
                </div>
                <div className="flex flex-col gap-1 text-end">
                  <span className="text-default-gray-500">
                    {getData().type === 'LIMIT'
                      ? t('challengeCard.limit')
                      : t('challengeCard.target')}
                  </span>
                  <span className="text-black dark:text-white">
                    {intl.formatNumber(targetValue, {
                      style: 'currency',
                      currency,
                    })}
                  </span>
                  <span className="text-default-gray-500">
                    {intl.formatNumber(Number(targetPercentage / 100), {
                      style: 'percent',
                    })}
                  </span>
                </div>
              </div>
            </div>
            <div className="relative mx-auto w-fit">
              <ChallengeProgressBar
                data-testid="challenge-progress-bar"
                variant={getData().progressVariant}
                percentage={Math.floor(getData().percentage)}
                maxPercentage={getData().maxPercentage}
                basePercentage={getData().basePercentage}
                displayedPercentage={intl.formatNumber(currentPercentage, {
                  maximumFractionDigits: 2,
                })}
              />
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}

ChallengeCard.displayName = 'ChallengeCard';
