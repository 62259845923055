import type { SVGProps } from 'react';

export default function SvgCzechia(
  props: SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
      <path fill="#c62d25" d="M1 24a4 4 0 0 0 4 4h22a4 4 0 0 0 4-4v-9H1z" />
      <path fill="#fff" d="M27 4H5a4 4 0 0 0-4 4v8h30V8a4 4 0 0 0-4-4" />
      <path
        fill="#1e427b"
        d="M2.316 26.947 16 16 2.316 5.053A3.98 3.98 0 0 0 1 8v16c0 1.172.513 2.216 1.316 2.947"
      />
      <path
        d="M27 4H5a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h22a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4m3 20c0 1.654-1.346 3-3 3H5c-1.654 0-3-1.346-3-3V8c0-1.654 1.346-3 3-3h22c1.654 0 3 1.346 3 3z"
        opacity={0.15}
      />
      <path
        fill="#fff"
        d="M27 5H5a3 3 0 0 0-3 3v1a3 3 0 0 1 3-3h22a3 3 0 0 1 3 3V8a3 3 0 0 0-3-3"
        opacity={0.2}
      />
    </svg>
  );
}
