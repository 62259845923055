import { Pdf, Speaker, Youtube } from '@/components/icons';
import { faArrowDownToLine, faPlay } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

interface ResourceCardProps {
  title: string;
  mb?: number;
  pages?: number;
  youtube?: string;
  type: 'E-Book' | 'Video' | 'Metatrader' | 'Podcast';
  duration?: number;
  application?: string;
  downloadUrl?: string;
}

const iconMap: Record<string, JSX.Element> = {
  'E-Book': <Pdf />,
  Podcast: <Speaker />,
  Video: <Youtube />,
  Metatrader: (
    <img src="/images/meta-trader.png" className="h-6 w-6" alt="metatrader" />
  ),
};

export function formatMinutes(totalMinutes: number): string {
  const numberFormatter = new Intl.NumberFormat('en', {
    minimumIntegerDigits: 1,
    maximumFractionDigits: 0,
  });

  const hours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;
  const hoursText =
    hours > 0
      ? `${numberFormatter.format(hours)} hour${hours > 1 ? 's' : ''}`
      : '';
  const minutesText =
    remainingMinutes > 0
      ? `${numberFormatter.format(remainingMinutes)} minute${remainingMinutes > 1 ? 's' : ''}`
      : '';
  return [hoursText, minutesText].filter(Boolean).join(' ');
}

function ResourceCard({
  title,
  mb,
  pages,
  youtube,
  type,
  duration,
  application,
  downloadUrl,
}: ResourceCardProps): JSX.Element {
  const { t } = useTranslation();
  function getPicture(): JSX.Element | null {
    return iconMap[type] || null;
  }

  let cardText = '';
  if (type === 'E-Book' && pages && mb) {
    cardText = `${mb} MB - ${pages} ${t('resource.pages')}`;
  } else if (type === 'Video' && duration) {
    cardText = `Video - ${formatMinutes(duration)}`;
  } else if (type === 'Podcast' && mb && duration) {
    cardText = `${mb} MB - ${formatMinutes(duration)}`;
  } else if (type === 'Metatrader' && application) {
    cardText = application;
  }

  return (
    <div className="flex flex-col gap-3 rounded-2xl border bg-white p-5 dark:border-charade-950/50 dark:bg-charade-950/50 dark:text-white">
      {youtube && (
        <div className="h-52">
          <iframe
            className="h-full w-full"
            src={youtube}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </div>
      )}
      <div className="flex items-center gap-5">
        <div className="flex h-12 w-11 items-center justify-center rounded-sm border border-default-gray-300 bg-charade-200 dark:border-default-gray-800 dark:bg-charade-950">
          {getPicture()}
        </div>
        <div className="flex w-full flex-row items-center justify-between gap-3">
          <div className="flex flex-col justify-center">
            <h1 className="line-clamp-2 text-sm font-bold">{title}</h1>
            <p className="text-xs text-default-gray-400">{cardText}</p>
          </div>
          {!youtube && downloadUrl && (
            <div className="flex h-full items-end gap-5">
              <a href={downloadUrl} target="_blank" rel="noopener" download>
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={type === 'Podcast' ? faPlay : faArrowDownToLine}
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ResourceCard;
