import { useAppConfig } from '@/context/app-config-context';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export function TrustBox(): JSX.Element {
  const { isDark } = useAppConfig();
  const { t } = useTranslation();
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window?.Trustpilot) {
      window?.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [isDark]);

  return (
    <div
      className="trustpilot-widget"
      data-businessunit-id="65e0acea6a7decc57bc5daf5"
      data-locale="en-US"
      data-style-height="20px"
      data-style-width="100%"
      data-template-id="5419b732fbfb950b10de65e5"
      data-theme={isDark ? 'dark' : 'light'}
      ref={ref}
    >
      <a
        href="https://www.trustpilot.com/review/monevis.com"
        rel="noopener"
        target="_blank"
      >
        {t('trustBox.linkText')}
      </a>
    </div>
  );
}
