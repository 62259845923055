import { cn } from '@/utils';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type { HTMLAttributes, ReactNode } from 'react';

const HeadingVariants = cva('dark:text-white text-default-gray-950', {
  variants: {
    size: {
      default: 'text-xl font-semibold mb-1',
      small: 'text-sm font-semibold mb-1',
      xxlarge: 'text-2xl font-bold mb-1',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

const SubheadingVariants = cva(
  'dark:text-default-gray-200 text-default-gray-900 ',
  {
    variants: {
      size: {
        default: 'text-sm',
        small: 'text-sm font-normal',
        xxlarge: 'text-sm font-normal dark:text-default-gray-100',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
);

export interface PageHeadingProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof HeadingVariants> {
  children?: ReactNode;
  subheading?: string | ReactNode;
  className?: string;
  subheadingClassName?: string;
}

export function PageHeading({
  size,
  children,
  subheading,
  subheadingClassName,
  className,
}: PageHeadingProps): JSX.Element {
  return (
    <div className={cn('flex flex-col', className)}>
      <div className={cn(HeadingVariants({ size }), 'text-2')}>{children}</div>

      {subheading && (
        <h2 className={cn(SubheadingVariants({ size }), subheadingClassName)}>
          {subheading}
        </h2>
      )}
    </div>
  );
}

PageHeading.displayName = 'PageHeading';
