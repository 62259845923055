import React, { Children, cloneElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextField, type TextFieldProps } from './text-field';

export interface FormFieldProps extends TextFieldProps {
  name: string;
  children?: React.ReactNode;
  'error-data-testid'?: string;
  'data-testid'?: string;
}

export function FormField({
  name,
  children,
  'error-data-testid': errorDataTestId = 'error-data-test-id',
  'data-testid': dataTestId = 'field-data-test-id',
  ...props
}: FormFieldProps): JSX.Element {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const childrenItems = Children.map(children, (child) => {
    return cloneElement(child as React.ReactElement, {
      ...register(name),
    });
  });

  return (
    <TextField
      data-testid={dataTestId}
      error-data-testid={errorDataTestId}
      error={errors[name]?.message ? String(errors[name]?.message) : undefined}
      {...props}
    >
      {childrenItems}
    </TextField>
  );
}
