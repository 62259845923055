import Section from '@/components/base/section';
import { Skeleton } from '@/components/base/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/base/table';
import { cn } from '@/utils';
import {
  OrderStatus,
  useGetOrdersQuery,
  type OrderResponseFragment,
} from '@graphql/index';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { isAfter } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import invariant from 'tiny-invariant';
import { defaultColumns } from './columns';

export function OrdersTable({
  orders,
}: {
  orders: OrderResponseFragment[];
}): JSX.Element {
  const intl = useIntl();
  const { t } = useTranslation();
  const columns = useMemo(() => defaultColumns(intl, { t }), [intl, t]);
  const table = useReactTable({
    data: orders,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: 'createdAt',
          desc: true,
        },
      ],
    },
  });

  return (
    <Section className="border-default-gray-300 p-0 sm:p-0" variant="outline">
      <Table className="bg-default-gray-100 dark:bg-default-gray-950">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              className="[&>*:first-child]:pl-5 [&>*:last-child]:pr-5"
              id={headerGroup.id}
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => (
                <TableHead
                  key={header.id}
                  className={cn('w-[100px] px-3 py-4 sm:px-6', {
                    'text-right': header.column.id === 'invoice',
                  })}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow
              className="text-xs text-default-gray-600 dark:text-default-gray-400 sm:text-sm"
              id={row.id}
              key={row.id}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell
                    className={cn('whitespace-nowrap px-3 py-4 sm:px-6', {
                      'text-right': cell.column.id === 'invoice',
                    })}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>

        {table.getRowModel().rows.length === 0 && (
          <TableRow className="border-none">
            <TableCell
              colSpan={columns.length}
              className="whitespace-nowrap px-3 py-3 sm:px-6"
            >
              <div className="flex w-full items-center justify-center text-default-gray-950 dark:text-white">
                <span>{t('calendar.noData')}</span>
              </div>
            </TableCell>
          </TableRow>
        )}
      </Table>
    </Section>
  );
}

export function OrdersTableWithData(): JSX.Element {
  const { data, loading } = useGetOrdersQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <div className="h-full max-h-60 w-full">
        <Skeleton className="h-full w-full rounded-2xl" />
      </div>
    );
  }

  invariant(data?.orders, 'Orders data is missing');

  const filteredOrders = data.orders.filter((order: OrderResponseFragment) => {
    if (order.status === OrderStatus.Completed) {
      return true;
    }
    if (!order.expiresAt) {
      return false;
    }
    const expiresAtDate = new Date(order.expiresAt);
    return isAfter(expiresAtDate, new Date());
  });

  return <OrdersTable orders={filteredOrders} />;
}
