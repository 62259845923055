import { RequestPayoutWithData } from '@/components/(authenticated)/request-payout';
import { PageWrapper } from '@/components/base/page-wrapper';
import { usePageLayout } from '@/context/layout-context';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const routeSchema = z.object({
  login: z.string().optional(),
});

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/request-payout/',
)({
  validateSearch: routeSchema,
  component: () => (
    <PageWrapper title="Payout - Monevis">
      <RequestPayoutPage />
    </PageWrapper>
  ),
});

function RequestPayoutPage(): JSX.Element {
  const { t } = useTranslation();
  usePageLayout({ title: t('requestPayoutPage.title') });
  const { login } = Route.useSearch();
  return <RequestPayoutWithData accountLogin={login} />;
}

export default RequestPayoutPage;
