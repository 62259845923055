import { Button } from '@/components/base/button';
import { Input } from '@/components/base/form/input';
import { TextField } from '@/components/base/form/text-field';
import { useFragment } from '@apollo/client';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CartFragmentDoc, type CartFragment } from '@graphql/index';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function AddDiscountCode({
  handleAddDiscount,
  cartId,
}: {
  cartId: string;
  handleAddDiscount: (code: string) => void;
}): JSX.Element {
  const [discountCode, setDiscountCode] = useState('');
  const { t } = useTranslation();

  const { data: cart } = useFragment<CartFragment>({
    fragment: CartFragmentDoc,
    fragmentName: 'Cart',
    from: {
      __typename: 'Cart',
      id: cartId,
    },
  });

  const hasDiscount =
    cart?.appliedDiscount && String(cart?.appliedDiscount) !== '';

  return (
    <fieldset>
      {hasDiscount && (
        <div className="flex w-full justify-center text-center">
          <div className="uppercase">
            <span>
              {t('addDiscountCode.activeDiscount')} - {` `}
            </span>
            <span className="font-bold">{cart?.appliedDiscount}</span>
            <button
              onClick={() => {
                handleAddDiscount('');
              }}
              type="button"
              className="mx-4 cursor-pointer rounded bg-red-400/10 p-2 text-red-400 transition-all hover:bg-red-400/20 hover:text-red-600"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      )}
      {!hasDiscount && (
        <TextField label={t('addDiscountCode.discountCode')}>
          <Input
            placeholder={t('addDiscountCode.discountPlaceholder')}
            variant="secondary"
            inputSize="sm"
            icon={
              <Button
                className="text-emerald-400"
                onClick={() => {
                  handleAddDiscount(discountCode);
                }}
                variant="link"
                type="button"
              >
                {t('addDiscountCode.apply')}
              </Button>
            }
            onChange={(e) => {
              setDiscountCode(e.target.value);
            }}
            value={discountCode}
          />
        </TextField>
      )}
    </fieldset>
  );
}
