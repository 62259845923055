import { cn } from '@/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import React from 'react';

const BadgeVariants = cva(
  'flex justify-center items-center rounded-xl text-emerald-400 bg-emerald-500/10 w-fit',
  {
    variants: {
      size: {
        default: 'px-2 py-1 text-xs',
        lg: 'py-4 px-5 text-2xl font-bold',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
);

export interface BadgeProps extends VariantProps<typeof BadgeVariants> {
  children: React.ReactNode;
  className?: string;
}

function Badge({ size, children, className }: BadgeProps): JSX.Element {
  return (
    <div>
      <div className={cn(BadgeVariants({ size }), className)}>{children}</div>
    </div>
  );
}
export { Badge, BadgeVariants };
