import { useAppConfig } from '@/context/app-config-context';
import { Switch } from './switch';

export function ThemeSwitcher(): JSX.Element {
  const { isDark, toggleTheme } = useAppConfig();

  return (
    <Switch
      onClick={() => {
        toggleTheme();
      }}
      defaultChecked={isDark}
      checked={isDark}
    />
  );
}
