import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Button } from '../base/button';

export function EmailAuthButton({
  onClick,
}: {
  onClick: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <Button
      as="button"
      onClick={onClick}
      variant="secondary"
      icon={<FontAwesomeIcon className="h-full w-4" icon={faEnvelope} />}
    >
      {t('formLayout.email')}
    </Button>
  );
}
