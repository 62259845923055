import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faClock,
  faLoader,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
  AccountType,
  ChallengeStatus,
  MonevisAnalysisResultOutcome,
} from '@graphql/index';

export const badgeTitleMap = {
  [AccountType.Funded]: 'Funded',
  [AccountType.Challenge]: 'Challenge',
  [AccountType.SharpEdge]: 'Sharp Edge',
  [AccountType.StablePath]: 'Stable Path',
  [AccountType.Trial]: 'Trial',
};

export const progressVariantMap: Record<
  string,
  { badgeVariant: 'red' | 'blue' | 'green'; badgeIcon: IconProp }
> = {
  [MonevisAnalysisResultOutcome.Evaluating]: {
    badgeVariant: 'blue',
    badgeIcon: faClock,
  },
  [MonevisAnalysisResultOutcome.Pass]: {
    badgeVariant: 'green',
    badgeIcon: faCheck,
  },
  [MonevisAnalysisResultOutcome.Fail]: {
    badgeVariant: 'red',
    badgeIcon: faXmark,
  },
};
export const iconVariantMap: Record<
  string,
  { badgeVariant: 'red' | 'blue' | 'green' | 'primary'; badgeIcon: IconProp }
> = {
  [MonevisAnalysisResultOutcome.Evaluating]: {
    badgeVariant: 'blue',
    badgeIcon: faClock,
  },
  [MonevisAnalysisResultOutcome.Pass]: {
    badgeVariant: 'green',
    badgeIcon: faCheck,
  },
  [MonevisAnalysisResultOutcome.Fail]: {
    badgeVariant: 'red',
    badgeIcon: faXmark,
  },
  '': { badgeVariant: 'primary', badgeIcon: faLoader },
};

export const statusMap: Record<
  ChallengeStatus | AccountType.Funded,
  'passed' | 'failed' | 'ongoing' | 'funded'
> = {
  [ChallengeStatus.Passed]: 'passed',
  [ChallengeStatus.Failed]: 'failed',
  [ChallengeStatus.Evaluation]: 'ongoing',
  [AccountType.Funded]: 'funded',
};

export function getCardVariant(
  type: AccountType,
  status: ChallengeStatus,
): ChallengeStatus | AccountType.Funded {
  if (type === AccountType.Funded) {
    if (
      status === ChallengeStatus.Passed ||
      status === ChallengeStatus.Evaluation
    ) {
      return AccountType.Funded;
    }
    return ChallengeStatus.Failed;
  }
  return status;
}
