export const blacklist = [
  'af', // Afghanistan
  'cf', // Central African Republic
  'cg', // Congo (Brazzaville)
  'cd', // Congo (Kinshasa)
  'cu', // Cuba
  'gw', // Guinea-Bissau
  'ir', // Iran
  'iq', // Iraq
  'kp', // North Korea
  'ly', // Libya
  'ml', // Mali
  'ru', // Russian Federation
  'so', // Somalia
  'ss', // South Sudan
  'sd', // Sudan
  'sy', // Syria
  'us', // United States
  'um', // US Minor Outlying Islands
  'vi', // US Virgin Island
  've', // Venezuela
  'ye', // Yemen
];

export interface Country {
  name: string;
  value: string;
}

export const countries = [
  { name: 'Afghanistan', value: 'AF' },
  { name: 'Albania', value: 'AL' },
  { name: 'Algeria', value: 'DZ' },
  { name: 'Andorra', value: 'AD' },
  { name: 'Angola', value: 'AO' },
  { name: 'Argentina', value: 'AR' },
  { name: 'Australia', value: 'AU' },
  { name: 'Austria', value: 'AT' },
  { name: 'Bangladesh', value: 'BD' },
  { name: 'Belgium', value: 'BE' },
  { name: 'Brazil', value: 'BR' },
  { name: 'Canada', value: 'CA' },
  { name: 'China', value: 'CN' },
  { name: 'Colombia', value: 'CO' },
  { name: 'Cuba', value: 'CU' },
  { name: 'Czech Republic', value: 'CZ' },
  { name: 'Denmark', value: 'DK' },
  { name: 'Egypt', value: 'EG' },
  { name: 'Ethiopia', value: 'ET' },
  { name: 'France', value: 'FR' },
  { name: 'Germany', value: 'DE' },
  { name: 'Greece', value: 'GR' },
  { name: 'Hungary', value: 'HU' },
  { name: 'India', value: 'IN' },
  { name: 'Indonesia', value: 'ID' },
  { name: 'Iran', value: 'IR' },
  { name: 'Iraq', value: 'IQ' },
  { name: 'Ireland', value: 'IE' },
  { name: 'Israel', value: 'IL' },
  { name: 'Italy', value: 'IT' },
  { name: 'Japan', value: 'JP' },
  { name: 'Kenya', value: 'KE' },
  { name: 'Mexico', value: 'MX' },
  { name: 'Morocco', value: 'MA' },
  { name: 'Netherlands', value: 'NL' },
  { name: 'New Zealand', value: 'NZ' },
  { name: 'Nigeria', value: 'NG' },
  { name: 'Pakistan', value: 'PK' },
  { name: 'Peru', value: 'PE' },
  { name: 'Philippines', value: 'PH' },
  { name: 'Poland', value: 'PL' },
  { name: 'Portugal', value: 'PT' },
  { name: 'Russia', value: 'RU' },
  { name: 'Saudi Arabia', value: 'SA' },
  { name: 'Slovakia', value: 'SK' },
  { name: 'South Africa', value: 'ZA' },
  { name: 'South Korea', value: 'KR' },
  { name: 'Spain', value: 'ES' },
  { name: 'Sweden', value: 'SE' },
  { name: 'Turkey', value: 'TR' },
  { name: 'Zimbabwe', value: 'ZW' },
];
export const filteredCountriesObject = Object.fromEntries(
  Object.entries(countries).sort(([, countryA], [, countryB]) =>
    countryA.name.localeCompare(countryB.name),
  ),
);

export const isBannedCountry = (country: string) => {
  return blacklist.includes(country?.toLowerCase());
};
