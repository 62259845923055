import type { ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';

const TOKEN_COOKIE_KEY = '__mnvs_token';
interface CredentialsContextProps {
  jwtToken: string | null;
  setToken: (token: string) => void;
  clearToken: () => void;
  hasToken: boolean;
  token?: string | null;
}

export const CredentialsContext = createContext<CredentialsContextProps>({
  jwtToken: null,
  setToken: () => false,
  clearToken: () => false,
  hasToken: false,
  token: null,
});

export function CredentialsProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [cookie, setCookie, removeCookie] = useCookies([TOKEN_COOKIE_KEY]);
  const cookieToken = cookie[TOKEN_COOKIE_KEY] as string;
  const [jwtToken, setJwtToken] = useState<string | null>(cookieToken || null);

  const hasToken = Boolean(jwtToken);

  function setToken(token: string): void {
    setCookie(TOKEN_COOKIE_KEY, token, { path: '/' });
    setJwtToken(token);
  }

  function clearToken(): void {
    removeCookie(TOKEN_COOKIE_KEY, { path: '/' });
    setJwtToken(null);
  }

  const value = useMemo(
    () => ({ jwtToken, setToken, clearToken, hasToken, token: jwtToken }),
    [hasToken, jwtToken],
  );

  return (
    <CredentialsContext.Provider value={value}>
      {children}
    </CredentialsContext.Provider>
  );
}
