import type { SVGProps } from 'react';

export default function SvgArrowdown(
  props: SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={8}
      fill="none"
      {...props}
    >
      <path
        fill="#757B8C"
        d="M5.85 7.59 2.685 2.452a.33.33 0 0 1 .02-.37c.375-.488.756-.924 1.077-1.215 0 0 .256-.25.419-.359.222-.175.512-.259.794-.259.317 0 .616.092.855.276.043.042.231.201.385.351.957.876 2.538 3.164 3.017 4.367.078.175.239.643.248.885 0 .234-.051.46-.163.676a1.4 1.4 0 0 1-.675.593c-.196.075-.786.192-.803.192-.42.075-1.012.132-1.702.16a.35.35 0 0 1-.307-.16"
      />
      <path
        fill="#757B8C"
        d="M4.145 7.246c.14.226-.039.511-.308.5a18 18 0 0 1-1.602-.13c-.009-.01-.743-.126-.992-.26A1.33 1.33 0 0 1 .5 6.17v-.04c.008-.319.29-.995.308-.995.141-.34.381-.785.67-1.264.13-.215.451-.22.584-.005z"
        opacity={0.4}
      />
    </svg>
  );
}
