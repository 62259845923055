import ResourcesList from '@/components/(authenticated)/resources-detail/resources-list';
import { PageWrapper } from '@/components/base/page-wrapper';
import { usePageLayout } from '@/context/layout-context';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/resources',
)({
  component: () => (
    <PageWrapper title="Resources - Monevis">
      <ResourcesPage />
    </PageWrapper>
  ),
});

function ResourcesPage(): JSX.Element {
  const { t } = useTranslation();
  usePageLayout({ title: t('resourcesPage.title') });

  return <ResourcesList />;
}

export default ResourcesPage;
