import type { SVGProps } from 'react';

export default function SvgYoutube(
  props: SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19}
      height={13}
      fill="none"
      {...props}
    >
      <path
        fill="#9399AA"
        d="M16.18 0H2.82A2.813 2.813 0 0 0 0 2.807v7.386A2.813 2.813 0 0 0 2.82 13h13.36A2.813 2.813 0 0 0 19 10.193V2.807A2.813 2.813 0 0 0 16.18 0M7.422 9.455v-5.91L12.469 6.5z"
      />
    </svg>
  );
}
