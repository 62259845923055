import i18n from '@/utils/i18n';
import { formatDuration } from 'date-fns';
import * as locales from 'date-fns/locale';
import { useEffect, useState } from 'react';

function formatNumber(number: number): string {
  if (number < 10) {
    return `0${Number(number).toFixed(0)}`;
  }
  return Number(number).toFixed(0);
}

function formatTime(seconds: number): string {
  const d = Math.floor(seconds / (60 * 60 * 24));
  let left = seconds - d * (60 * 60 * 24);
  const h = Math.floor(left / (60 * 60));
  left = left - h * (60 * 60);
  const m = Math.floor(left / 60);
  left = left - m * 60;
  // const m = Math.floor(seconds / 60);
  // const s = Math.ceil(left * 60);
  const days = formatDuration(
    { days: d },
    {
      locale: (locales as Record<string, locales.Locale | undefined>)[
        i18n.language
      ],
    },
  );

  return `${days} ${formatNumber(h)}:${formatNumber(m)}:${formatNumber(left)}`;
}

export function RestartCountdown({
  time,
}: {
  time?: string | null | undefined;
}): JSX.Element {
  const [remainingSeconds, setRemainingSeconds] = useState(0);

  useEffect(() => {
    tick();

    const interval = setInterval(() => {
      tick();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  function tick(): void {
    const now = new Date();
    const expirationTime = new Date(String(time));
    // expirationTime.setTime(Number((new Date(time)).getTime()));
    const remaining = (expirationTime.getTime() - now.getTime()) / 1000;
    setRemainingSeconds(Math.max(remaining, 0));
  }

  return <>{formatTime(remainingSeconds)}</>;
}
