import { Skeleton } from '@/components/base/skeleton';
import { cn } from '@/utils';
import {
  MonevisAnalysisResultOutcome,
  useGetBrokerAccountStatisticsQuery,
  type MonevisAnalysisRuleFragment,
} from '@graphql/index';
import invariant from 'tiny-invariant';
import { ChallengeCard } from './challenge-card';
import { DaysOfTradingCard } from './days-of-trading-card';

export function AnalysisDetails({
  analysisData,
  outcome,
  accountId,
}: {
  analysisData: (MonevisAnalysisRuleFragment | null)[];
  outcome: MonevisAnalysisResultOutcome;
  accountId: string;
}): JSX.Element {
  const filteredAnalysisData = analysisData.filter(
    (item) =>
      item &&
      ['TargetReturn', 'MaxBalanceDailyDrawdown', 'MaxStaticDrawdown'].includes(
        item.identifier,
      ),
  );

  const runningDays = analysisData?.find(
    (item) => item?.identifier === 'MaxRunningDays',
  );

  function calculateRemainingDays(
    data?: MonevisAnalysisRuleFragment | null,
  ): number {
    const maxDays = data?.targetRelativeValue ?? 0;
    const currentDays = data?.currentRelativeValue ?? 0;
    return maxDays - currentDays;
  }

  const daysOfTradingAnalysis = analysisData.find(
    (item) => item?.identifier === 'MinimumTradingDays',
  );

  const targetReturn = analysisData.find(
    (item) => item?.identifier === 'TargetReturn',
  );

  return (
    <div
      className={cn('flex w-full flex-col gap-5', {
        'opacity-40': outcome === MonevisAnalysisResultOutcome.Fail,
      })}
    >
      <div
        className={cn(
          'grid grid-cols-1 gap-5 sm:grid-cols-2',
          {
            'xl:grid-cols-3': targetReturn,
          },
          {
            'xl:grid-cols-2': !targetReturn,
          },
        )}
      >
        {filteredAnalysisData.map((item) => {
          return (
            <ChallengeCard
              outcome={outcome}
              key={item?.identifier}
              analysis={item!}
              trialDays={calculateRemainingDays(runningDays)}
            />
          );
        })}
      </div>
      {daysOfTradingAnalysis && (
        <DaysOfTradingCard
          analysis={daysOfTradingAnalysis}
          accountId={accountId}
          outcome={outcome}
        />
      )}
    </div>
  );
}

export function AnalysisDetailsWithData({
  accountId,
}: {
  accountId: string;
}): JSX.Element {
  const { data, loading } = useGetBrokerAccountStatisticsQuery({
    variables: { id: accountId },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return (
      <div className="flex w-full flex-col gap-5">
        <div className="grid h-full w-full grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
          <Skeleton className="h-full min-h-40 w-full min-w-80 rounded-xl" />
          <Skeleton className="h-full min-h-40 w-full min-w-80 rounded-xl" />
          <Skeleton className="h-full min-h-40 w-full min-w-80 rounded-xl" />
        </div>

        <Skeleton className="h-full min-h-20 w-full min-w-80 rounded-xl" />
      </div>
    );
  }

  invariant(
    data?.brokerAccountStatistics.stats?.analysis,
    `[${accountId}] Analysis missing`,
  );

  return (
    <AnalysisDetails
      accountId={accountId}
      analysisData={data.brokerAccountStatistics.stats.analysis}
      outcome={data?.brokerAccountStatistics.stats.outcome}
    />
  );
}
