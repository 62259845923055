import { Badge } from '@/components/base/badge';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectTrigger,
} from '@/components/base/form/select';
import { Skeleton } from '@/components/base/skeleton';
import { cn } from '@/utils';
import { iconVariantMap } from '@/utils/challenge-config';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useGetBrokerAccountsQuery,
  type BrokerAccountFragment,
  type MonevisAnalysisResultOutcome,
} from '@graphql/index';
import { Link, useRouterState } from '@tanstack/react-router';
import invariant from 'tiny-invariant';

export function AccountSelectlist({
  brokerAccounts,
  currentAccount,
  outcome,
  className,
}: {
  brokerAccounts: BrokerAccountFragment[];
  currentAccount?: BrokerAccountFragment;
  outcome: MonevisAnalysisResultOutcome;
  className?: string;
}): JSX.Element {
  const router = useRouterState();
  function checkItemActive(to: string): boolean {
    if (router.location.pathname === to) {
      return true;
    }
    return false;
  }

  return (
    <Select>
      <SelectTrigger
        className={cn('h-fit w-fit gap-5 whitespace-nowrap', className)}
        size="sm"
        variant="secondary"
      >
        <div className="flex w-full items-center gap-2">
          <Badge
            className="px-1 py-1"
            variant={iconVariantMap[outcome].badgeVariant}
            iconRightClassName="ml-0"
            iconRight={iconVariantMap[outcome].badgeIcon}
          />
          <span className="text-sm font-bold">
            {currentAccount?.accountType} - {currentAccount?.login}
          </span>
        </div>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup className="flex max-h-40 flex-col gap-2 overflow-y-auto">
          {brokerAccounts.map((account) => (
            <Link
              className="flex items-center gap-2 px-2 py-1 text-sm"
              key={account.id}
              params={{ 'account-id': account.login }}
              to="/dashboard/accounts/$account-id"
            >
              {checkItemActive(`/dashboard/accounts/${account.login}`) && (
                <FontAwesomeIcon icon={faCheck} />
              )}
              <span className="max-w-[300px] truncate md:max-w-none">
                {account.accountName}
              </span>
            </Link>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export function AccountSelectlistWithData({
  accountId,
  outcome,
  className,
}: {
  accountId: string;
  outcome: MonevisAnalysisResultOutcome;
  className?: string;
}): JSX.Element {
  const { data, loading } = useGetBrokerAccountsQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <Skeleton className="h-full min-h-8 w-full min-w-24" />;
  }
  invariant(
    data?.me.brokerAccounts,
    `[${accountId}] Select list data is missing`,
  );
  const currentAccount = data?.me.brokerAccounts.find(
    (account) => account.login === accountId,
  );

  return (
    <AccountSelectlist
      brokerAccounts={data?.me.brokerAccounts}
      currentAccount={currentAccount}
      outcome={outcome}
      className={className}
    />
  );
}
