import { cn } from '@/utils';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import * as React from 'react';

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-2', className)}
      {...props}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

type RadioGroupItemProps = React.ComponentPropsWithoutRef<
  typeof RadioGroupPrimitive.Item
> & {
  decoration?: React.ReactNode;
};

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemProps
>(({ className, decoration, children, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        'group relative flex h-full w-full items-center justify-between gap-2 rounded-lg border border-default-gray-400 px-4 py-3 text-slate-900 ring-offset-white focus:border-green-400 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-emerald-400 dark:border-white dark:text-slate-50 dark:focus:border-emerald-400 data-[state=checked]:dark:border-emerald-400',
        className,
      )}
      {...props}
    >
      <span className="w-full text-start group-data-[state=checked]:text-emerald-400 dark:text-slate-50 group-data-[state=checked]:dark:text-emerald-400">
        {children}
      </span>
      <div className="flex items-center gap-3">
        <RadioGroupPrimitive.Indicator className="absolute right-1 top-1 flex items-center justify-center text-emerald-400">
          <FontAwesomeIcon className="text-xl" icon={faCircleCheck} />
        </RadioGroupPrimitive.Indicator>
        {decoration}
      </div>
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
