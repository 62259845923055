import { useAppConfig } from '@/context/app-config-context';

interface MetatraderProps {
  accountId?: string;
}

function getTerminalUrl(login?: string, isDarkMode?: boolean): string {
  const baseUrl = 'https://trade.monevis.com/terminal';
  const themeMode = isDarkMode ? '1' : '0';

  if (login) {
    return `${baseUrl}/?theme-mode=${themeMode}&login=${login}`;
  }

  return `${baseUrl}/?theme-mode=${themeMode}`;
}

export function Metatrader({ accountId }: MetatraderProps): JSX.Element {
  const { isDark } = useAppConfig();
  return (
    <div className="h-full">
      <iframe
        className="h-full w-full rounded-2xl"
        src={getTerminalUrl(accountId, isDark)}
        title="Terminal"
      />
    </div>
  );
}
