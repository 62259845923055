import type { SVGProps } from 'react';

export default function SvgTimebomb(
  props: SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={39}
      height={47}
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.083.417a1 1 0 1 0 0 2H18.5v6.86c-4.401.232-8.4 1.99-11.473 4.752l-2.278-2.277a1 1 0 1 0-1.414 1.414l2.277 2.278A18.6 18.6 0 0 0 .833 27.917c0 10.31 8.358 18.667 18.667 18.667s18.667-8.358 18.667-18.667c0-4.795-1.809-9.168-4.78-12.473l2.279-2.278a1 1 0 0 0-1.415-1.414l-2.278 2.277A18.6 18.6 0 0 0 20.5 9.277v-6.86h3.417a1 1 0 1 0 0-2h-8.834M7.715 16.132A16.6 16.6 0 0 1 19.5 11.25c4.603 0 8.768 1.865 11.785 4.882a16.6 16.6 0 0 1 4.882 11.785c0 9.205-7.462 16.667-16.667 16.667S2.833 37.122 2.833 27.917c0-4.603 1.865-8.768 4.882-11.785M30.52 29.695c.169-1.008-.676-1.843-1.699-1.843h-7.404A1.85 1.85 0 0 1 19.565 26v-7.404c0-1.023-.835-1.868-1.843-1.7-5.257.879-9.264 5.45-9.264 10.956 0 6.134 4.973 11.107 11.107 11.107 5.507 0 10.077-4.007 10.955-9.264"
        clipRule="evenodd"
      />
    </svg>
  );
}
