import { FormattedDate } from '@/components/base/formatted-date';
import { type MonevisAccountTradingDiaryFragment } from '@graphql/index';
import { createColumnHelper } from '@tanstack/react-table';
import { type IntlShape } from 'react-intl';

const columnHelper =
  createColumnHelper<MonevisAccountTradingDiaryFragment | null>();
export const defaultColumns = (
  intl: IntlShape,
  { t }: { t: (key: string) => string },
) => [
  columnHelper.accessor('day', {
    id: 'Date',
    header: () => t('table.Date'),
    cell: (props) => {
      const { day, month, year } = props.row.original ?? {};
      const date = new Date(Number(year), Number(month) - 1, Number(day));
      return <FormattedDate value={date} dateFormat="MMM d - yyyy" />;
    },
    enableGlobalFilter: false,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('startingBalance', {
    id: 'startingBalance',
    header: () => t('table.StartingBalance'),
    cell: (info) =>
      info.getValue()
        ? intl.formatNumber(info.getValue() ?? 0, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          })
        : '-',
  }),
  columnHelper.accessor('endingBalance', {
    id: 'endingBalance',
    header: () => t('table.EndingBalance'),
    cell: (info) =>
      info.getValue()
        ? intl.formatNumber(info.getValue() ?? 0, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          })
        : '-',
  }),
  columnHelper.accessor('startingEquity', {
    id: 'startingEquity',
    header: () => t('table.StartingEquity'),
    cell: (info) =>
      info.getValue()
        ? intl.formatNumber(info.getValue() ?? 0, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          })
        : '-',
  }),
  columnHelper.accessor('endingEquity', {
    id: 'endingEquity',
    header: () => t('table.EndingEquity'),
    cell: (info) =>
      info.getValue()
        ? intl.formatNumber(info.getValue() ?? 0, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          })
        : '-',
  }),
  columnHelper.accessor('lotsExecuted', {
    id: 'lotsExecuted',
    header: () => t('table.LotsExecuted'),
    cell: (info) => <span>{info.getValue() ?? '-'}</span>,
  }),
  columnHelper.accessor('tradesExecuted', {
    id: 'tradesExecuted',
    header: () => t('table.TradesExecuted'),
    cell: (info) => <span>{info.getValue() ?? '-'}</span>,
  }),
];
