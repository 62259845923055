import { cn } from '@/utils';
import { type HTMLProps } from 'react';
import {
  SemiCircularProgressBar,
  type SemiCircularProgressBarProps,
} from './semi-circular-progress';
import {
  SemiCircularProgressClock,
  type SemiCircularProgressClockProps,
} from './semi-circular-progress-clock';

interface ClockOptions
  extends Omit<SemiCircularProgressClockProps, 'percentage'> {
  positionY?: number;
}

type BarOptions = Omit<
  SemiCircularProgressBarProps,
  'percentage' | 'overflow' | 'canvasWidth'
>;

interface TextOptions extends HTMLProps<HTMLSpanElement> {
  positionY?: number;
}

interface CombinedProgressBarProps {
  percentage: number;
  canvasWidth: number;
  clockOptions: ClockOptions;
  barOptions: BarOptions;
  textOptions: TextOptions;
  maxPercentage?: number;
  basePercentage?: number;
  displayedPercentage?: number | string;
}

export function CombinedProgressBar({
  percentage,
  canvasWidth,
  clockOptions,
  barOptions,
  textOptions,
  displayedPercentage,
  maxPercentage = 100,
  basePercentage = 0,
}: CombinedProgressBarProps): JSX.Element {
  return (
    <div>
      <div
        className="absolute bottom-0 left-0 text-xs text-default-gray-500"
        style={{
          transform: barOptions?.barWidth
            ? `translateX(${barOptions?.barWidth / 2}px)`
            : undefined,
        }}
      >
        <span className="absolute bottom-0 left-0 -translate-x-1/2 translate-y-full text-xs text-default-gray-500">
          {basePercentage}%
        </span>
      </div>

      <div
        style={{
          position: 'relative',
          width: 'fit-content',
        }}
      >
        <SemiCircularProgressBar
          {...barOptions}
          canvasWidth={canvasWidth}
          percentage={percentage}
          overflow="visible"
        />
        <div
          style={{
            position: 'absolute',
            transform: 'translate(-50%, 0%)',
            left: '50%',
            bottom: `${clockOptions?.positionY ?? 0}%`,
          }}
        >
          <SemiCircularProgressClock
            {...clockOptions}
            percentage={percentage}
          />
        </div>
        <span
          {...textOptions}
          style={{
            ...textOptions?.style,
            position: 'absolute',
            bottom: `${textOptions?.positionY ?? 0}%`,
            left: '50%',
            transform: 'translate(-50%, 0%)',
          }}
        >
          {displayedPercentage ?? percentage}%
        </span>
      </div>
      <div
        className="absolute bottom-0 right-0 text-sm text-default-gray-500"
        style={{
          transform: barOptions?.barWidth
            ? `translateX(${-barOptions?.barWidth / 2}px)`
            : undefined,
        }}
      >
        <span
          className={cn(
            'absolute bottom-0 right-0 translate-x-1/2 translate-y-full text-xs text-default-gray-500',
          )}
        >
          {maxPercentage}%
        </span>
      </div>
    </div>
  );
}
