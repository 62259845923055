import { useAnalytics } from '@/context/analytics-context';
import { CredentialsContext } from '@/context/credentials-context';
import { SocialLoginProvider, useSocialLoginMutation } from '@graphql/index';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useCallback, useContext, useEffect, useRef } from 'react';

export const Route = createFileRoute('/(global)/oauth2/callback/$handler')({
  component: () => <OauthHandler />,
  validateSearch: (search: Record<string, unknown>): { code?: string } => {
    return {
      code: search.code ? String(search.code) : undefined,
    };
  },
});

function OauthHandler() {
  const { handler } = Route.useParams();
  const { code } = Route.useSearch();
  const [socialLogin] = useSocialLoginMutation();
  const navigate = useNavigate();
  const { setToken } = useContext(CredentialsContext);
  const hasBeenCalledRef = useRef(false);
  const { identify } = useAnalytics();

  let provider: SocialLoginProvider = SocialLoginProvider.Google;
  switch (handler) {
    case 'google':
      provider = SocialLoginProvider.Google;
      break;
    case 'twitter':
      provider = SocialLoginProvider.Twitter;
      break;
    case 'discord':
      provider = SocialLoginProvider.Discord;
      break;
    default:
      provider = SocialLoginProvider.Google;
  }

  const performLogin = useCallback(async () => {
    try {
      const { data } = await socialLogin({
        variables: {
          authorization: String(code),
          provider,
        },
      });

      if (data?.socialLogin?.token) {
        setToken(data?.socialLogin?.token);
        identify(data.socialLogin.id, {
          first_name: data.socialLogin.firstName,
          last_name: data.socialLogin.lastName,
          email: data.socialLogin.email,
        });
        navigate({ to: '/dashboard/home' });
      } else {
        navigate({ to: '/auth/login' });
      }
    } catch (e) {
      navigate({ to: '/auth/login' });
    }
  }, [code, provider, socialLogin, navigate, setToken]);

  useEffect(() => {
    if (!hasBeenCalledRef.current) {
      hasBeenCalledRef.current = true;
      performLogin();
    }
  }, [performLogin]);

  return <></>;
}
