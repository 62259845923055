import { CountryPicker } from '@/components/base/form/country-picker';
import { FormCheckbox } from '@/components/base/form/form-checkbox';
import { FormField } from '@/components/base/form/form-field';
import { Input } from '@/components/base/form/input';
import { PageHeading } from '@/components/base/page-heading';
import { TermsCheckbox } from '@/components/base/terms-checkbox';
import { useAppConfig } from '@/context/app-config-context';
import { type GeolocationQueryResult } from '@graphql/index';
import { useTranslation } from 'react-i18next';

export function PersonalInfoForm({
  geolocation,
  isLoggedIn = false,
}: {
  geolocation: GeolocationQueryResult['data'];
  isLoggedIn?: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const {
    AppConfig: { REFUND_POLICY_URL },
  } = useAppConfig();

  return (
    <div className="space-y-8">
      <PageHeading subheading={t('personalInfoForm.subheading')}>
        {t('personalInfoForm.heading')}
      </PageHeading>

      <div className="grid gap-4 md:grid-cols-2">
        <fieldset className="col-span-2 md:col-span-1">
          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('personalInfoForm.firstName')}
            name="firstName"
          >
            <Input
              inputSize="sm"
              variant="secondary"
              placeholder={t('personalInfoForm.firstNamePlaceholder')}
              data-testid="first-name-input"
            />
          </FormField>
        </fieldset>
        <fieldset className="col-span-2 md:col-span-1">
          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('personalInfoForm.lastName')}
            name="lastName"
          >
            <Input
              inputSize="sm"
              variant="secondary"
              placeholder={t('personalInfoForm.lastNamePlaceholder')}
              data-testid="last-name-input"
            />
          </FormField>
        </fieldset>
        <fieldset className="col-span-2 md:col-span-1">
          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('personalInfoForm.email')}
            name="email"
          >
            <Input
              placeholder={t('personalInfoForm.emailPlaceholder')}
              inputSize="sm"
              variant="secondary"
              data-testid="email-input"
            />
          </FormField>
        </fieldset>
        <fieldset className="col-span-2 md:col-span-1">
          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('personalInfoForm.city')}
            name="city"
          >
            <Input
              placeholder={t('personalInfoForm.cityPlaceholder')}
              inputSize="sm"
              variant="secondary"
              data-testid="city-input"
            />
          </FormField>
        </fieldset>
        <fieldset className="col-span-2 md:col-span-1">
          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('personalInfoForm.street')}
            name="street"
          >
            <Input
              placeholder={t('personalInfoForm.streetPlaceholder')}
              inputSize="sm"
              variant="secondary"
              data-testid="street-input"
            />
          </FormField>
        </fieldset>
        <fieldset className="col-span-2 md:col-span-1">
          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('personalInfoForm.postalCode')}
            name="zip"
          >
            <Input
              placeholder={t('personalInfoForm.postalCodePlaceholder')}
              inputSize="sm"
              variant="secondary"
              data-testid="zip-input"
            />
          </FormField>
        </fieldset>
        <fieldset className="col-span-2">
          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('personalInfoForm.country')}
            name="countryCode"
          >
            <CountryPicker
              disabled={Boolean(geolocation?.geolocation.country)}
              name="countryCode"
            />
          </FormField>
        </fieldset>
      </div>
      {!isLoggedIn ? (
        <fieldset className="col-span-2">
          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('personalInfoForm.password')}
            name="password"
          >
            <Input
              type="password"
              placeholder={t('personalInfoForm.passwordPlaceholder')}
              inputSize="sm"
              variant="secondary"
              data-testid="password-input"
            />
          </FormField>
        </fieldset>
      ) : null}
      <div className="space-y-4">
        <FormCheckbox data-testid="refund-checkbox" name="refundPolicy">
          <span>
            <span>{t('personalInfoForm.refundPolicyTextPart1')}</span>
            <a
              target="_blank"
              rel="noreferrer"
              href={REFUND_POLICY_URL}
              className="underline underline-offset-2"
            >
              {t('personalInfoForm.refundPolicyLinkText')}
            </a>
          </span>
        </FormCheckbox>

        <TermsCheckbox
          name="termsPolicy"
          description={t('personalInfoForm.termsPolicyDescription')}
        />
      </div>
    </div>
  );
}
