interface StatisticsListItemProps {
  label: string;
  value: string | number;
}
export function StatisticsListItem({
  label,
  value,
}: StatisticsListItemProps): JSX.Element {
  return (
    <div className="flex w-full justify-between rounded-md px-3 py-2">
      <span>{label}</span>
      <div className="flex items-center gap-2">
        <span>{value}</span>
      </div>
    </div>
  );
}
