import type { AnalyticsPlugin } from 'analytics';
import { type LeadEventPayload } from '../events/lead';
import { type OrderPayload } from '../events/order';
import { type PurchasePayload } from '../events/purchase';

const allowedEvents = ['Lead', 'Purchase', 'InitiateCheckout', 'Order'];

interface TrackPayload {
  event: string;
  properties: LeadEventPayload | PurchasePayload | OrderPayload;
}

function trackLead(): void {
  window.fbq('track', 'Lead');
}

function trackPurchase(payload: PurchasePayload): void {
  window.fbq(
    'track',
    'Purchase',
    {
      value: payload.value,
      currency: payload.currency,
    },
    {
      eventID: payload.id,
    },
  );
}

function trackOrder(payload: OrderPayload): void {
  window.fbq(
    'track',
    'Order',
    {
      value: payload.value,
      currency: payload.currency,
    },
    {
      eventID: payload.id,
    },
  );
}

function trackCheckout(): void {
  window.fbq('track', 'InitiateCheckout');
}

export function providerFacebookPixel({
  pixel,
  name = 'facebook-ixel',
}: {
  pixel: string;
  name: string;
}): AnalyticsPlugin {
  // return object for analytics to use
  return {
    /* All plugins require a name */
    name,
    /* Everything else below this is optional depending on your plugin requirements */

    // load provider script to page
    initialize: () => {
      typeof window !== 'undefined' &&
        Boolean(window.fbq) &&
        window.fbq('init', pixel);
    },

    page: () => {
      window.fbq('track', 'PageView');
      // call provider specific page tracking
    },
    track: ({ payload }: { payload: TrackPayload }) => {
      if (allowedEvents.includes(payload.event)) {
        switch (payload.event) {
          case 'Lead':
            trackLead();
            break;
          case 'Purchase':
            trackPurchase(payload.properties as PurchasePayload);
            break;
          case 'InitiateCheckout':
            trackCheckout();
            break;
          case 'Order':
            trackOrder(payload.properties as OrderPayload);
            break;
        }
      }
    },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third party
      return typeof window !== 'undefined' && Boolean(window.fbq);
    },
  };
}
