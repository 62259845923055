import { Invoice } from '@confirmo/overlay';
import { useEffect } from 'react';

export function ConfirmoCheckout({ token }: { token: string }): JSX.Element {
  useEffect(() => {
    Invoice.open(token);
  }, [token]);

  return <div className="flex flex-col items-center" />;
}
