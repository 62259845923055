import { Button } from '@/components/base/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/base/dropdown-menu';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export enum Impact {
  MID = 'Mid',
  HIGH = 'High',
  LOW = 'Low',
  HL = 'HL',
}

interface ImpactFilterProps {
  selectedImpacts: Impact[];
  setSelectedImpacts: React.Dispatch<React.SetStateAction<Impact[]>>;
}

export function ImpactFilter({
  selectedImpacts,
  setSelectedImpacts,
}: ImpactFilterProps): JSX.Element {
  function handleChange(impact: Impact): void {
    if (selectedImpacts.includes(impact)) {
      setSelectedImpacts(selectedImpacts.filter((item) => item !== impact));
    } else {
      setSelectedImpacts([...selectedImpacts, impact]);
    }
  }
  const { t } = useTranslation();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="focus-visible:ring-0">
        <Button
          data-testid="impact-filter-button"
          role="combobox"
          className="hover:dark:bg-default-gray-960 w-full min-w-24 gap-2 border-none px-3 py-2 dark:bg-default-gray-950"
        >
          {t('calendar.filter')} <FontAwesomeIcon icon={faFilter} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent data-testid="impact-filter-content" className="w-56">
        <DropdownMenuLabel> {t('calendar.impact')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem
          data-testid="item-high"
          checked={selectedImpacts.includes(Impact.HIGH)}
          onCheckedChange={() => {
            handleChange(Impact.HIGH);
          }}
        >
          {t('calendar.high')}
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          data-testid="item-mid"
          checked={selectedImpacts.includes(Impact.MID)}
          onCheckedChange={() => {
            handleChange(Impact.MID);
          }}
        >
          {t('calendar.medium')}
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          data-testid="item-low"
          checked={selectedImpacts.includes(Impact.LOW)}
          onCheckedChange={() => {
            handleChange(Impact.LOW);
          }}
        >
          {t('calendar.low')}
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          data-testid="item-hl"
          checked={selectedImpacts.includes(Impact.HL)}
          onCheckedChange={() => {
            handleChange(Impact.HL);
          }}
        >
          {t('calendar.holiday')}
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
