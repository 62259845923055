import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/base/tabs';
import { useTranslation } from 'react-i18next';
import { AccountDetails } from './account-details';
import { AccountDiaryWithData } from './account-diary';
import { AdvancedAnalysis } from './advanced-analysis';
import { ClosedPistionsTableWithData } from './closed-positions';
import { OverviewWithData } from './overview';

export function AccountDetailContent({
  accountId,
}: {
  accountId: string;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4">
      <Tabs defaultValue="overview">
        <div className="mx-4">
          <TabsList>
            <TabsTrigger value="overview">
              {t('accountDetailTabs.overview')}
            </TabsTrigger>
            <TabsTrigger value="advanced-analysis">
              {t('accountDetailTabs.advancedAnalysis')}
            </TabsTrigger>
            <TabsTrigger value="account-positions">
              {t('accountDetailTabs.closedPositions')}
            </TabsTrigger>
            <TabsTrigger value="account-diary">
              {t('accountDetailTabs.accountDiary')}
            </TabsTrigger>
            <TabsTrigger value="account-details">
              {t('accountDetailTabs.accountDetails')}
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="overview">
          <OverviewWithData accountId={accountId} />
        </TabsContent>
        <TabsContent value="account-details">
          <AccountDetails accountId={accountId} />
        </TabsContent>
        <TabsContent value="account-diary">
          <AccountDiaryWithData accountId={accountId} />
        </TabsContent>
        <TabsContent value="account-positions">
          <ClosedPistionsTableWithData accountId={accountId} />
        </TabsContent>
        <TabsContent value="advanced-analysis">
          <AdvancedAnalysis accountId={accountId} />
        </TabsContent>
      </Tabs>
    </div>
  );
}
