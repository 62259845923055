import { Skeleton } from '@/components/base/skeleton';
import {
  AccountType,
  useGetAccountTradingDiaryQuery,
  useGetBrokerAccountQuery,
  useGetBrokerAccountStatisticsQuery,
  type GetAccountTradingDiaryQuery,
  type GetBrokerAccountQuery,
  type GetBrokerAccountStatisticsQuery,
} from '@graphql/index';
import invariant from 'tiny-invariant';
import { StatisticsList } from './components/statistics-list';
import { StatsChart } from './components/stats-chart';

export function Overview({
  data,
  diary,
  brokerAccount,
}: {
  data: GetBrokerAccountStatisticsQuery;
  diary: GetAccountTradingDiaryQuery | null | undefined;
  brokerAccount: GetBrokerAccountQuery;
}): JSX.Element {
  const { balance, equity } = data.brokerAccountStatistics;
  const analysis = data.brokerAccountStatistics?.stats?.analysis;
  invariant(analysis, 'Analysis must be defined');
  const isTrial = brokerAccount.brokerAccount.accountType === AccountType.Trial;

  const targetReturn = analysis.find((item) => {
    return item?.identifier === 'TargetReturn';
  });

  // invariant(targetReturn, 'Target return must be defined');
  invariant(diary, 'Diary must be defined');

  const gain = targetReturn?.currentPercentageValue;
  const profit = targetReturn?.currentRelativeValue;
  const payoutPeriod = brokerAccount.brokerAccount.payoutPeriod!;
  const profitSplit = brokerAccount.brokerAccount.profitSplit!;
  const leverage = brokerAccount.brokerAccount.leverage;

  return (
    <div className="flex flex-col gap-5">
      <div className="grid grid-cols-2 gap-5 md:grid-cols-3">
        <div className="col-span-2 h-full">
          <StatsChart
            diary={diary.accountTradingDiary.slice(-14)}
            analysis={analysis}
            currentBalance={balance}
            currentEquity={equity}
          />
        </div>
        <div className="col-span-2 md:col-span-1" data-testId="statistics-list">
          <StatisticsList
            gain={gain}
            profit={profit}
            equity={equity}
            balance={balance}
            payoutPeriod={payoutPeriod}
            profitSplit={profitSplit}
            leverage={leverage}
            isTrial={isTrial}
          />
        </div>
      </div>
    </div>
  );
}

export function OverviewWithData({
  accountId,
}: {
  accountId: string;
}): JSX.Element {
  const { data: accountStats, loading } = useGetBrokerAccountStatisticsQuery({
    variables: { id: accountId ?? '' },
    fetchPolicy: 'cache-and-network',
  });
  const { data: brokerAccount, loading: brokerAccountLoading } =
    useGetBrokerAccountQuery({
      variables: { id: accountId ?? '' },
      fetchPolicy: 'cache-and-network',
    });
  const { data: accountDiary, loading: diaryLoading } =
    useGetAccountTradingDiaryQuery({
      variables: { login: accountId ?? '' },
      fetchPolicy: 'cache-and-network',
    });

  if (loading || brokerAccountLoading || diaryLoading) {
    return (
      <div className="grid grid-cols-2 gap-5 md:grid-cols-3">
        <Skeleton className="col-span-2 h-full min-h-64 w-full min-w-56 rounded-lg" />
        <Skeleton className="h-full min-h-64 w-full min-w-24 rounded-lg" />
      </div>
    );
  }

  invariant(accountStats, `[${accountId}] Stats missing`);
  //invariant(accountDiary);

  return (
    <Overview
      brokerAccount={brokerAccount!}
      data={accountStats}
      diary={accountDiary}
    />
  );
}
