import DashboardTrading from './dashboard-trading-accounts';
// import DiscountBox from '@/components/discount-box';
import MetatraderDownload from '@/components/metatrader-download';
import { useCurrentUser } from '@/context/current-user-context';
import { useTranslation } from 'react-i18next';
import NewChallenge from './new-challenge';

export default function DashboardAccount(): JSX.Element {
  const { t } = useTranslation();
  const data = useCurrentUser();
  return (
    <div className="flex h-full flex-col justify-between gap-5">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <div className="text-xl font-medium dark:text-white">
            {t('dashboard.greeting')}, {data.me.firstName} {data.me.lastName}
          </div>
          <div className="text-sm dark:text-default-gray-400">
            {t('dashboard.underTitle')}
          </div>
        </div>
        <DashboardTrading />
      </div>
      <div className="flex h-full flex-col justify-between gap-5">
        {/* <DiscountBox /> */}
        <NewChallenge />
        <MetatraderDownload />
      </div>
    </div>
  );
}
