import { Timebomb } from '@/components/icons';
import { useFragment } from '@apollo/client';
import { CartFragmentDoc, type CartFragment } from '@graphql/index';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function formatNumber(number: number): string {
  if (number < 10) {
    return `0${Number(number).toFixed(0)}`;
  }
  return String(Number(number).toFixed(0));
}

function formatTime(seconds: number): string {
  const m = Math.floor(seconds / 60);
  const s = Math.ceil(seconds - m * 60);

  return `${formatNumber(m)}:${formatNumber(s)}`;
}

export function Countdown(): JSX.Element {
  const [expiryTime, setExpiryTime] = useState(
    localStorage.getItem('discountExpiry') ?? 0,
  );
  const [remainingSeconds, setRemainingSeconds] = useState(0);

  function randomMinutes(min = 24, max = 30): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  useEffect(() => {
    const now = new Date();
    if (expiryTime === 0 || Number(expiryTime) < now.getTime()) {
      now.setTime(now.getTime() + randomMinutes() * 60 * 1000);
      setExpiryTime(now.getTime());
      localStorage.setItem('discountExpiry', String(now.getTime()));
    }

    const interval = setInterval(() => {
      tick();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [expiryTime]);

  function tick(): void {
    const now = new Date();
    const expirationTime = new Date();
    expirationTime.setTime(Number(expiryTime));
    const remaining = (expirationTime.getTime() - now.getTime()) / 1000;
    setRemainingSeconds(Math.max(remaining, 0));
  }

  return <>{formatTime(remainingSeconds)}</>;
}

export function DiscountBanner({ cartId }: { cartId: string }): JSX.Element {
  const { t } = useTranslation();

  const { data: cart } = useFragment<CartFragment>({
    fragment: CartFragmentDoc,
    fragmentName: 'Cart',
    from: {
      __typename: 'Cart',
      id: cartId,
    },
  });

  const hasDiscount =
    cart?.appliedDiscount && String(cart?.appliedDiscount) !== '';

  if (!hasDiscount) {
    return <></>;
  }

  return (
    <div className="my-4 flex flex-col items-center gap-4 rounded border border-green-400/50 bg-green-400/10 p-4 md:flex-row">
      <Timebomb />
      <div className="flex w-full flex-col">
        <div className="flex-1 justify-center text-center text-lg font-bold">
          {t('addDiscountCode.activatedDiscount')} -{' '}
          <span className="font-bold uppercase">{cart?.appliedDiscount}</span>
        </div>
        <div className="text-center text-2xl font-bold">
          {t('addDiscountCode.discountExpire')}{' '}
          <span className="font-bold uppercase">
            <Countdown />
          </span>
        </div>
      </div>
    </div>
  );
}
