import { cn } from '@/utils';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

const LoadingVariants = cva('animate-spin', {
  variants: {
    variant: {
      default: 'text-black',
    },
    size: {
      default: 'text-base',
      small: 'text-sm',
      big: 'text-2xl',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export interface LoadingProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof LoadingVariants> {
  className?: string;
}

export function Loading({
  className,
  size,
  variant,
}: LoadingProps): JSX.Element {
  return (
    <div>
      <FontAwesomeIcon
        fixedWidth
        className={cn(LoadingVariants({ size, variant, className }))}
        icon={faSpinnerThird}
      />
    </div>
  );
}
