import { type AuthContext } from '@/auth';
import { ThemeProvider } from '@/stories/theme-provider';
import {
  createRootRouteWithContext,
  Outlet,
  ScrollRestoration,
} from '@tanstack/react-router';
import { lazy, Suspense } from 'react';

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

interface MyRouterContext {
  auth: AuthContext;
}

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: () => (
    <ThemeProvider theme={{ font: { sans: 'InterDisplay' } }}>
      <ScrollRestoration />

      <Outlet />
      {/* Loading */}
      {/* eslint-disable-next-line i18next/no-literal-string -- ok */}
      <Suspense fallback={<>Loading</>}>
        <TanStackRouterDevtools />
      </Suspense>
    </ThemeProvider>
  ),
});
