import Section from '@/components/base/section';
import { Skeleton } from '@/components/base/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/base/table';
import { useGetWalletQuery, type WalletFragment } from '@graphql/index';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import invariant from 'tiny-invariant';
import { defaultColumns } from './columns';

export function PayoutsTable({ data }: { data: WalletFragment }): JSX.Element {
  const intl = useIntl();
  const { t } = useTranslation();
  const columns = useMemo(() => defaultColumns(intl, { t }), [intl, t]);
  const table = useReactTable({
    data: data.transactions ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  if (Number(data.transactions.length) === 0) {
    return <></>;
  }

  return (
    <Section className="p-0 sm:p-0" variant="outline">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              className="[&>*:first-child]:pl-5 [&>*:last-child]:pr-5"
              id={headerGroup.id}
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => (
                <TableHead
                  key={header.id}
                  className="w-[100px] px-3 py-3 sm:px-6"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row, id) => (
            <TableRow
              data-testid={`row-${id}`}
              className="text-xs text-default-gray-400 sm:text-sm"
              id={row.id}
              key={row.id}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell
                    className="whitespace-nowrap px-3 py-3 sm:px-6"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>

        {table.getRowModel().rows.length === 0 && (
          <TableRow className="border-none">
            <TableCell
              colSpan={columns.length}
              className="whitespace-nowrap px-3 py-3 sm:px-6"
            >
              <div className="flex w-full items-center justify-center text-default-gray-950 dark:text-white">
                <span>{t('calendar.noData')}</span>
              </div>
            </TableCell>
          </TableRow>
        )}
      </Table>
    </Section>
  );
}

export function PayoutsTableWithData(): JSX.Element {
  const { data, loading } = useGetWalletQuery();

  if (loading) {
    return (
      <div className="mx-auto flex h-full w-full flex-col gap-4">
        <Skeleton className="h-full max-h-32 w-full rounded-xl" />
      </div>
    );
  }

  invariant(data?.wallet, `Wallet Missing`);

  return <PayoutsTable data={data?.wallet} />;
}
