/* eslint-disable -- ok */
// @ts-nocheck -- ok
import googleAnalyticsPlugin from '@analytics/google-analytics';

// Original google analytics plugin with no alterations
const originalGoogleAnalytics = googleAnalyticsPlugin;

function supressTrack() {
  // Send data to custom backend
}

// Here are my custom overrides
const overrides = {
  // Assign myCustomTrack over the original GA track call
  track: supressTrack,
};

export const googleAnalytics = (config: unknown) =>
  Object.assign({}, originalGoogleAnalytics(config), overrides);
