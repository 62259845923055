import Section from '@/components/base/section';
import { Skeleton } from '@/components/base/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/base/table';
import {
  useGetAccountTradingDiaryQuery,
  type MonevisAccountTradingDiaryFragment,
} from '@graphql/index';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import invariant from 'tiny-invariant';
import { defaultColumns } from './components/columns';

export function AccountDiary({
  diary,
}: {
  diary: (MonevisAccountTradingDiaryFragment | null)[];
}): JSX.Element {
  const intl = useIntl();
  const { t } = useTranslation();
  const columns = useMemo(() => defaultColumns(intl, { t }), [intl, t]);
  const table = useReactTable({
    data: diary ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <Section variant="secondary">
      <Table data-testid="account-diary-table">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              className="[&>*:first-child]:pl-5 [&>*:last-child]:pr-5"
              id={headerGroup.id}
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className="w-[100px] text-nowrap px-3 py-3 text-left sm:px-6"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow
              className="group relative text-xs text-default-gray-400 hover:bg-gray-100 sm:text-sm"
              id={row.id}
              key={row.id}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell
                    className="whitespace-nowrap px-3 py-3 sm:px-6"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>

        {table.getRowModel().rows.length === 0 && (
          <TableRow className="border-none">
            <TableCell
              colSpan={columns.length}
              className="whitespace-nowrap px-3 py-3 sm:px-6"
            >
              <div className="flex w-full items-center justify-center text-default-gray-950 dark:text-white">
                <span>{t('accountDetail.noCertificate')}</span>
              </div>
            </TableCell>
          </TableRow>
        )}
      </Table>
    </Section>
  );
}

export function AccountDiaryWithData({
  accountId,
}: {
  accountId: string;
}): JSX.Element {
  const { data: accountDiary, loading } = useGetAccountTradingDiaryQuery({
    variables: { login: accountId ?? '' },
  });
  if (loading) {
    return (
      <div className="h-40 w-full">
        <Skeleton className="h-full w-full rounded-2xl" />
      </div>
    );
  }
  invariant(
    accountDiary?.accountTradingDiary,
    `[${accountId}] Diary is missing`,
  );
  const sortedDiary = [...(accountDiary?.accountTradingDiary ?? [])].sort(
    (a, b) => {
      const { day: dayA, month: monthA, year: yearA } = a ?? {};
      const { day: dayB, month: monthB, year: yearB } = b ?? {};
      const dateA = new Date(Number(yearA), Number(monthA) - 1, Number(dayA));
      const dateB = new Date(Number(yearB), Number(monthB) - 1, Number(dayB));
      return dateB.getTime() - dateA.getTime();
    },
  );

  return <AccountDiary diary={sortedDiary} />;
}
