import { cn } from '@/utils';
import { type PropsWithChildren } from 'react';

export function Divider({
  children,
  className,
}: PropsWithChildren & { className?: string }): JSX.Element {
  return (
    <div className="flex w-full items-center">
      <div
        className={cn(
          'h-[1px] w-full bg-default-gray-100 font-thin',
          className,
        )}
      />
      {children && (
        <div className="whitespace-nowrap px-4 text-center text-base text-white">
          {children}
        </div>
      )}

      <div
        className={cn(
          'h-[1px] w-full bg-default-gray-100 font-thin',
          className,
        )}
      />
    </div>
  );
}
