import type { AnalyticsPlugin } from 'analytics';
import { type PurchasePayload } from '../events/purchase';

const allowedEvents = ['Purchase'];

interface TrackPayload {
  event: string;
  properties: PurchasePayload;
}

function trackPurchase(payload: PurchasePayload): void {
  let value = payload.value;
  if (payload.currency === 'USD') {
    value = value * 22.6;
  }
  if (payload.currency === 'EUR') {
    value = value * 25.2;
  }

  const conversionConf = {
    id: 100216926,
    value,
    consent: 1,
  };
  window.rc.conversionHit(conversionConf);
}

export function providerSeznam({
  name = 'seznam',
}: {
  name: string;
}): AnalyticsPlugin {
  // return object for analytics to use
  return {
    /* All plugins require a name */
    name,
    /* Everything else below this is optional depending on your plugin requirements */

    // load provider script to page
    // initialize: () => {},

    identify: ({
      payload,
    }: {
      payload: {
        anonymousId: string;
        userId: string;
      };
    }) => {
      // @ts-expect-error -- ok
      // eslint-disable-next-line -- ok
      window.sznIVA.IS.updateIdentities({
        eid: payload.userId,
      });
    },

    page: () => {
      // window.fbq('track', 'PageView');
      // call provider specific page tracking
    },
    track: ({ payload }: { payload: TrackPayload }) => {
      if (allowedEvents.includes(payload.event)) {
        switch (payload.event) {
          case 'Purchase':
            trackPurchase(payload.properties);
            break;
        }
      }
    },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third party
      return typeof window !== 'undefined' && Boolean(window.twq);
    },
  };
}
