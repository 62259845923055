import { Button } from '@/components/base/button';
import { PageHeading } from '@/components/base/page-heading';
import { PaymentMethod } from '@graphql/index';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { Badge } from './badge';
import { TrustBox } from './trustbox';

export function OrderSummary({
  total,
  subTotal,
  currency = 'USD',
  onSubmit,
  loading,
}: {
  total: number | undefined;
  subTotal: number | undefined;
  currency: string;
  loading: boolean;
  onSubmit: (method: PaymentMethod) => void;
}) {
  const intl = useIntl();
  const { t } = useTranslation();

  const totalFormatted = intl.formatNumber(total ?? 0, {
    style: 'currency',
    currency,
  });

  const subTotalFormatted = intl.formatNumber(subTotal ?? 0, {
    style: 'currency',
    currency,
  });

  return (
    <div className="flex flex-col gap-6">
      {typeof total === 'number' && (
        <div className="mt-4 flex flex-col items-center">
          <PageHeading size="xxlarge">
            {t('orderSummary.totalToPay')}
          </PageHeading>
          <Badge className="min-w-52" size="lg">
            {Number(total) < Number(subTotal) && (
              <div className="mr-2 text-sm text-red-400 line-through">
                {subTotalFormatted}
              </div>
            )}
            {totalFormatted}
          </Badge>
        </div>
      )}

      <TrustBox />

      <div className="flex flex-col gap-6">
        <div className="mt-4 flex w-full flex-col justify-center gap-2 md:flex-row md:gap-4">
          <div>
            <Button
              loading={loading}
              onClick={() => {
                onSubmit(PaymentMethod.Stripe);
              }}
              className="w-full"
              type="submit"
            >
              {t('orderSummary.pay')} {totalFormatted}
            </Button>
          </div>
          <div>
            <Button
              loading={loading}
              onClick={() => {
                onSubmit(PaymentMethod.Confirmo);
              }}
              className="w-full"
              variant="outlined"
              type="submit"
            >
              {t('orderSummary.payWithCryptocurrencies')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
