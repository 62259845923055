import { EventName, useAnalytics } from '@/context/analytics-context';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export function BridgerCheckout({
  token,
  onBack = () => false,
}: {
  token: string;
  onBack: () => void;
}): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const { track } = useAnalytics();
  const { t } = useTranslation();

  function loadCheckout(replace = false) {
    const key =
      (import.meta.env.VITE_BRIDGERPAY_KEY as string) ||
      window.ENV.BRIDGERPAY_KEY;

    if (ref.current?.getAttribute('data-loaded') === 'yes') {
      return null;
    }

    const script = document.createElement('script');
    script.src = 'https://checkout.bridgerpay.com/v2/loader';
    script.setAttribute('data-checkout-key', key);
    script.setAttribute('data-checkout-token', token);
    script.setAttribute('data-currency-lock', 'true');
    //script.setAttribute('data-button-mode', 'modal');
    // script.async = true;
    if (replace) {
      // alert('replace')
      ref.current?.replaceChild(script, ref.current.childNodes[0]);
    } else {
      ref.current?.appendChild(script);
    }
    ref.current?.setAttribute('data-loaded', 'yes');
  }

  useEffect(() => {
    // console.log(ref)
    if (ref.current) {
      // window.addEventListener(
      //   '[bp]:all',
      //   ({ detail }) => console.log('[bp]:all', detail)
      // );
      loadCheckout();
    }
  }, [ref]);

  useEffect(() => {
    loadCheckout(true);
  }, [token]);

  const listener = ({
    detail,
  }: {
    detail: { event: string; provider: string; type: string };
  }): void => {
    track(EventName.SelectedPaymentMethod, {
      provider: detail.provider,
      type: detail.type,
    });
  };

  useEffect(() => {
    // @ts-expect-error -- ok
    window?.addEventListener('[bp]:clickOnPaymentMethod', listener);

    return () => {
      // @ts-expect-error -- ok
      window?.removeEventListener('[bp]:clickOnPaymentMethod', listener);
    };
  });

  return (
    <div className="flex flex-col items-center">
      <div className="w-full max-w-[500px] rounded-lg bg-white p-8" ref={ref} />
      <button
        className="mt-2 cursor-pointer text-center font-bold hover:underline"
        onClick={onBack}
        type="button"
      >
        <span>{t('paybycrypto')}</span>
      </button>
    </div>
  );
}
