import type { AnalyticsPlugin } from 'analytics';
import { type ProductItem, type PurchasePayload } from '../events/purchase';

const allowedEvents = ['Purchase'];

interface TrackPayload {
  event: string;
  properties: PurchasePayload;
}

function extractProductFromPayload(payload: ProductItem) {
  return {
    item_id: payload.id,
    item_name: payload.name,
    item_category: ['Challenge'],
    // variant: payload.variant,
    quantity: 1,
  };
}

function trackPurchase(payload: PurchasePayload): void {
  const data = {
    currency: 'USD',
    value: payload.value,
    transaction_id: payload.id,
    items: payload.items.map(extractProductFromPayload),
  };

  window.gtag('event', 'purchase', data);

  window.gtag('event', 'conversion', {
    send_to: 'AW-16496785973/961wCKezp8wZELX0o7o9',
    value: payload.value,
    currency: 'USD',
    transaction_id: payload.id,
  });
}

export function googleEnhancedEcommerce(): AnalyticsPlugin {
  // return object for analytics to use
  return {
    /* All plugins require a name */
    name: 'enhanced-ecommerce',
    /* Everything else below this is optional depending on your plugin requirements */

    // initialize: ({ config }) => {
    //   // load provider script to page
    // },
    // page: ({ payload }) => {
    //   // call provider specific page tracking
    // },
    track: ({ payload }: { payload: TrackPayload }) => {
      if (allowedEvents.includes(payload.event)) {
        switch (payload.event) {
          case 'Purchase':
            trackPurchase(payload.properties);
            break;
        }
      }
      // call provider specific event tracking
    },
    // identify: ({ payload }) => {
    //   // call provider specific user identify method
    // },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third party
      return typeof window !== 'undefined' && Boolean(window.gtag);
    },
  };
}
