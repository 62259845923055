import type { SVGProps } from 'react';

export default function SvgMacOs(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={103}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        d="M0 23.613h4.015V13.167c0-2.06 1.355-3.558 3.29-3.558 1.87 0 3.032 1.175 3.032 3.075v10.93h3.902V13.07c0-2.044 1.322-3.46 3.257-3.46 1.968 0 3.064 1.19 3.064 3.283v10.72h4.015V11.911c0-3.412-2.209-5.682-5.58-5.682-2.466 0-4.498 1.32-5.32 3.429h-.098c-.628-2.19-2.354-3.429-4.772-3.429-2.323 0-4.129 1.304-4.87 3.284h-.081V6.551H0zm33.364-2.752c-1.741 0-2.902-.885-2.902-2.286 0-1.352 1.113-2.221 3.048-2.35l3.95-.241v1.304c0 2.06-1.822 3.573-4.096 3.573m-1.209 3.026c2.193 0 4.338-1.143 5.322-2.994h.08v2.72h3.87v-11.75c0-3.429-2.757-5.666-6.998-5.666-4.354 0-7.079 2.286-7.257 5.473h3.725c.259-1.417 1.468-2.334 3.37-2.334 1.984 0 3.194 1.03 3.194 2.817v1.223l-4.516.258c-4.16.257-6.498 2.076-6.498 5.102 0 3.075 2.402 5.151 5.708 5.151m26.898-11.251c-.274-3.59-3.064-6.439-7.579-6.439-4.966 0-8.192 3.429-8.192 8.885 0 5.537 3.226 8.869 8.224 8.869 4.29 0 7.24-2.511 7.563-6.326h-3.79c-.37 1.931-1.693 3.09-3.724 3.09-2.548 0-4.193-2.06-4.193-5.633 0-3.51 1.629-5.634 4.16-5.634 2.145 0 3.42 1.368 3.742 3.187zM71.454 0c-6.788 0-11.046 4.62-11.046 12.008S64.665 24 71.454 24c6.773 0 11.03-4.604 11.03-11.992S78.227 0 71.454 0m0 3.541c4.145 0 6.789 3.284 6.789 8.467 0 5.167-2.644 8.45-6.789 8.45-4.16 0-6.788-3.283-6.788-8.45 0-5.183 2.628-8.467 6.788-8.467m12.724 13.537C84.355 21.36 87.871 24 93.224 24c5.628 0 9.176-2.769 9.176-7.18 0-3.46-2-5.408-6.724-6.486l-2.677-.612c-2.854-.676-4.031-1.577-4.031-3.122 0-1.932 1.773-3.22 4.402-3.22 2.66 0 4.483 1.304 4.676 3.477h3.967C101.916 2.77 98.53 0 93.403 0c-5.065 0-8.66 2.785-8.66 6.906 0 3.315 2.031 5.376 6.32 6.358l3.016.708c2.935.692 4.128 1.658 4.128 3.332 0 1.931-1.951 3.316-4.757 3.316-2.838 0-4.982-1.4-5.24-3.541z"
      />
    </svg>
  );
}
