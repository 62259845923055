import type { AnalyticsPlugin } from 'analytics';
import { type ChangeLocalePayload } from '../events/change-locale';
import { type FailedPurchasePayload } from '../events/failed-purchase';
import { type InitiatedGatewayPayload } from '../events/initiated-gateway';
import { type SelectedPaymentMethodPayload } from '../events/selected-payment-method';

const allowedEvents = [
  'FailedPurchase',
  'ChangeLocale',
  'SelectedPaymentMethod',
  'InitiatedGateway',
];

interface TrackPayload {
  event: string;
  properties:
    | InitiatedGatewayPayload
    | SelectedPaymentMethodPayload
    | FailedPurchasePayload
    | ChangeLocalePayload;
}

function trackFailedPurchase(data: FailedPurchasePayload): void {
  window._cio.track('Failed Purchase', data);
}

function trackChangeLocale(data: ChangeLocalePayload): void {
  window._cio.track('Change Preferred Locale', data);
}

function trackSelectedPaymentMethod(data: SelectedPaymentMethodPayload): void {
  window._cio.track('Selected Payment Method', data);
}

function trackInitiatedGateway(data: InitiatedGatewayPayload): void {
  window._cio.track('Initiated Gateway', data);
}

export function CIO(): AnalyticsPlugin {
  // return object for analytics to use
  return {
    /* All plugins require a name */
    name: 'cio',
    /* Everything else below this is optional depending on your plugin requirements */

    // load provider script to page
    initialize: () => false,

    identify: ({
      payload,
    }: {
      payload: {
        anonymousId: string;
        userId: string;
        traits: {
          email: string;
          first_name?: string;
          last_name?: string;
        };
      };
    }) => {
      window._cio.identify({
        id: payload.userId,
        email: payload.traits.email,
        first_name: payload.traits.first_name,
        last_name: payload.traits.last_name,
      });
    },

    page: () => {
      window._cio.page();
    },
    track: ({ payload }: { payload: TrackPayload }) => {
      if (allowedEvents.includes(payload.event)) {
        switch (payload.event) {
          case 'FailedPurchase':
            trackFailedPurchase(payload.properties as FailedPurchasePayload);
            break;
          case 'ChangeLocale':
            trackChangeLocale(payload.properties as ChangeLocalePayload);
            break;
          case 'SelectedPaymentMethod':
            trackSelectedPaymentMethod(
              payload.properties as SelectedPaymentMethodPayload,
            );
            break;

          case 'InitiatedGateway':
            trackInitiatedGateway(
              payload.properties as InitiatedGatewayPayload,
            );
            break;
        }
      }
    },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third party
      return typeof window !== 'undefined' && Boolean(window._cio);
    },
  };
}
