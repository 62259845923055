import { cn } from '@/utils';
import { getCardVariant, statusMap } from '@/utils/challenge-config';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import {
  faAwardSimple,
  faCheck,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ChallengeStatus,
  useGetBrokerAccountQuery,
  type AccountType,
} from '@graphql/index';
import { Link } from '@tanstack/react-router';
import { cva, type VariantProps } from 'class-variance-authority';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { Skeleton } from './base/skeleton';
import { TradingAccountArchiveModal } from './trading-account-archive-modal';

const TradingAccountVariants = cva(
  'p-4 rounded-2xl flex flex-col gap-3 dark:text-white text-black dark:bg-default-gray-950 bg-white w-full transition-all duration-150 hover:border-default-gray-300 dark:hover:border-default-gray-400 border border-default-gray-600',
  {
    variants: {
      variant: {
        ongoing: '',
        passed: '',
        failed: 'opacity-40',
        funded:
          'border border-green-500 dark:hover:border-default-green-300 hover:border-default-green-300',
      },
    },
    defaultVariants: {
      variant: 'ongoing',
    },
  },
);

const IconVariants = cva('rounded-md p-2', {
  variants: {
    variant: {
      ongoing: 'bg-blue-500/15 text-blue-500',
      passed: 'bg-green-500/15 text-green-500',
      failed: 'bg-danger/15 text-danger',
      funded: 'bg-green-500/15 text-green-500',
    },
  },
  defaultVariants: {
    variant: 'ongoing',
  },
});

interface TradingComponentProps {
  header: string;
  value: number | string;
}

function TradingComponent({
  header,
  value,
}: TradingComponentProps): JSX.Element {
  return (
    <div>
      <h1 className="text-[8px] font-normal uppercase tracking-widest text-default-gray-300">
        {header}
      </h1>
      {value}
    </div>
  );
}

export interface TradingAccountCardProps
  extends VariantProps<typeof TradingAccountVariants> {
  accountType: AccountType;
  accountName: string;
  challengeStatus: ChallengeStatus;
  login: string;
  'data-testid'?: string;
}

export function TradingAccountCard({
  accountType,
  accountName,
  challengeStatus,
  login,
  'data-testid': dataTestId = 'tradingAccountCard',
}: TradingAccountCardProps): JSX.Element {
  const iconMap = {
    ongoing: faClock,
    passed: faCheck,
    failed: faXmark,
    funded: faAwardSimple,
  };

  const { t } = useTranslation();

  const variant = statusMap[getCardVariant(accountType, challengeStatus)];

  return (
    <Link
      to={`/dashboard/accounts/${login}`}
      params={{ 'account-id': login }}
      className={cn(TradingAccountVariants({ variant }))}
      data-testid={dataTestId}
    >
      <div className="relative flex flex-col gap-3">
        <div className="flex justify-between gap-2 text-xs tracking-wider text-default-gray-800 dark:text-default-gray-300">
          <div className="flex flex-col gap-1">
            {accountName}
            <div className="text-base font-semibold tracking-widest text-black dark:text-white">
              {login}
            </div>
          </div>
          <div>
            {variant && (
              <FontAwesomeIcon
                data-testid={`icon-${variant}`}
                icon={iconMap[variant]}
                className={cn(IconVariants({ variant }), 'h-5 w-5')}
              />
            )}
          </div>
        </div>
        <div className="flex w-full items-end">
          <div className="grid w-full grid-cols-3 text-sm font-medium tracking-wider">
            <TradingComponent
              header={t('tradingAccountCard.accountType')}
              value={accountType}
            />
          </div>
          <div className="flex items-center justify-end">
            {challengeStatus !== ChallengeStatus.Evaluation ? (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <TradingAccountArchiveModal login={login} />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </Link>
  );
}

export function TradingAccountCardWithData({
  login,
}: {
  login: string;
}): JSX.Element {
  const { data, loading } = useGetBrokerAccountQuery({
    variables: {
      id: login,
    },
  });

  if (loading) {
    return (
      <div className="h-40 w-full">
        <Skeleton className="h-full w-full rounded-2xl" />
      </div>
    );
  }

  invariant(data?.brokerAccount, `[${login}] Broker account missing`);

  const { accountType, accountName, challengeStatus } = data.brokerAccount;

  return (
    <TradingAccountCard
      login={login}
      accountType={accountType}
      accountName={accountName ?? ''}
      challengeStatus={challengeStatus}
    />
  );
}
