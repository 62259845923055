import { type ReactNode } from 'react';
import { DiscordButton } from './discord-button';
import { GoogleButton } from './google-button';
import { TwitterButton } from './twitter-button';

export function SocialLoginButtons({
  children,
  googleButtonText,
}: {
  children?: ReactNode;
  googleButtonText?: string;
}): JSX.Element {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex w-full flex-col items-stretch justify-center gap-6">
        <GoogleButton>{googleButtonText}</GoogleButton>
        <div className="grid grid-cols-2 items-center justify-center gap-6">
          <TwitterButton />
          <DiscordButton />
        </div>
      </div>
      {children}
    </div>
  );
}
