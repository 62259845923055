import { cn } from '@/utils';
import { type InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox, type CheckboxProps } from './checkbox';

interface FormCheckboxProps
  extends InputHTMLAttributes<HTMLInputElement>,
    CheckboxProps {
  name: string;
}

export function FormCheckbox({
  name,
  id,
  children,
  ...props
}: FormCheckboxProps): JSX.Element {
  const {
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useFormContext();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setValue(name, e.target.checked);
    trigger(name);
  }

  return (
    <div>
      <label htmlFor={id} className="flex items-center gap-2">
        <Checkbox
          checked={getValues(name) as boolean}
          errors={errors[name]?.message as string}
          onChange={handleChange}
          id={id}
          {...props}
        />
        <span
          className={cn(
            'block cursor-pointer text-xs text-default-gray-950 dark:text-white',
            {
              'text-danger dark:text-danger': errors[name],
            },
          )}
        >
          {children}
        </span>
      </label>
    </div>
  );
}
