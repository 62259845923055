import { Button } from '@/components/base/button';
import { TradingAccountCardWithData } from '@/components/trading-account-card';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChallengeStatus, useGetBrokerAccountsQuery } from '@graphql/index';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

function DashboardTrading(): JSX.Element {
  const { data } = useGetBrokerAccountsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const brokerAccounts = data?.me.brokerAccounts ?? [];
  const { t } = useTranslation();
  const activeAccounts = brokerAccounts?.filter(
    (account) => account.challengeStatus === ChallengeStatus.Evaluation,
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <h1 className="text-sm dark:text-white">
          {t('dashboard.tradingTitle')}
        </h1>
        <Button
          size="xsmall"
          className="bg-default-gray-700 text-white hover:bg-danger dark:bg-default-gray dark:hover:bg-danger"
        >
          <Link to="/dashboard/trading-accounts" className="uppercase">
            {t('dashboard.seeAll')} <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </Button>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
        {activeAccounts.slice(0, 3).map((account) => {
          return (
            <TradingAccountCardWithData
              key={account.id}
              login={account.login}
            />
          );
        })}
      </div>
    </div>
  );
}
export default DashboardTrading;
