import { Avatar, AvatarFallback, AvatarImage } from '@/components/base/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/base/dropdown-menu';
import { Faq } from '@/components/base/faq';
import { ThemeSwitcher } from '@/components/base/mode-switcher';
import { CredentialsContext } from '@/context/credentials-context';
import { useCurrentUser } from '@/context/current-user-context';
import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@tanstack/react-router';
import { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

export function ProfileDropdown(): JSX.Element {
  const { clearToken, setToken, token } = useContext(CredentialsContext);
  const data = useCurrentUser();
  const [cookie] = useCookies(['ADMIN_TOKEN']);
  const { t } = useTranslation();

  function logout(): void {
    if (cookie.ADMIN_TOKEN && token !== cookie.ADMIN_TOKEN) {
      setToken(String(cookie.ADMIN_TOKEN));
      location.reload();
    } else {
      clearToken();
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="cursor-pointer" asChild>
        <div className="relative">
          <div className="flex h-full items-center justify-center gap-5">
            <Avatar>
              <AvatarImage alt="@shadcn" />
              <AvatarFallback>
                {data.me.firstName[0]}
                {data.me.lastName[0]}
              </AvatarFallback>
            </Avatar>
            <div className="hidden lg:block">
              <h1 className="text-sm">
                {data.me.firstName} {data.me.lastName}
              </h1>
              <p className="text-xs text-default-gray">{data.me.email}</p>
            </div>
          </div>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="min-w-60" align="end" sideOffset={20}>
        <DropdownMenuLabel className="block lg:hidden">
          <div className="flex items-center justify-end gap-3">
            <Faq iconClassname="h-5" />
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator className="block lg:hidden" />
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={(e) => {
              e.preventDefault();
            }}
            className="flex justify-between lg:hidden"
          >
            <span>{t('dropDown.switcherText')}</span>
            <ThemeSwitcher />
          </DropdownMenuItem>
          <Link to="/dashboard/settings/edit-profile">
            <DropdownMenuItem>{t('dropDown.settings')}</DropdownMenuItem>
          </Link>
          <Link to="/dashboard/settings/billing-history">
            <DropdownMenuItem>{t('settings.orderHistory')}</DropdownMenuItem>
          </Link>
          <DropdownMenuItem
            className="flex w-full justify-between"
            onClick={() => {
              logout();
            }}
          >
            <span>{t('dropDown.signOut')}</span>
            <FontAwesomeIcon className="h-5" icon={faArrowRightFromBracket} />
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
