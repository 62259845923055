import Section from '@/components/base/section';
import { Skeleton } from '@/components/base/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/base/table';
import { cn } from '@/utils';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useGetAllUserCertificatesQuery,
  type CertificateFragment,
} from '@graphql/index';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import invariant from 'tiny-invariant';

import { CertificateDialog } from './certificate-dialog';
import { defaultColumns } from './columns';

export function UserCertificates({
  data,
}: {
  data: (CertificateFragment | null)[];
}): JSX.Element {
  const [currentId, setCurrentId] = useState<string | null>(null);
  const intl = useIntl();
  const { t } = useTranslation();
  const columns = useMemo(() => defaultColumns(intl, { t }), [intl, t]);
  const table = useReactTable({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="flex h-full flex-col justify-between gap-6">
      <Section className="overflow-visible p-0 sm:p-0" variant="outline">
        <Table className=" overflow-visible">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                className="[&>*:first-child]:pl-5 [&>*:last-child]:pr-5"
                id={headerGroup.id}
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header) => {
                  const id = header.column.columnDef.id;
                  const isIconsId = id === 'icons';
                  return (
                    <TableHead
                      key={header.id}
                      className={cn('w-[100px] px-3 py-3 text-left sm:px-6', {
                        'text-right': isIconsId,
                      })}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                className="group relative text-xs text-default-gray-400 hover:bg-gray-100 sm:text-sm"
                id={row.id}
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => {
                  const id = cell.column.columnDef.id;
                  const isIconsId = id === 'icons';
                  const isTitleRow = id === 'certificate';
                  return (
                    <TableCell
                      className="whitespace-nowrap px-3 py-3 sm:px-6"
                      key={cell.id}
                      align={isIconsId ? 'right' : 'left'}
                    >
                      <div className={cn({ 'flex gap-5': isTitleRow })}>
                        {isTitleRow && (
                          <button
                            type="button"
                            onClick={() => {
                              setCurrentId(row.original?.ulidx ?? null);
                            }}
                          >
                            <img
                              alt="certificate thumbnail"
                              className="h-10 min-w-10"
                              src={`https://certificates.monevis.com/api/certificate?id=${row.original?.ulidx}&output=image&size=40`}
                            />
                          </button>
                        )}
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                        {isIconsId && (
                          <button
                            data-testid="view-certificate-button"
                            type="button"
                            className="ml-4"
                            onClick={() => {
                              if (row.original) {
                                setCurrentId(row.original.ulidx);
                              }
                            }}
                          >
                            <FontAwesomeIcon
                              className="h-4 text-default-gray-950 dark:text-white"
                              icon={faEye}
                            />
                          </button>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>

          {table.getRowModel().rows.length === 0 && (
            <TableRow className="border-none">
              <TableCell
                colSpan={columns.length}
                className="whitespace-nowrap px-3 py-3 sm:px-6"
              >
                <div className="flex w-full items-center justify-center text-default-gray-950 dark:text-white">
                  <span>{t('certificate.noCertificates')}</span>
                </div>
              </TableCell>
            </TableRow>
          )}
        </Table>
      </Section>

      <CertificateDialog
        id={currentId}
        onClose={() => {
          setCurrentId(null);
        }}
        open={Boolean(currentId)}
      />
    </div>
  );
}

export function UserCertificatesWithData(): JSX.Element {
  const { data, loading } = useGetAllUserCertificatesQuery();

  if (loading) {
    return <Skeleton className="h-full max-h-60 w-full min-w-80 rounded-xl" />;
  }

  invariant(data?.getAllUserCertificates, `Certificates missing`);

  const dataSortedByDate = [...(data?.getAllUserCertificates ?? [])].sort(
    (a, b) => {
      const dateA = new Date(a?.createdAt ?? 0).getTime();
      const dateB = new Date(b?.createdAt ?? 0).getTime();
      return dateB - dateA;
    },
  );

  return <UserCertificates data={dataSortedByDate} />;
}
