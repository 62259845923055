import React from 'react';
import { Helmet } from 'react-helmet';
import PageErrorBoundary from '../page-error-boundary';

interface PageWrapperProps {
  title: string;
  description?: string;
  children: React.ReactNode;
  meta?: React.DetailedHTMLProps<
    React.MetaHTMLAttributes<HTMLMetaElement>,
    HTMLMetaElement
  >[];
}

export function PageWrapper({
  title,
  meta,
  children,
}: PageWrapperProps): JSX.Element {
  return (
    <PageErrorBoundary>
      <div className="h-full">
        <Helmet title={title} meta={meta} />
        {children}
      </div>
    </PageErrorBoundary>
  );
}
