import Chart from 'react-apexcharts';

function options(limit: number, dailyLimit: number, target?: number) {
  const rawMax = Math.max(limit, dailyLimit, target ?? 0);
  const rawMin = Math.min(limit, dailyLimit, target ?? limit);

  const max = Math.ceil(rawMax / 100) * 100;
  const min = Math.floor((rawMin * 0.99) / 100) * 100;

  const config = {
    xaxis: {
      lines: {
        lines: {
          show: true,
        },
      },
      labels: {
        show: true,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    colors: ['#246CF9', '#21ecca'],
    yaxis: {
      labels: {
        show: true,
      },
      min,
      ...(target ? { max } : {}),
    },
    grid: {
      clipMarkers: false,
      yaxis: {
        lines: {
          show: true,
        },
      },
      strokeDashArray: 5,
      borderColor: '#282C38',
    },
    stroke: {
      curve: 'straight',
      width: [2, 2],
      dropShadow: {
        enabled: true,
        top: 10,
        left: 10,
        blur: 5,
        opacity: 0.5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: 'dark',
    },
    chart: {
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      selection: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 2,
        color: ['#246CF9', '#21ecca'],
        opacity: 0.85,
      },
    },
    annotations: {
      yaxis: [
        {
          y: limit,
          strokeDashArray: 4,
          borderColor: '#CB002D',
          label: {
            borderColor: '#cb002d85',
            style: {
              color: '#fff',
              background: '#cb002d85',
            },
            text: 'Overall Limit',
          },
        },
        {
          y: dailyLimit,
          strokeDashArray: 4,
          borderColor: '#fff',
          label: {
            offsetX: -50,
            borderColor: '#ffffff26',
            style: {
              color: '#fff',
              background: '#ffffff26',
            },
            text: 'Daily limit',
          },
        },
      ],
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 1,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
  };

  // Target is not on funded account
  if (target) {
    config.annotations.yaxis.push({
      y: target,
      strokeDashArray: 4,
      borderColor: '#0a5d2e',
      label: {
        borderColor: '#0a5d2e96',
        style: {
          color: '#fff',
          background: '#0a5d2e96',
        },
        text: 'Target Profit',
      },
    });
  }
  return config;
}

export function ChartComponent({
  series,
  target,
  limit,
  dailyLimit,
  categories,
}: {
  series: {
    name: string;
    data: (number | null | undefined)[] | undefined;
  }[];
  target?: number;
  limit: number;
  dailyLimit: number;
  categories: (string | null | undefined)[];
}): JSX.Element {
  return (
    <div className="min-h-56">
      {/* eslint-disable-next-line -- . */
      /*@ts-expect-error*/}
      <Chart
        options={{
          ...options(limit, dailyLimit, target),
          xaxis: { ...options(limit, dailyLimit, target).xaxis, categories },
        }}
        series={series}
        type="area"
        width="100%"
        height="100%"
      />
    </div>
  );
}
