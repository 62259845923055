import { Button } from '@/components/base/button';
import { Logo, LogoVariant } from '@/components/base/logo';
import MenuItem from '@/components/base/menu/menu-item';
import MenuLabel from '@/components/base/menu/menu-label';
import { CredentialsContext } from '@/context/credentials-context';
import { useCurrentUser } from '@/context/current-user-context';
import { useScreenSize } from '@/hooks/use-screen-size';
import { cn } from '@/utils';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowRightFromBracket,
  faCalendarAlt,
  faChartLine,
  faCubes,
  faFileCertificate,
  faFileDownload,
  faGear,
  faHouse,
  faLock,
  faUsers,
  faWallet,
} from '@fortawesome/pro-regular-svg-icons';
import { faFire } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useRouterState } from '@tanstack/react-router';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './language-switcher';

export default function Sidebar({
  closeMenu,
}: {
  closeMenu?: () => void;
}): JSX.Element {
  const [isOpen, _setIsOpen] = useState(true);
  const { isLarge } = useScreenSize();
  const router = useRouterState();
  const { clearToken } = useContext(CredentialsContext);
  const user = useCurrentUser();
  const { t } = useTranslation();

  function checkMenuItemActive(to: string): boolean {
    if (router.location.pathname === to) {
      return true;
    }
    return false;
  }

  /*const toggleSidebar = (): void => {
    setIsOpen(!isOpen);
  };*/

  return (
    <div className="relative h-full w-full">
      <section
        className={cn(
          'flex h-full w-full min-w-[100vw] flex-col divide-y divide-divider rounded-none bg-white pb-3 transition-all duration-300 dark:divide-divider-900 dark:bg-default-gray-950 sm:min-w-[300px] lg:min-w-[250px] lg:rounded-2xl',
          {
            'min-w-0 md:min-w-[75px] md:max-w-[75px] lg:min-w-[75px]': !isOpen,
          },
        )}
      >
        {/* TOP SECTION */}
        <div
          className={cn(
            'flex flex-row flex-wrap items-center justify-center gap-2 overflow-hidden p-5',
            { 'justify-center': isOpen },
          )}
        >
          <div className="mx-auto h-7 w-fit text-black dark:text-white">
            <Logo variant={isOpen ? LogoVariant.Monevis : LogoVariant.Symbol} />
          </div>
          {!isLarge && (
            <div
              className={cn('flex w-fit justify-end', {
                'justify-center': !isOpen,
              })}
            >
              <button
                onClick={closeMenu}
                className="p-2 text-default-gray-900 dark:text-white"
                type="button"
              >
                <FontAwesomeIcon className="w-5" icon={faXmark} />
              </button>
            </div>
          )}
        </div>

        {/* MIDDLE MENU ITEMS */}
        <div className="flex h-full flex-col justify-between overflow-y-auto">
          <div>
            <div
              className={cn(
                'flex w-full flex-col pb-5 transition-all duration-300 sm:max-w-[75px]',
                { 'sm:max-w-[300px] lg:max-w-[250px]': isOpen },
              )}
            >
              <Link className="flex w-full flex-col" to="/order">
                <Button className="mx-2 my-4">
                  <FontAwesomeIcon icon={faFire} className="h-[20px]" />
                  <span className="block leading-3">{t('sidebar.button')}</span>
                </Button>
              </Link>
              <div className="flex flex-col gap-[10px]">
                <MenuItem
                  active={checkMenuItemActive('/dashboard/home')}
                  icon={faHouse}
                  isOpen={isOpen}
                  to="/dashboard/home"
                  onClick={closeMenu}
                >
                  {t('sidebar.dashboard')}
                </MenuItem>
                <MenuItem
                  active={checkMenuItemActive('/dashboard/trading-accounts')}
                  icon={faCubes}
                  isOpen={isOpen}
                  to="/dashboard/trading-accounts"
                  onClick={closeMenu}
                >
                  {t('sidebar.tradingAccounts')}
                </MenuItem>
                <MenuItem
                  active={checkMenuItemActive(`/dashboard/accounts/terminal`)}
                  isOpen={isOpen}
                  icon={faChartLine}
                  to="/dashboard/accounts/terminal"
                  onClick={closeMenu}
                >
                  {t('sidebar.terminal')}
                </MenuItem>
                <MenuItem
                  active={checkMenuItemActive('/dashboard/payout')}
                  icon={faWallet}
                  isOpen={isOpen}
                  to="/dashboard/payout"
                  onClick={closeMenu}
                >
                  {t('sidebar.payout')}
                </MenuItem>
                <MenuItem
                  active={checkMenuItemActive('/dashboard/calendar')}
                  icon={faCalendarAlt}
                  isOpen={isOpen}
                  to="/dashboard/calendar"
                  onClick={closeMenu}
                >
                  {t('sidebar.calendar')}
                </MenuItem>
                <MenuItem
                  active={checkMenuItemActive('/dashboard/user-certificates')}
                  icon={faFileCertificate}
                  isOpen={isOpen}
                  to="/dashboard/user-certificates"
                  onClick={closeMenu}
                >
                  {t('sidebar.certificate')}
                </MenuItem>
                <MenuItem
                  active={checkMenuItemActive('/dashboard/affiliate')}
                  icon={faUsers}
                  isOpen={isOpen}
                  to="/dashboard/affiliate"
                  onClick={closeMenu}
                >
                  {t('sidebar.affiliate')}
                </MenuItem>
                <MenuItem
                  active={checkMenuItemActive('/dashboard/resources')}
                  icon={faFileDownload}
                  isOpen={isOpen}
                  to="/dashboard/resources"
                  onClick={closeMenu}
                >
                  {t('sidebar.resources')}
                </MenuItem>

                {user?.me.role === 'ADMIN' && (
                  <MenuItem
                    active={checkMenuItemActive('/dashboard/impersonate')}
                    icon={faLock}
                    isOpen={isOpen}
                    to="/dashboard/impersonate"
                    onClick={closeMenu}
                  >
                    {t('sidebar.impersonate')}
                  </MenuItem>
                )}
              </div>
            </div>
          </div>

          {/* BOTTOM MENU ITEMS */}
          <div className="mt-auto flex flex-col gap-1">
            <MenuLabel isOpen={isOpen}>{t('sidebar.changeLanguage')}</MenuLabel>
            <MenuItem>
              <LanguageSwitcher />{' '}
            </MenuItem>
            <MenuItem
              onClick={closeMenu}
              icon={faGear}
              isOpen={isOpen}
              to="/dashboard/settings/edit-profile"
            >
              {t('sidebar.settings')}
            </MenuItem>
            <button
              type="button"
              onClick={() => {
                clearToken();
              }}
            >
              <MenuItem icon={faArrowRightFromBracket} isOpen={isOpen}>
                {t('sidebar.logOut')}
              </MenuItem>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
