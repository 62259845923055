import { Link, useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { Header } from '../auth-form-layout';

import { Button } from '@/components/base/button';
import { PageHeading } from '@/components/base/page-heading';

export function InvalidLink(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex h-full w-full justify-center overflow-y-scroll rounded-none bg-default-gray-950 p-5 text-white shadow-xl sm:p-10 lg:rounded-tl-[20px]">
      <div className="relative mx-auto h-full w-full">
        <Header
          onClickBack={() => {
            navigate({ to: '/auth/login' });
          }}
          showGoBackOption
          arrowBackText={t('invalidLink.signIn')}
        />
        <div className="flex h-full w-full flex-col items-center justify-center gap-5 text-center">
          <PageHeading subheading={t('invalidLink.subheading')}>
            {t('invalidLink.mainHeading')}
          </PageHeading>
          <Link to="/auth/login">
            <Button>{t('invalidLink.backToLogin')}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
