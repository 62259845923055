import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { OrderDetailPage } from '@/components/order/order-detail';
import { usePageLayout } from '@/context/layout-context';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/(global)/order/_layout/pay/$order-id')({
  component: () => (
    <PageWrapper title="Order - Monevis">
      <OrderDetail />
    </PageWrapper>
  ),
  validateSearch: (
    search: Record<string, unknown>,
  ): { hash?: string; status?: string } => {
    return {
      hash: search.hash ? String(search.hash) : undefined,
      status: search.status ? String(search.status) : undefined,
    };
  },
});

function OrderDetail(): JSX.Element {
  const { 'order-id': orderId } = Route.useParams();
  const { hash, status } = Route.useSearch();
  usePageLayout({ title: `Order - ${orderId}` });

  return (
    <ScreenContainer>
      <OrderDetailPage orderId={orderId} hash={hash} status={status} />
    </ScreenContainer>
  );
}

export default OrderDetail;
