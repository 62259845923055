import { CombinedProgressBar } from '@/components/base/progress-bar/combined-progress-bar';
import { cva, type VariantProps } from 'class-variance-authority';

const ChallengeVariants = cva('', {
  variants: {
    variant: {
      yellow: 'text-[#fef08a]',
      green: 'text-[#2fe0a0]',
      red: 'text-[#CB002D]',
      blue: 'text-[#236df9]',
    },
  },
  defaultVariants: {
    variant: 'yellow',
  },
});

export type ProgressVariant = 'yellow' | 'green' | 'red' | 'blue';

interface ChallengeProgressBarProps
  extends VariantProps<typeof ChallengeVariants> {
  percentage: number;
  maxPercentage?: number;
  displayedPercentage?: number | string;
  basePercentage?: number;
}

export const variantMap: Record<
  string,
  { strokeColor: string; shadowSpread: string }
> = {
  yellow: { strokeColor: '#fef08a', shadowSpread: '15px' },
  green: { strokeColor: '#2fe0a0', shadowSpread: '0' },
  red: { strokeColor: '#CB002D', shadowSpread: '0' },
  blue: { strokeColor: '#236df9', shadowSpread: '15px' },
};

function ChallengeProgressBar({
  variant,
  percentage,
  maxPercentage,
  displayedPercentage,
  basePercentage,
  ...props
}: ChallengeProgressBarProps): JSX.Element {
  const { strokeColor, shadowSpread } = variantMap[variant!];

  const canvasWidth = 80;
  return (
    <div className="relative h-fit w-fit" {...props}>
      <CombinedProgressBar
        basePercentage={basePercentage}
        displayedPercentage={displayedPercentage}
        maxPercentage={maxPercentage}
        percentage={percentage}
        canvasWidth={canvasWidth}
        barOptions={{
          barWidth: 8,
          activeBar: {
            color: strokeColor,
            shadow: `0px 0px ${shadowSpread} ${strokeColor}`,
          },
          backgroundBar: {
            color: '#3C4254',
          },
          circle: {
            radius: 1.5,
            color: 'white',
            hidable: true,
          },
        }}
        clockOptions={{
          rectangleOptions: {
            width: 1,
            height: 2,
            count: 25,
            colors: {
              fill: '#3C4254',
              activeFill: '#FFFFFF',
            },
          },
          positionY: 10,
          canvasWidth: canvasWidth / 1.38,
          rangeType: 'closest',
        }}
        textOptions={{
          positionY: 1,
          style: {
            color: strokeColor,
            fontSize: 14,
            fontWeight: 500,
          },
        }}
      />
    </div>
  );
}

ChallengeProgressBar.displayName = 'ChallengeProgressBar';

export { ChallengeProgressBar, ChallengeVariants };
