import { cn } from '@/utils';
import { faCheck, faExclamation } from '@fortawesome/pro-solid-svg-icons';
import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { type InputHTMLAttributes } from 'react';

interface CustomIconProps extends FontAwesomeIconProps {
  'data-testid'?: string;
  className?: string;
}

export function CustomIcon({
  'data-testid': dataTestId,
  className,
  icon,
  ...props
}: CustomIconProps): JSX.Element {
  return (
    <FontAwesomeIcon
      data-testid={dataTestId}
      icon={icon}
      height={15}
      width={20}
      className={cn(`absolute inset-0 m-auto`, className)}
      {...props}
    />
  );
}

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  errors?: string;
  'data-testid'?: string;
  'aria-label'?: string;
  id?: string;
}

export function Checkbox({
  errors,
  onChange,
  'data-testid': dataTestId = 'checkbox-testid',
  'aria-label': ariaLabel = 'checkbox-label',
  id = 'checkbox-testid',
  ...props
}: CheckboxProps): JSX.Element {
  return (
    <div className="group flex cursor-pointer items-center gap-2">
      <div className="relative max-h-5 max-w-5 cursor-pointer">
        <input
          {...props}
          aria-label={ariaLabel}
          data-testid={dataTestId}
          id={id}
          onChange={onChange}
          type="checkbox"
          className={cn(
            'h-5 w-5 cursor-pointer appearance-none rounded-full border border-default-gray-950 checked:border-green-400 checked:bg-green-400 dark:border-white',
            { 'border-danger dark:border-danger': errors },
          )}
        />

        <CustomIcon
          icon={faCheck}
          className={cn('hidden text-white group-has-[:checked]:block')}
          data-testid="checkbox-icon-check"
        />

        {errors && (
          <CustomIcon
            icon={faExclamation}
            className="text-default-gray-950 dark:text-white"
            data-testid="checkbox-icon-error"
          />
        )}
      </div>
    </div>
  );
}
