import { Button } from '@/components/base/button';
import { Dialog, DialogContent } from '@/components/base/dialog';
import { PageHeading } from '@/components/base/page-heading';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export function SignInModal({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (_val: boolean) => void;
}): JSX.Element {
  // const { login, server, masterPassword } = data;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        setOpen(value);
      }}
    >
      <DialogContent className="max-w-3xl gap-6 md:pb-0">
        <div className="flex flex-col items-start justify-center gap-4 pb-6 text-2xl">
          <PageHeading
            size="xxlarge"
            className="mb-4"
            subheading={t('accountAlreadyExists.subheading')}
          >
            {t('accountAlreadyExists.heading')}
          </PageHeading>
          <div className="flex gap-4">
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="outlined"
            >
              {t('accountAlreadyExists.closeButton')}
            </Button>
            <Link to="/auth/login" search={{ redirect: '/order' }}>
              <Button>{t('accountAlreadyExists.signInButton')}</Button>
            </Link>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
