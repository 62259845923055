import { cn } from '@/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import { type ReactNode } from 'react';

export const TableBadgeVariants = cva(
  'flex text-default-gray-950 items-center justify-center rounded-lg px-2.5 py-1 text-sm font-semibold w-fit',
  {
    variants: {
      variant: {
        default: 'bg-default-gray-500',
        red: 'bg-danger text-white',
        yellow: 'bg-yellow-400',
        green: 'bg-emerald-400',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface TableBadgeProps
  extends VariantProps<typeof TableBadgeVariants> {
  className?: string;
  children: ReactNode;
}

export function TableBadge({
  className,
  children,
  variant,
}: TableBadgeProps): JSX.Element {
  return (
    <div
      data-testid={variant}
      className={cn(TableBadgeVariants({ variant }), className)}
    >
      {children}
    </div>
  );
}
