import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

interface PageLayoutContextProps {
  title: string;
  setTitle: (newTitle: string) => void;
}

export const PageLayoutContext = createContext<PageLayoutContextProps>({
  title: '',
  setTitle: () => false,
});

export function PageLayoutProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [title, setTitle] = useState('Dashboard');

  return (
    <PageLayoutContext.Provider value={{ title, setTitle }}>
      {children}
    </PageLayoutContext.Provider>
  );
}

export function usePageLayout(options?: { title?: string }): { title: string } {
  const { title, setTitle } = useContext(PageLayoutContext);

  useEffect(() => {
    if (options?.title) {
      setTitle(options.title);
    }
  }, [options, setTitle]);

  return { title };
}
