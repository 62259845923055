import type { SVGProps } from 'react';

export default function SvgSpeaker(
  props: SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#0078D4"
        d="M18.244 12a8.19 8.19 0 0 1-3.059 6.39l1.49 1.49a.407.407 0 0 0 .562.016 10.7 10.7 0 0 0 3.469-7.895c0-3.125-1.34-5.933-3.47-7.896a.407.407 0 0 0-.561.017l-1.49 1.49A8.19 8.19 0 0 1 18.245 12"
      />
      <path
        fill="#199BE2"
        d="M15.618 12c0 1.858-.912 3.5-2.308 4.514l1.875 1.876A8.19 8.19 0 0 0 18.244 12a8.19 8.19 0 0 0-3.059-6.39L13.31 7.486A5.57 5.57 0 0 1 15.618 12"
      />
      <path
        fill="#35C1F1"
        d="M15.618 12c0-1.859-.912-3.5-2.309-4.515l-1.899 1.9a2.953 2.953 0 0 1 0 5.229l1.9 1.899a5.57 5.57 0 0 0 2.308-4.514"
      />
      <path
        fill="#50E6FF"
        d="m8.795 12 2.614 2.615a2.953 2.953 0 0 0 0-5.23z"
      />
      <path
        fill="#5C6369"
        d="M6.117 15.765H4.235a.94.94 0 0 1-.941-.942V9.176a.94.94 0 0 1 .941-.94h1.882z"
      />
      <path fill="#727D86" d="m10.354 20.471-4.236-4.706V8.236l4.236-4.706z" />
      <path
        fill="url(#speaker_svg__a)"
        d="M10.824 3.06a.47.47 0 0 0-.47.47v16.941a.47.47 0 0 0 .94 0V3.53a.47.47 0 0 0-.47-.47"
      />
      <defs>
        <linearGradient
          id="speaker_svg__a"
          x1={6.308}
          x2={14.084}
          y1={6.291}
          y2={16.122}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A1AAB3" />
          <stop offset={1} stopColor="#8F979E" />
        </linearGradient>
      </defs>
    </svg>
  );
}
