import { cn } from '@/utils';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva, type VariantProps } from 'class-variance-authority';
import React from 'react';

const BadgeVariants = cva(
  'inline-flex items-center justify-center rounded-md text-xs font-normal',
  {
    variants: {
      variant: {
        primary:
          'dark:bg-default-gray-950 bg-default-gray-200 dark:text-white text-default-gray-950 tracking-wide',
        red: 'bg-danger/15 border border-danger text-danger text-black dark:text-white',
        blue: 'bg-blue-500/15 border border-blue-500 dark:bg-blue-500/15 dark:border-blue-500 text-black dark:text-white',
        green:
          'bg-default-green/15 border border-default-green-700 text-black dark:text-white dark:border-default-green',
        yellow:
          'bg-yellow-200 border border-yellow-700 text-yellow-900 dark:text-yellow-700 dark:border-yellow-700',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
);
const IconVariants = cva('', {
  variants: {
    variant: {
      blue: 'text-blue-600',
      green: 'text-green-600',
      red: 'text-danger',
      primary: 'text-white',
      yellow: 'text-yellow-900',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});
export interface BadgeProps extends VariantProps<typeof BadgeVariants> {
  iconRight?: IconProp;
  iconRightClassName?: string;
  iconLeft?: IconProp;
  children?: React.ReactNode;
  className?: string;
  'data-testid'?: string;
}

function Badge({
  variant,
  iconLeft,
  iconRight,
  iconRightClassName,
  children,
  className,
  'data-testid': dataTestId = 'badge',
}: BadgeProps): JSX.Element {
  return (
    <div
      data-testid={dataTestId}
      className={cn(BadgeVariants({ variant }), className)}
    >
      {iconLeft && (
        <FontAwesomeIcon
          icon={iconLeft}
          className={cn(IconVariants({ variant }), 'mr-2 h-4 w-4')}
        />
      )}
      {children}
      {iconRight && (
        <FontAwesomeIcon
          icon={iconRight}
          className={cn(
            IconVariants({ variant }),
            'ml-2 h-4 w-4',
            iconRightClassName,
          )}
        />
      )}
    </div>
  );
}
export { Badge, BadgeVariants };
