import type { SVGProps } from 'react';

export default function SvgTwitter(
  props: SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 150 150"
      {...props}
    >
      <path
        fill="currentColor"
        d="M89 63.5 143.6 0h-12.9L83.2 55.1 45.3 0H1.6l57.3 83.4L1.6 150h12.9l50.1-58.2 40 58.2h43.7zM71.2 84.1l-5.8-8.3L19.2 9.7h19.9L76.4 63l5.8 8.3 48.5 69.3h-19.9z"
      />
    </svg>
  );
}
