import { cn } from '@/utils';
import { blacklist } from '@/utils/country-list';
import { useGeolocationQuery } from '@graphql/index';
import { type VariantProps } from 'class-variance-authority';
import IntlTelInput, { type IntlTelInputRef } from 'intl-tel-input/react';
import 'intl-tel-input/styles';
import { forwardRef, useCallback, useState } from 'react';
import { Loading } from '../loading';
import { InputVariants } from './input';
import './phone-input.css';

export interface PhoneNumberInputProps
  extends VariantProps<typeof InputVariants> {
  className?: string;
  onChange: (value?: string) => void;
  value?: string;
  onError: (error: string | null) => void;
}
const errorMap = [
  'Invalid number',
  'Invalid country code',
  null,
  'Too long',
  'Invalid number',
];

const PhoneNumberInput = forwardRef<IntlTelInputRef, PhoneNumberInputProps>(
  ({ className, variant, onChange, value, onError }, ref) => {
    const [isValid, setIsValid] = useState<boolean | null>(null);
    const { data: geolocation, loading } = useGeolocationQuery();

    const handleError = useCallback(
      (errorCode: number | null): void => {
        if (isValid) {
          onError('');
        } else {
          const errorMessage = errorMap[errorCode ?? 0];
          onError(errorMessage);
        }
      },
      [isValid],
    );
    if (loading) {
      return <Loading />;
    }

    return (
      <IntlTelInput
        ref={ref}
        onChangeNumber={onChange}
        onChangeValidity={setIsValid}
        onChangeErrorCode={handleError}
        initialValue={value}
        initOptions={{
          initialCountry: geolocation?.geolocation.country ?? 'auto',
          containerClass: 'text-sm',
          nationalMode: true,
          excludeCountries: blacklist,
          utilsScript:
            'https://cdn.jsdelivr.net/npm/intl-tel-input@23.3.2/build/js/utils.js',
        }}
        inputProps={{ className: cn(InputVariants({ variant }), className) }}
      />
    );
  },
);
PhoneNumberInput.displayName = 'PhoneNumberInput';
export default PhoneNumberInput;
