import { MainWrapper } from '@/components/(authenticated)/settings-layout/main-wrapper';
import { OrdersTableWithData } from '@/components/(authenticated)/settings-layout/order-history/table/orders-table';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/_layout-settings/settings/billing-history',
)({ component: SettingsPage });

function SettingsPage(): JSX.Element {
  const { t } = useTranslation();
  return (
    <MainWrapper title={t('orderHistoryPage.orderHistoryTitle')}>
      <OrdersTableWithData />
    </MainWrapper>
  );
}

export default SettingsPage;
