import {
  filteredCountriesObject,
  isBannedCountry,
  type Country,
} from '@/utils/country-list';
import { useMemo, useState } from 'react';
import { ReactCountryFlag } from 'react-country-flag';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../select';

interface CountryPickerProps extends React.ComponentProps<typeof Select> {
  name: string;
  'data-testid'?: string;
}

export function CountryPicker({
  name,
  'data-testid': dataTestId,
  ...props
}: CountryPickerProps): JSX.Element {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const { trigger, setValue, watch } = useFormContext();
  const watchCountry = watch(name) as string;

  const countries: Country[] = useMemo(() => {
    return Object.values(filteredCountriesObject).filter((country) =>
      country.name.toLowerCase().startsWith(searchValue.toLowerCase()),
    );
  }, [searchValue]);

  function handleChangeCountry(value: string): void {
    setValue(name, value.toUpperCase());
    void trigger(name);
  }

  const currentCountry = watch(name) as string;

  return (
    <Select
      {...props}
      data-testid={dataTestId}
      value={currentCountry}
      onValueChange={(currentValue) => {
        handleChangeCountry(
          currentValue === currentCountry ? '' : currentValue,
        );
      }}
    >
      <SelectTrigger size="sm" variant="secondary">
        <SelectValue placeholder={t('countryPicker.selectCountry')} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>
            <input
              className="border-b border-default-gray-400 bg-transparent"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder={t('countryPicker.searchCountry')}
            />
          </SelectLabel>
          {countries.map((country: Country) => (
            <SelectItem
              data-testid={country.name}
              key={country.value}
              value={country.value}
            >
              <div className="flex items-center gap-2">
                <ReactCountryFlag
                  countryCode={country.value}
                  style={{
                    height: '1rem',
                    width: '1rem',
                    borderRadius: '100%',
                    objectFit: 'cover',
                  }}
                  svg
                />
                {country.name}
              </div>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
      {isBannedCountry(watchCountry) && (
        <div
          data-testid="bannedCountryInfo"
          className="mt-2 rounded-lg border border-danger bg-danger/50 px-4 py-1 text-white/80"
        >
          {t('countryPicker.blockedCountry')}
        </div>
      )}
    </Select>
  );
}
