import { cn } from '@/utils';
import { type ReactNode } from 'react';
import Styles from './styles.module.css';

export function AuthLayout({
  children,
  sideContent,
}: {
  children: ReactNode;
  sideContent?: ReactNode;
}): JSX.Element {
  return (
    <div className={cn(Styles.styles, 'light')}>
      <div
        className="hidden lg:block"
        style={{
          gridArea: 'side',
          overflowY: 'auto',
          overflowX: 'hidden',
          position: 'relative',
          width: '110%',
        }}
      >
        <img
          alt="background"
          src="/sign-in-background.png"
          className="h-full w-[101%] max-w-none object-cover"
        />
        {sideContent}
      </div>

      <div
        style={{
          gridArea: 'main',
          overflowX: 'hidden',
          position: 'relative',
          overflowY: 'auto',
        }}
      >
        {children}
      </div>
    </div>
  );
}
