import { cn } from '@/utils';
import { Slot } from '@radix-ui/react-slot';
import { type ReactNode } from 'react';

export function NavLink({
  className,
  asChild = false,
  children,
  ...props
}: {
  className?: string;
  asChild?: boolean;
  children: ReactNode;
}): JSX.Element {
  const Component = asChild ? Slot : 'a';

  return (
    <Component
      {...props}
      className={cn(
        'text-md cursor-pointer text-lg font-medium text-blue-400 hover:underline',
        className,
      )}
    >
      {children}
    </Component>
  );
}
