import { Avatar, AvatarFallback, AvatarImage } from '@/components/base/avatar';
import { useCurrentUser } from '@/context/current-user-context';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function UploadAvatar(): JSX.Element {
  const data = useCurrentUser();
  const { firstName, lastName } = data.me;
  const [avatarImage, _setAvatarImage] = useState('');
  const { t } = useTranslation();
  /*function handleImageChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event.target.files?.[0]) {
      setAvatarImage(URL.createObjectURL(event.target.files[0]));
    }
  }*/

  return (
    <div className="relative mb-16 w-full">
      <div className="h-28 w-full overflow-hidden rounded-lg">
        <img
          src="/images/profile-bg.png"
          alt="Background"
          className="h-32 w-full object-cover"
        />
      </div>
      <div className="group absolute bottom-0 left-1/2 h-24 w-24 -translate-x-1/2 translate-y-1/2">
        <Avatar className="relative h-24 w-24 text-2xl">
          <AvatarImage src={avatarImage} alt="Avatar" />
          <AvatarFallback>
            {firstName[0]}
            {lastName[0]}
          </AvatarFallback>
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 transition-opacity">
            <label className="flex h-full w-full items-center justify-center rounded-full px-4 py-2 text-white">
              {t('settings.upload')}
              <input
                //type="file"
                className="hidden"
                //onChange={handleImageChange}
              />
            </label>
          </div>
        </Avatar>
        {/*<div className="absolute bottom-0 right-0 z-30">
          <FontAwesomeIcon className="h-5" icon={faCamera} />
        </div>*/}
      </div>
    </div>
  );
}
