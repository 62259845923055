import { cn } from '@/utils';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Faq({
  iconClassname,
}: {
  iconClassname?: string;
}): JSX.Element {
  return (
    <a
      href="https://help.monevis.com "
      rel="noopener"
      target="_blank"
      className="flex h-fit w-fit"
    >
      <FontAwesomeIcon
        icon={faQuestionCircle}
        className={cn('h-6', iconClassname)}
      />
    </a>
  );
}
