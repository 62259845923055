import { Google } from '@/components/icons';
import { useAppConfig } from '@/context/app-config-context';
import { type ReactNode } from 'react';
import { Button } from '../button';

export function GoogleButton({
  children,
}: {
  children?: ReactNode;
}): JSX.Element {
  const {
    AppConfig: { GOOGLE_AUTH_URL },
  } = useAppConfig();
  return (
    <Button
      as="link"
      variant="secondary"
      href={GOOGLE_AUTH_URL}
      icon={<Google className="h-5 w-5 sm:h-6 sm:w-6" />}
    >
      {children}
    </Button>
  );
}
