import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}
export const getOS = (): string => {
  const userAgent = window.navigator.userAgent || window.navigator.vendor;
  if (typeof userAgent !== 'string') {
    return 'unknown';
  }
  if (userAgent.includes('Windows Phone')) {
    return 'Windows';
  }
  if (userAgent.includes('Android')) {
    return 'Android';
  }
  if (
    (userAgent.includes('iPad') ||
      userAgent.includes('iPhone') ||
      userAgent.includes('iPod')) &&
    !navigator.userAgent.includes('Windows')
  ) {
    return 'iOS';
  }
  if (userAgent.includes('Win')) {
    return 'Windows';
  }
  if (userAgent.includes('Macintosh') || userAgent.includes('Mac OS X')) {
    return 'macOS';
  }
  return 'unknown';
};
