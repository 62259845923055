import { Arrowdown } from '@/components/icons';
import { cn } from '@/utils';

interface ToggleSectionProps {
  sectionName: string;
  isOpen?: boolean;
  toggleContent: (sectionName: string) => void;
}

function ToggleSection(props: ToggleSectionProps): JSX.Element {
  const { sectionName, isOpen, toggleContent } = props;

  function handleClick(): void {
    toggleContent(sectionName);
  }

  return (
    <button
      className="mt-4 flex cursor-pointer items-center justify-between rounded-2xl bg-default-gray-200 p-3 px-6 dark:bg-default-gray-950 dark:text-white"
      onClick={handleClick}
      type="button"
    >
      <span className="text-lg font-bold">{sectionName}</span>
      <span>
        <Arrowdown
          className={cn('transition-transform', {
            'rotate-180': !isOpen,
          })}
        />
      </span>
    </button>
  );
}

export default ToggleSection;
