import type { AnalyticsPlugin } from 'analytics';
import { type LeadEventPayload } from '../events/lead';
import { type OrderPayload } from '../events/order';
import { type PurchasePayload } from '../events/purchase';

const allowedEvents = ['Lead', 'Purchase', 'InitiateCheckout'];

interface TrackPayload {
  event: string;
  properties: LeadEventPayload | PurchasePayload | OrderPayload;
}

function trackLead(): void {
  window.twq('event', 'tw-onr3w-onr46', {});
}

function trackPurchase(payload: PurchasePayload): void {
  window.twq('event', 'tw-onr3w-onr43', {
    value: payload.value,
    currency: payload.currency,
    conversion_id: payload.id,
  });
}

function trackCheckout(): void {
  window.twq('event', 'tw-onr3w-onr47', {});
}

export function providerTwitter({
  name = 'twitter',
}: {
  name: string;
}): AnalyticsPlugin {
  // return object for analytics to use
  return {
    /* All plugins require a name */
    name,
    /* Everything else below this is optional depending on your plugin requirements */

    // load provider script to page
    // initialize: () => {},

    page: () => {
      // window.fbq('track', 'PageView');
      // call provider specific page tracking
    },
    track: ({ payload }: { payload: TrackPayload }) => {
      if (allowedEvents.includes(payload.event)) {
        switch (payload.event) {
          case 'Lead':
            trackLead();
            break;
          case 'Purchase':
            trackPurchase(payload.properties as PurchasePayload);
            break;
          case 'InitiateCheckout':
            trackCheckout();
            break;
        }
      }
    },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third party
      return typeof window !== 'undefined' && Boolean(window.twq);
    },
  };
}
