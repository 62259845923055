import { FormattedDate } from '@/components/base/formatted-date';
import { cn } from '@/utils';
import { type AccountTradingPositionFragment } from '@graphql/index';
import { createColumnHelper } from '@tanstack/react-table';
import { intervalToDuration } from 'date-fns';
import { type IntlShape } from 'react-intl';

const columnHelper =
  createColumnHelper<AccountTradingPositionFragment | null>();
export const defaultColumns = (
  intl: IntlShape,
  { t }: { t: (key: string) => string },
) => [
  columnHelper.accessor('id', {
    id: 'id',
    header: () => t('Ticket'),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('dateOpen', {
    id: 'dateOpen',
    header: () => t('table.Opentime'),
    cell: (info) => {
      return <FormattedDate value={info.getValue() ?? ''} dateFormat="PP pp" />;
    },
    enableGlobalFilter: false,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('dateClose', {
    id: 'dateClose',
    header: () => t('table.Closetime'),
    cell: (info) => {
      return <FormattedDate value={info.getValue() ?? ''} dateFormat="PP pp" />;
    },
    enableGlobalFilter: false,
    enableColumnFilter: false,
  }),
  columnHelper.accessor(
    (data) => {
      return { duration: data?.duration, open: data?.dateOpen };
    },
    {
      id: 'duration',
      header: () => t('table.Duration'),
      cell: (info) => {
        const helperDate = intervalToDuration({
          start: 0,
          end: Number(info.getValue().duration) * 1000,
        });
        const zeroPad = (num: number | undefined): string =>
          num ? String(num).padStart(2, '0') : '00';
        return (
          <span>
            {zeroPad(helperDate.hours)}:{zeroPad(helperDate.minutes)}:
            {zeroPad(helperDate.seconds)}
          </span>
        );
      },
      enableGlobalFilter: false,
      enableColumnFilter: false,
    },
  ),

  columnHelper.accessor('symbol', {
    id: 'symbol',
    header: () => t('table.Symbol'),
    cell: (info) => info.getValue(),
  }),

  columnHelper.accessor('action', {
    id: 'action',
    header: () => 'Action',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('lots', {
    id: 'lots',
    header: () => t('table.Lots'),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('priceOpen', {
    id: 'priceOpen',
    header: () => t('table.OpenPrice'),
    cell: (info) => info.getValue() ?? 0,
  }),
  columnHelper.accessor('priceClose', {
    id: 'priceClose',
    header: () => t('table.ClosePrice'),
    cell: (info) => info.getValue() ?? 0,
  }),
  columnHelper.accessor('profit', {
    id: 'profit',
    header: () => t('table.P/L'),
    cell: (info) => {
      return (
        <span
          className={cn(
            { 'text-green-500': Number(info.getValue()) > 0 },
            { 'text-red-500': Number(info.getValue()) < 0 },
          )}
        >
          {intl.formatNumber(info.getValue() ?? 0, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          })}
        </span>
      );
    },
  }),
];
