import { Button } from '@/components/base/button';
import { FormField } from '@/components/base/form/form-field';
import { Input } from '@/components/base/form/input';
import { NavLink } from '@/components/base/nav-link';
import { PageHeading } from '@/components/base/page-heading';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForgottenPasswordMutation } from '@graphql/index';
import { Transition } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link, useNavigate } from '@tanstack/react-router';
import { useSnackbar } from 'notistack';
import { useState, type FormEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { FormLayout } from '../auth-form-layout';

const userSchema = z.object({
  email: z.string().min(1, { message: 'Email is required' }).email(),
});

type User = z.infer<typeof userSchema>;

export function ForgottenPassword(): JSX.Element {
  const navigate = useNavigate();
  const [forgottenPassword, loading] = useForgottenPasswordMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [emailWasSent, setEmailWasSent] = useState(false);
  const { t } = useTranslation();

  const methods = useForm<User>({
    resolver: zodResolver(userSchema),
    mode: 'onChange',
  });
  const { handleSubmit } = methods;

  async function submit(email: string): Promise<void> {
    try {
      await forgottenPassword({ variables: { email } });
      setEmailWasSent(true);
    } catch {
      enqueueSnackbar({
        message: t('forgottenPassword.errorMessage'),
        variant: 'error',
      });
    }
  }

  async function handleClickSubmit(
    e: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    e.preventDefault();
    await handleSubmit((data) => submit(data.email))();
  }

  return (
    <div className="flex h-full w-full justify-center overflow-y-scroll rounded-none bg-default-gray-950 p-5 text-white shadow-xl sm:p-10 lg:rounded-tl-[20px]">
      <div className="relative mx-auto h-full w-full">
        <FormLayout
          link={
            emailWasSent ? null : (
              <>
                <span className="text-base">
                  {t('forgottenPassword.noAccountText')}{' '}
                </span>
                <NavLink asChild>
                  <Link
                    to="/auth/registration"
                    className="text-base font-bold text-white underline underline-offset-4"
                  >
                    {t('forgottenPassword.signUp')}
                  </Link>
                </NavLink>
              </>
            )
          }
          arrowBackText={t('forgottenPassword.signIn')}
          showGoBackOption
          onClickBack={() => {
            navigate({ to: '/auth/login' });
          }}
        >
          {emailWasSent ? (
            <Transition
              enter="transition-opacity duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={emailWasSent}
            >
              <div className="bg-primary-positive flex flex-col items-center justify-center gap-8 self-center rounded-md px-3 py-1 text-center opacity-100 transition-opacity duration-200">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="h-20 text-default-green-500"
                />
                <PageHeading
                  size="xxlarge"
                  subheading={t('forgottenPassword.successSubheading')}
                >
                  {t('forgottenPassword.checkMailbox')}
                </PageHeading>
              </div>
            </Transition>
          ) : (
            <FormProvider {...methods}>
              <form
                onSubmit={(e) => {
                  handleClickSubmit(e);
                }}
              >
                <div className="mx-auto flex h-full max-w-[528px] flex-col items-center justify-center gap-5 text-center">
                  <PageHeading
                    size="xxlarge"
                    subheading={t('forgottenPassword.formSubheading')}
                  >
                    {t('forgottenPassword.forgotPassword')}
                  </PageHeading>

                  <FormField
                    name="email"
                    className="w-full"
                    label={t('forgottenPassword.emailLabel')}
                  >
                    <Input />
                  </FormField>
                  <div className="flex w-full items-center justify-end">
                    <Button
                      className="w-full"
                      type="submit"
                      loading={loading.loading}
                    >
                      {t('forgottenPassword.submitButton')}
                    </Button>
                  </div>
                </div>
              </form>
            </FormProvider>
          )}
        </FormLayout>
      </div>
    </div>
  );
}
