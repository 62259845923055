import { Button } from '@/components/base/button';
import { FormField } from '@/components/base/form/form-field';
import { FormPhoneNumberInput } from '@/components/base/form/form-phone-input';
import { Input } from '@/components/base/form/input';
import { CredentialsContext } from '@/context/credentials-context';
import { useCurrentUser } from '@/context/current-user-context';
import { faFloppyDisk } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUpdateUserMutation } from '@graphql/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'notistack';
import { useContext, useState, type FormEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { z } from 'zod';

export function EditProfile({
  onSubmit,
  loading,
}: {
  loading: boolean;
  onSubmit: (values: {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
  }) => Promise<void>;
}): JSX.Element {
  const data = useCurrentUser();
  const [phoneError, setPhoneError] = useState<string | null>();
  const { t } = useTranslation();

  const userSchema = z.object({
    email: z.string().min(1, { message: 'Email is required' }).email(),
    phone: z.string().refine(() => !phoneError, {
      message: phoneError ?? '',
    }),
    firstName: z.string(),
    lastName: z.string(),
  });

  type User = z.infer<typeof userSchema>;

  const methods = useForm<User>({
    resolver: zodResolver(userSchema),
    mode: 'onChange',
    values: {
      email: data.me.email,
      phone: data.me.phone ?? '',
      firstName: data.me.firstName,
      lastName: data.me.lastName,
    },
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  invariant(data?.me, 'User data is required');
  const user = data.me;

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  function handleClickSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    return void (async () => {
      handleSubmit((values) => onSubmit(values))();
    })();
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleClickSubmit}>
        <div className="flex flex-col gap-6">
          <fieldset>
            <FormField
              labelClassName="dark:text-white text-default-gray-950"
              label={t('settings.firstNameLabel')}
              name="firstName"
            >
              <Input
                data-testid="firstName"
                defaultValue={user.firstName}
                disabled
                variant="secondary"
                placeholder={t('settings.firstNamePlaceholder')}
              />
            </FormField>
          </fieldset>

          <fieldset>
            <FormField
              labelClassName="dark:text-white text-default-gray-950"
              label={t('settings.lastNameLabel')}
              name="lastName"
            >
              <Input
                data-testid="lastName"
                defaultValue={user.lastName}
                disabled
                variant="secondary"
                placeholder={t('settings.lastNamePlaceholder')}
              />
            </FormField>
          </fieldset>

          <fieldset>
            <FormField
              labelClassName="dark:text-white text-default-gray-950"
              label={t('settings.emailLabel')}
              name="email"
              defaultValue={user.email}
            >
              <Input
                data-testid="email"
                variant="secondary"
                placeholder={t('settings.emailPlaceholder')}
              />
            </FormField>
          </fieldset>
          <fieldset>
            <FormPhoneNumberInput
              data-testid="phone"
              onError={setPhoneError}
              labelClassName="dark:text-white text-default-gray-950"
              label={t('settings.phoneLabel')}
              name="phone"
              defaultValue={user.phone ?? ''}
            />
          </fieldset>

          <div className="flex w-full justify-end">
            <Button
              as="button"
              disabled={!isDirty}
              className="w-full md:w-fit"
              loading={loading}
              type="submit"
              icon={<FontAwesomeIcon icon={faFloppyDisk} />}
              data-testid="submitButton"
            >
              {t('settings.saveChangesButton')}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export function EditPasswordWithMuation(): JSX.Element {
  const [updateUser, { loading }] = useUpdateUserMutation();
  const { clearToken } = useContext(CredentialsContext);
  const { enqueueSnackbar } = useSnackbar();

  async function submit(values: {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
  }): Promise<void> {
    try {
      await updateUser({
        variables: {
          user: {
            email: values.email,
            phone: values.phone,
          },
        },
      });
      enqueueSnackbar({
        message: 'Data has been updated, please sign in with your new address.',
        variant: 'success',
      });
      clearToken();
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar({
          message: e.message,
          variant: 'error',
        });
      }
    }
  }
  return <EditProfile loading={loading} onSubmit={submit} />;
}
