import * as Sentry from '@sentry/react';
import { Component, type ErrorInfo, type ReactNode } from 'react';
import { ErrorPage } from './error-page';

Sentry.init({
  dsn: 'https://510877702eb93f982d589a16caf76779@o4506506499325952.ingest.us.sentry.io/4506972464414720',
  enabled: process.env.NODE_ENV === 'production',
});

interface PageErrorBoundaryProps {
  errorMessage?: string;
  children: ReactNode;
}

interface PageErrorBoundaryState {
  hasError: boolean;
  error?: Error | null;
}

class PageErrorBoundary extends Component<
  PageErrorBoundaryProps,
  PageErrorBoundaryState
> {
  constructor(props: PageErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error });

    Sentry.captureException(error, {
      extra: { errorInfo },
    });
  }

  render(): ReactNode {
    if (this.state.error) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default PageErrorBoundary;
