import { cn } from '@/utils';

export function StepperProgressBar({
  currentValue,
  targetValue,
  className,
  'data-testid': dataTestId = 'stepper-progress-bar',
}: {
  currentValue: number;
  targetValue: number;
  className?: string;
  'data-testid'?: string;
}): JSX.Element {
  const bars = Array(targetValue).fill(0);

  return (
    <div className="flex w-full gap-2" data-testid={dataTestId}>
      {bars.map((_, index) => (
        <div
          key={index}
          className={cn(
            'h-2 w-full rounded-full bg-slate-950 opacity-25',
            { 'opacity-100': index < currentValue },
            className,
          )}
        />
      ))}
    </div>
  );
}
