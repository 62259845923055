import { PageWrapper } from '@/components/base/page-wrapper';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/(authenticated)/order-status/')({
  component: () => (
    <PageWrapper title="Order Status">
      <OrderRedirecter />
    </PageWrapper>
  ),
  validateSearch: (
    search: Record<string, unknown>,
  ): { orderId: string; status: string } => {
    return {
      orderId: String(search.orderId),
      status: String(search.status),
    };
  },
});

function OrderRedirecter(): JSX.Element {
  const { orderId, status } = Route.useSearch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    navigate({
      to: '/order/pay/$order-id',
      params: { 'order-id': orderId },
      search: { status },
    });
  }, [navigate, orderId, status]);
  return <div>{t('orderStatusPage.redirectMessage')}</div>;
}
