import ConfettiExplosion from 'react-confetti-explosion';

export function Confetti(): JSX.Element {
  return (
    <div className="fixed left-1/2 top-1/2 w-full justify-center">
      <ConfettiExplosion
        force={1}
        duration={5000}
        particleCount={250}
        width={2400}
      />
    </div>
  );
}
