import { Czechia, Slovakia, UnitedKingdom } from '@/components/icons/index';
import { EventName, useAnalytics } from '@/context/analytics-context';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher(): JSX.Element {
  const { i18n } = useTranslation();
  const { track } = useAnalytics();

  const handleLanguageChange = (lng: string): void => {
    i18n.changeLanguage(lng);
    track(EventName.ChangeLocale, {
      locale: lng,
    });
  };

  return (
    <div className="flex items-center space-x-4">
      <button
        type="button"
        className="rounded-md focus:outline"
        onClick={() => {
          handleLanguageChange('en');
        }}
        tabIndex={0}
        aria-label="English"
      >
        <UnitedKingdom className="h-7 w-7 object-cover" aria-hidden="true" />
      </button>
      <button
        type="button"
        className="rounded-md focus:outline"
        onClick={() => {
          handleLanguageChange('cs');
        }}
        tabIndex={0}
        aria-label="Čeština"
      >
        <Czechia className="h-8 w-7 object-cover" aria-hidden="true" />
      </button>
      <button
        type="button"
        className="rounded-md focus:outline"
        onClick={() => {
          handleLanguageChange('sk');
        }}
        tabIndex={0}
        aria-label="Slovenčina"
      >
        <Slovakia className="h-8 w-7 object-cover" aria-hidden="true" />
      </button>
    </div>
  );
}

export default LanguageSwitcher;
