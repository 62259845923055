import { FormattedDate } from '@/components/base/formatted-date';
import { faChartSimple } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type CalendarRecordFragment } from '@graphql/index';
import { createColumnHelper } from '@tanstack/react-table';
import { Impact } from './impact-filter';
import { TableBadge } from './table-badge';

const tableBadgeVarianttMap: Record<
  Impact,
  'red' | 'orange' | 'yellow' | 'default'
> = {
  [Impact.HIGH]: 'red',
  [Impact.MID]: 'orange',
  [Impact.LOW]: 'yellow',
  [Impact.HL]: 'default',
};

const columnHelper = createColumnHelper<CalendarRecordFragment>();
export const defaultColumns = ({ t }: { t: (key: string) => string }) => [
  columnHelper.accessor('date', {
    id: 'date',
    header: () => t('table.Time'),
    cell: (info) => (
      <FormattedDate value={info.getValue() ?? ''} dateFormat="h:mm a" />
    ),
    enableGlobalFilter: false,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('country', {
    id: 'country',
    header: () => t('table.Currency'),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('impact', {
    id: 'impact',
    header: () => t('table.Impact'),
    cell: (info) => (
      <TableBadge variant={tableBadgeVarianttMap[info.getValue() as Impact]}>
        {info.getValue()}
      </TableBadge>
    ),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),

  columnHelper.accessor('title', {
    id: 'title',
    header: () => t('table.Name'),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('forecast', {
    id: 'forecast',
    header: () => t('table.Forecast'),
    cell: (info) => info.getValue(),
    enableGlobalFilter: false,
    enableColumnFilter: false,
  }),

  columnHelper.accessor('previous', {
    id: 'previous',
    header: () => t('table.Previous'),
    cell: (info) => <TableBadge>{info.getValue()}</TableBadge>,
    enableGlobalFilter: false,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('url', {
    id: 'url',
    header: () => t('table.Graph'),
    cell: (info) =>
      info.getValue() ? (
        <a
          className="p-2"
          rel="noopener"
          target="_blank"
          href={info.getValue() ?? ''}
        >
          <FontAwesomeIcon icon={faChartSimple} />
        </a>
      ) : null,
    enableGlobalFilter: false,
    enableColumnFilter: false,
  }),
];
