import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { NotFoundPage } from '@/components/not-found-page';
import { usePageLayout } from '@/context/layout-context';

import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/(unathenticated)/auth/$/')({
  component: () => (
    <PageWrapper title="Error - Monevis">
      <DashboardPage />
    </PageWrapper>
  ),
});

function DashboardPage(): JSX.Element {
  usePageLayout({ title: 'Error' });

  return (
    <ScreenContainer className="justify-between">
      <NotFoundPage className="px-4 py-8 sm:px-10" />
    </ScreenContainer>
  );
}

export default DashboardPage;
