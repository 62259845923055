import { Button } from '@/components/base/button';
import { Input } from '@/components/base/form/input';
import { CredentialsContext } from '@/context/credentials-context';
import { useImpersonateUserMutation } from '@graphql/index';
import { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

export function ImpersonationForm(): JSX.Element {
  const [username, setUsername] = useState('');
  const { setToken, token } = useContext(CredentialsContext);
  const [impersonateMutation, { loading }] = useImpersonateUserMutation();
  const [cookie, setCookie] = useCookies(['ADMIN_TOKEN']);
  const { t } = useTranslation();

  async function impersonate(login: string) {
    setCookie('ADMIN_TOKEN', token, { path: '/' });
    const { data } = await impersonateMutation({
      variables: {
        username: login,
      },
    });

    if (data?.impersonate?.token) {
      setToken(data?.impersonate.token);
      location.reload();
    }
  }

  function logout() {
    setToken(String(cookie.ADMIN_TOKEN));
    location.reload();
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
        <Input
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <Button loading={loading} onClick={() => impersonate(username)}>
          {t('impersonationForm.impersonateButton')}
        </Button>
      </div>
      <div>
        <Button
          loading={loading}
          onClick={() => {
            logout();
          }}
        >
          {t('impersonationForm.logoutButton')}
        </Button>
      </div>
    </div>
  );
}
