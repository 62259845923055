import { cn } from '@/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import { type ReactNode } from 'react';

export const SectionVariants = cva(
  'relative flex w-full flex-col gap-2 rounded-2xl p-4 sm:gap-4 sm:p-6 lg:flex-row',
  {
    variants: {
      variant: {
        secondary: 'bg-white dark:bg-default-gray-950 dark:text-white',
        primary: 'bg-primary text-white',
        outline:
          'bg-transparent border dark:border-default-gray-600 border-default-gray-400',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
);

interface SectionProps extends VariantProps<typeof SectionVariants> {
  children: ReactNode;
  className?: string;
}

export default function Section({
  children,
  variant,
  className,
}: SectionProps): JSX.Element {
  return (
    <div className={cn(SectionVariants({ variant, className }))}>
      {children}
    </div>
  );
}
