import { PageHeading } from '@/components/base/page-heading';
import { type AddonFragment, type AddonType } from '@graphql/index';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { CustomCheckbox } from '../components/custom-checkbox';
import { Addons } from '../configuration';

interface SelectAddonsProps {
  currentAddons: AddonFragment[];
  onSelect: (key: AddonType) => void;
}

export function SelectAddons({
  currentAddons = [],
  onSelect = () => false,
}: SelectAddonsProps): JSX.Element {
  const intl = useIntl();
  const { t } = useTranslation();

  return (
    <div>
      <PageHeading subheading={t('selectAddons.subheading')}>
        {t('selectAddons.heading')}
      </PageHeading>
      <div className="mb-8 mt-4 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {Addons.map((item) => {
          return (
            <div key={item.type} className="flex items-center space-x-2">
              <CustomCheckbox
                type="button"
                onClick={() => {
                  onSelect(item.type);
                }}
                active={Boolean(
                  currentAddons.find((a) => a.type === item.type),
                )}
              >
                <div className="flex w-full items-center justify-between gap-5">
                  <div className="flex w-fit flex-col items-start text-start">
                    <span className="text-lg font-bold">{item.name}</span>
                    <span className="text-sm">
                      {item.from} {'->'} {item.to}
                    </span>
                  </div>
                  <span className="text-xl font-bold text-default-gray-950 dark:text-white">
                    +
                    {intl.formatNumber(item.percentage, {
                      style: 'percent',
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </div>
              </CustomCheckbox>
            </div>
          );
        })}
      </div>
    </div>
  );
}
