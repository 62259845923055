import { AnalysisDetailsWithData } from './components/analysis-details';
import { BrokerAccountDetailsWithData } from './components/broker-account-details';
import { BrokerAccountRestartPanel } from './components/broker-account-restart-panel';

export function DetailHead({ accountId }: { accountId: string }): JSX.Element {
  return (
    <div className="flex flex-col gap-4">
      <BrokerAccountDetailsWithData accountId={accountId} />
      <BrokerAccountRestartPanel accountId={accountId} />
      <AnalysisDetailsWithData accountId={accountId} />
    </div>
  );
}
