import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/')({
  beforeLoad: async ({ context }) => {
    if (context.auth.isAuthenticated && !context.auth.loading) {
      throw redirect({
        to: '/dashboard/home',
      });
    } else if (!context.auth.isAuthenticated && !context.auth.loading) {
      throw redirect({
        to: '/auth/login',
      });
    }
  },
});
