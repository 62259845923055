import { cn } from '@/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef, type ReactNode } from 'react';

export const InputVariants = cva(
  'w-full file:border-0 text-sm file:bg-transparent ring-offset-background file:text-sm rounded-md file:font-medium focus-visible:outline-0 ring-default-gray-500 focus-visible:ring-1 disabled:cursor-not-allowed disabled:bg-disabled disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'text-black',
        secondary:
          'dark:bg-default-gray-950 dark:text-white text-default-gray-950 bg-default-gray-100 border border-default-gray-200 dark:border-default-gray-800',
      },
      inputSize: {
        sm: 'px-3 py-[10px]',
        default: 'px-4 py-[14px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      inputSize: 'default',
    },
  },
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof InputVariants> {
  icon?: ReactNode;
  'data-testid'?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      inputSize,
      variant,
      icon,
      type,
      'data-testid': dataTestId = 'input',
      ...props
    },
    ref,
  ) => {
    return (
      <div className="placeholder:text-muted-foreground flex w-full items-center text-sm">
        <div className="relative w-full">
          <input
            data-testid={dataTestId}
            type={type}
            className={cn(InputVariants({ variant, inputSize }), className, {
              'pr-10': icon,
            })}
            ref={ref}
            {...props}
          />
          <div className="absolute right-0 top-1/2 flex h-full -translate-y-1/2 justify-center p-1">
            {icon && icon}
          </div>
        </div>
      </div>
    );
  },
);
Input.displayName = 'Input';
