import { cn } from '@/utils';
import { cva, type VariantProps } from 'class-variance-authority';

const NotificationBadgeVariants = cva(
  'truncate rounded-full flex justify-center items-center bg-danger text-white',
  {
    variants: {
      size: {
        default: 'w-[23px] h-[23px] text-xs',
        sm: 'h-5 w-5 text-[10px]',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
);

export interface NotificationBadgeProps
  extends VariantProps<typeof NotificationBadgeVariants> {
  notificationsCount: number;
  className?: string;
}

export function NotificationBadge({
  notificationsCount,
  className,
  size,
}: NotificationBadgeProps): JSX.Element {
  const count = notificationsCount > 99 ? '99+' : notificationsCount;

  return (
    <div
      data-testid="menu-item-notification"
      className={cn(NotificationBadgeVariants({ size, className }))}
    >
      <span className="leading-tight">{count}</span>
    </div>
  );
}
