import { AddonType } from '@graphql/index';

export const PriceTiers = [
  {
    balance: 5000,
    price: 49,
  },
  {
    balance: 10000,
    price: 80,
  },
  {
    balance: 25000,
    price: 180,
  },
  {
    balance: 50000,
    price: 297,
  },
  {
    balance: 100000,
    price: 507,
  },
  {
    balance: 200000,
    price: 987,
  },
];

export const BalanceTiers = [5000, 25000, 10000, 50000, 100000, 200000];

export const Addons = [
  {
    name: 'Increased Leverage',
    type: AddonType.IncreasedLeverage,
    percentage: 0.25,
    from: '1:20',
    to: '1:50 (FX), 1:20 Gold',
  },
  {
    name: 'Increased profit split',
    type: AddonType.IncreasedProfitSplit,
    percentage: 0.2,
    from: '75%',
    to: '85% Profit Split',
  },
  {
    name: 'Weekly Payouts',
    type: AddonType.WeeklyPayouts,
    percentage: 0.1,
    from: '14 days',
    to: 'Payouts every 7 days',
  },
];
const poorCountries = ['PK', 'ZA', 'IN', 'BD', 'NG', 'TZ'];

export function getTiers(country?: string, hardBalance?: number | string) {
  return BalanceTiers.filter((tier) => {
    return (
      ![5000].includes(tier) ||
      poorCountries.includes(country ?? '') ||
      tier === Number(hardBalance)
    );
  }).sort((a, b) => a - b);
}
