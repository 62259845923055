import Section from '@/components/base/section';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/base/tooltip';
import i18n from '@/utils/i18n';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDuration } from 'date-fns';
import * as locales from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { StatisticsListItem } from './statistics-list-item';

export function StatisticsList({
  balance,
  equity,
  profit,
  gain,
  payoutPeriod,
  profitSplit,
  leverage,
  isTrial,
}: {
  balance: number;
  equity: number;
  profit?: number;
  gain?: number;
  payoutPeriod: number;
  profitSplit: number;
  leverage: number;
  isTrial: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const intl = useIntl();
  const currency = 'USD';

  return (
    <Section variant="secondary" className="h-full">
      <div className="flex w-full flex-col gap-3 text-sm text-black dark:text-white">
        <div className="flex items-center gap-2 border-b border-default-gray-900 py-2 font-medium">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <span>{t('statistics.title')}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>{t('statistics.tooltip')}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="flex flex-col p-4 [&>*:nth-child(odd)]:bg-default-gray-100 [&>*:nth-child(odd)]:dark:bg-default-gray-900">
          <StatisticsListItem
            label={t('statistics.balance')}
            value={intl.formatNumber(balance, {
              style: 'currency',
              currency,
              maximumFractionDigits: 2,
            })}
          />
          <StatisticsListItem
            label={t('statistics.equity')}
            value={intl.formatNumber(equity, {
              style: 'currency',
              currency,
              maximumFractionDigits: 2,
            })}
          />
          {profit !== undefined && (
            <StatisticsListItem
              label={t('statistics.profit')}
              value={intl.formatNumber(profit, {
                style: 'currency',
                currency,
                maximumFractionDigits: 2,
              })}
            />
          )}
          {gain !== undefined && (
            <StatisticsListItem
              label={t('statistics.gain')}
              value={intl.formatNumber(gain / 100, {
                style: 'percent',
                currency,
                maximumFractionDigits: 2,
              })}
            />
          )}
          {!isTrial && (
            <StatisticsListItem
              label={t('statistics.payoutPeriod')}
              value={formatDuration(
                { days: payoutPeriod },
                {
                  locale: (
                    locales as Record<string, locales.Locale | undefined>
                  )[i18n.language],
                },
              )}
            />
          )}

          {!isTrial && (
            <StatisticsListItem
              label={t('statistics.profitSplit')}
              value={intl.formatNumber(profitSplit, {
                style: 'percent',
                currency,
                maximumFractionDigits: 2,
              })}
            />
          )}
          <StatisticsListItem label="Leverage" value={`1:${leverage}`} />
        </div>
      </div>
    </Section>
  );
}
