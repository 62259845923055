import { PageHeading } from '@/components/base/page-heading';
import { cn, getOS } from '@/utils';
import { type VariantProps } from 'class-variance-authority';
import { useTranslation } from 'react-i18next';
import Section, { type SectionVariants } from './base/section';
import { AppStore, GooglePlay, MacOs, Windows } from './icons';

interface MetatraderDownloadProps extends VariantProps<typeof SectionVariants> {
  className?: string;
}

export default function MetatraderDownload({
  variant = 'primary',
  className,
}: MetatraderDownloadProps): JSX.Element {
  const { t } = useTranslation();
  const os = getOS();
  return (
    <Section className={cn('flex', className)} variant={variant}>
      <div className="flex flex-1 flex-row items-end justify-start md:hidden">
        <img
          src="/images/man.png"
          className="h-44 -scale-x-100 transform"
          alt="man"
        />
        <img
          src="/images/meta-trader.png"
          className="h-16 w-16"
          alt="metatrader"
        />
      </div>
      <div className="lg:flex-2 z-20 flex flex-col gap-5">
        <div className="flex flex-col gap-3">
          <PageHeading
            className="text-white"
            size="xxlarge"
            subheadingClassName="max-w-md text-white"
            subheading={t('metatraderDownload.subheading')}
          >
            {t('metatraderDownload.title')}
          </PageHeading>
        </div>
        <div className="flex items-end justify-start">
          <div className="flex items-end gap-24">
            <div className="flex gap-5">
              {os === 'iOS' && (
                <a
                  href="https://apps.apple.com/us/app/metatrader-5/id413251709"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="max-w-[137px]">
                    <AppStore />
                  </div>
                </a>
              )}
              {os === 'macOS' && (
                <a
                  href="https://apps.apple.com/us/app/metatrader-5/id413251709"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MacOs />
                </a>
              )}
              {os === 'unknown' && (
                <a
                  href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&referrer=ref_id%3D5189196426676839094%26server%3DMonevisBrokers-Live&pli=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="max-w-[137px]">
                    <GooglePlay />
                  </div>
                </a>
              )}
              {os === 'unknown' && (
                <a
                  href="https://apps.apple.com/us/app/metatrader-5/id413251709"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="max-w-[137px]">
                    <AppStore />
                  </div>
                </a>
              )}
              {os === 'Android' && (
                <a
                  href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&referrer=ref_id%3D5189196426676839094%26server%3DMonevisBrokers-Live&pli=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="max-w-[137px]">
                    <GooglePlay />
                  </div>
                </a>
              )}
              {os === 'Windows' && (
                <a
                  href="https://download.metatrader.com/cdn/web/monevis.brokers.ltd/mt5/monevisbrokers5setup.exe"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="max-w-[137px]">
                    <Windows />
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden flex-1 md:block">
        <img
          src="/images/mascot.png"
          className="absolute bottom-0 right-0 z-40 h-52 xl:h-60"
          alt="man"
        />
      </div>
    </Section>
  );
}
