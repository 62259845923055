import { Loading } from './base/loading';

export function LoadingScreen(): JSX.Element {
  return (
    <div className="flex min-h-screen w-full items-center justify-center bg-white dark:bg-default-gray-950">
      <Loading
        size="big"
        className="text-7xl text-default-gray-950 dark:text-white"
      />
    </div>
  );
}
