import { formatInTimeZone } from 'date-fns-tz';

export function FormattedDate({
  value,
  dateFormat = 'MMM d - yyyy',
}: {
  value: string | Date;
  dateFormat?: string;
}): JSX.Element {
  const date = new Date(value);

  if (isNaN(date.getTime())) {
    return <div />;
  }

  return <span>{formatInTimeZone(date, 'UTC', dateFormat)}</span>;
}
