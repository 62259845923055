import { PageHeading } from '@/components/base/page-heading';
import { useTranslation } from 'react-i18next';
import { Badge } from './badge';

export function TrialInfo(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col justify-between gap-5 md:flex-row md:items-center">
      <div className="flex flex-col">
        <PageHeading
          className="mb-3"
          size="xxlarge"
          subheading={t('trialInfo.subheading')}
        >
          {t('trialInfo.heading')}
        </PageHeading>
        <div className="mt-3 flex flex-wrap gap-2">
          <Badge>{t('trialInfo.badge1')}</Badge>
          <Badge>{t('trialInfo.badge2')}</Badge>
          <Badge>{t('trialInfo.badge3')}</Badge>
          <Badge>{t('trialInfo.badge4')}</Badge>
          <Badge>{t('trialInfo.badge5')}</Badge>
          <Badge>{t('trialInfo.badge6')}</Badge>
        </div>
      </div>
      <div className="flex flex-1 flex-row items-center space-x-2 md:justify-end">
        <div>
          <img
            src="/images/mt5-logo.png"
            className="h-12 w-auto"
            alt="metatrader logo"
          />
        </div>
        <div className="font-bold">{t('MonevisBrokers-Live')}</div>
      </div>
    </div>
  );
}
