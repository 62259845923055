import { Badge } from '@/components/base/badge';
import { PageHeading } from '@/components/base/page-heading';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/base/select';
import {
  faHourglassStart,
  faMoneySimpleFromBracket,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type FundedAccountFragment } from '@graphql/index';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { RestartCountdown } from '../account-detail/head/components/restart-countdown';

export function SelectAccount({
  data,
  selectedAccount,
  onChange,
  eligibleForWithdrawal,
  nextEligiblePayout,
}: {
  data: FundedAccountFragment[];
  selectedAccount: string;
  eligibleForWithdrawal: boolean;
  nextEligiblePayout: string;
  onChange: (value: string) => void;
}): JSX.Element {
  const { t } = useTranslation();
  const openTrades = useMemo(
    () =>
      data.find((account) => account.login === selectedAccount)?.openedTrades ??
      0,
    [selectedAccount],
  );
  const intl = useIntl();

  function isCurrentOrPastDate(dateTimeString: string): boolean {
    const backendDate = new Date(dateTimeString);
    const currentDate = new Date();
    return backendDate <= currentDate;
  }

  const notEligible = selectedAccount && !eligibleForWithdrawal;
  const showCountDown = !isCurrentOrPastDate(nextEligiblePayout);
  const showOpenTradesNotEligible =
    isCurrentOrPastDate(nextEligiblePayout) && openTrades > 0;

  return (
    <div>
      <PageHeading subheading={t('payout.selectSubheading')} />
      <Select
        value={selectedAccount}
        onValueChange={(currentValue) => {
          onChange(currentValue);
        }}
      >
        <SelectTrigger
          data-testid="select-account-trigger"
          size="sm"
          variant="secondary"
        >
          <SelectValue placeholder={t('payout.placeholder')} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup data-testid="select-account-content">
            {data.length > 0 ? (
              data.map((account) => {
                return (
                  <SelectItem
                    data-testid={account.login}
                    key={account.login}
                    value={account.login}
                  >
                    <span className="flex min-w-10 gap-5">
                      {account.login} -{' '}
                      {intl.formatNumber(account.initialBalance, {
                        style: 'currency',
                        currency: 'USD',
                      })}
                      {account.openedTrades}
                      <Badge
                        variant={
                          account.eligibleForWithdrawal ? 'green' : 'yellow'
                        }
                        className="flex gap-2 px-1"
                      >
                        <FontAwesomeIcon
                          icon={
                            account.eligibleForWithdrawal
                              ? faMoneySimpleFromBracket
                              : faHourglassStart
                          }
                        />
                      </Badge>
                    </span>
                  </SelectItem>
                );
              })
            ) : (
              <SelectLabel>{t('payout.noAccounts')}</SelectLabel>
            )}
          </SelectGroup>
        </SelectContent>
      </Select>
      {notEligible ? (
        <span
          data-testid="eligibilityCountdown"
          className="text-sm text-yellow-300"
        >
          {showCountDown && (
            <>
              {t('payout.notEligibleYet')}
              <RestartCountdown time={nextEligiblePayout} />
            </>
          )}
          {showOpenTradesNotEligible && t('payout.notEligibleOpenTrades')}
        </span>
      ) : null}
    </div>
  );
}
