import { Button } from '@/components/base/button';
import { Dialog, DialogContent } from '@/components/base/dialog';
import { Skeleton } from '@/components/base/skeleton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function CertificateDialog({
  id,
  open,
  onClose,
}: {
  id: string | null;
  open: boolean;
  onClose: () => void;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [imageError, setImageError] = useState(false);

  function handleImageLoaded(): void {
    setIsLoading(false);
  }
  function handleImageError(): void {
    setImageError(true);
  }
  const { t } = useTranslation();
  return (
    <Dialog
      onOpenChange={() => {
        onClose();
      }}
      open={open}
    >
      <DialogContent className="flex w-full max-w-80 flex-col items-center justify-center gap-2">
        {isLoading && (
          <Skeleton className="m-2 h-full min-h-[270px] w-full min-w-[270px] rounded-xl" />
        )}
        {imageError && (
          <div className="text-red-500">
            {t('certificate.errorLoadingCertificate')}
          </div>
        )}
        <img
          className="m-2"
          src={`https://certificates.monevis.com/api/certificate?id=${id}&output=image&size=800`}
          alt={t('certificate.certificateAltText')}
          onLoad={handleImageLoaded}
          onError={handleImageError}
        />
        <Button
          target="_blank"
          rel="noreferrer"
          as="link"
          href={`https://certificates.monevis.com/certificate/${id}`}
          variant="link"
        >
          {t('certificate.viewCertificate')}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
