import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/base/alert-dialog';
import { Button } from '@/components/base/button';
import { Trans, useTranslation } from 'react-i18next';

export function CompleteRequest({
  amount,
  accountName,
  disabled,
  onSubmit,
}: {
  amount: number;
  accountName?: string;
  disabled: boolean;
  id?: string;
  onSubmit: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button data-testid="submit-button" disabled={disabled}>
          {t('payout.requestButton')}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent data-testid="complete-request-alert">
        <AlertDialogHeader>
          <AlertDialogTitle>{t('payout.confirmTitle')}</AlertDialogTitle>
          <AlertDialogDescription>
            <Trans
              i18nKey="payout.confirmDescription"
              values={{ amount, accountName }}
            />
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel data-testid="cancel-alert">
            {t('payout.cancelButton')}
          </AlertDialogCancel>
          <AlertDialogAction data-testid="submit-alert" onClick={onSubmit}>
            {t('payout.continueButton')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
