import color from 'color';
import deepmerge from 'deepmerge';
import type { ReactNode } from 'react';

export interface Theme {
  colors?: {
    danger?: string;
    dark?: string;
    primary?: string;
    divider?: string;
    blueOutline?: string;
    disabled?: string;
    warning?: string;
  };
  radius?: {
    default: string;
    large: string;
  };
  font?: {
    sans: string;
  };
}

const defaultTheme: Theme = {
  colors: {
    primary: '#CB002D',
    danger: '#CB002D',
    disabled: '#ededed',
    blueOutline: '#2563EB',
    dark: '#141518',
    divider: '#DCE3E3',
    warning: '#E23801',
  },
  radius: {
    default: '10px',
    large: '20px',
  },
  font: {
    sans: '',
  },
};

export interface ThemeProviderProps {
  theme?: Theme;
  children?: ReactNode;
}

export function ThemeProvider(props: ThemeProviderProps): JSX.Element {
  const { children, theme } = props;
  const appliedTheme = deepmerge(defaultTheme, theme ?? {});
  const colors = {
    primary: color(appliedTheme.colors.primary).rgb().array(),
    danger: color(appliedTheme.colors.danger).rgb().array(),
    dark: color(appliedTheme.colors.dark).rgb().array(),
    divider: color(appliedTheme.colors.divider).rgb().array(),
    blueOutline: color(appliedTheme.colors.blueOutline).rgb().array(),
    disabled: color(appliedTheme.colors.disabled).rgb().array(),
    warning: color(appliedTheme.colors.warning).rgb().array(),
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          :root {
            --sg-border-radius-default: ${appliedTheme.radius.default};
            --sg-border-radius-large: ${appliedTheme.radius.large};
            --sg-color-primary: ${colors.primary.join(' ')};
            --sg-color-danger: ${colors.danger.join(' ')};
            --sg-color-dark: ${colors.dark.join(' ')};
            --sg-color-divider: ${colors.divider.join(' ')};
            --sg-color-blue-outline: ${colors.blueOutline.join(' ')};
            --sg-color-disabled: ${colors.disabled.join(' ')};
            --sg-color-warning: ${colors.warning.join(' ')};
          }
      `,
        }}
      />
      {children}
    </>
  );
}
