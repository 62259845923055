import { PageWrapper } from '@/components/base/page-wrapper';
import { usePageLayout } from '@/context/layout-context';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/rankings',
)({
  component: () => (
    <PageWrapper title="Rankings - Monevis">
      <RankingsPage />
    </PageWrapper>
  ),
});

function RankingsPage(): JSX.Element {
  const { t } = useTranslation();
  usePageLayout({ title: t('rankingsPage.title') });

  return (
    <div className="p-2">
      {t('rankingsPage.breadcrumb')}
      <button
        type="button"
        onClick={() => {
          throw new Error('Sentry Test Error');
        }}
      >
        {t('rankingsPage.buttonText')}
      </button>
    </div>
  );
}

export default RankingsPage;
