import type { SVGProps } from 'react';

export default function SvgPdf(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={20}
      fill="none"
      {...props}
    >
      <path
        fill="#FA2256"
        d="M21.192 12.515c-1.582-1.579-5.902-.936-6.936-.819-1.52-1.403-2.555-3.1-2.92-3.684.548-1.579.912-3.158.973-4.853 0-1.462-.608-3.041-2.311-3.041-.609 0-1.157.35-1.46.818-.73 1.228-.427 3.684.73 6.198-.67 1.813-1.278 3.567-2.981 6.666-1.765.702-5.476 2.34-5.78 4.093-.12.527.061 1.053.488 1.462.425.352.972.527 1.52.527 2.25 0 4.44-2.982 5.962-5.496 1.278-.41 3.285-.994 5.293-1.345 2.372 1.988 4.44 2.28 5.536 2.28 1.46 0 2.008-.585 2.19-1.111.304-.584.122-1.227-.304-1.695m-1.521.993c-.06.41-.609.819-1.582.585a8.5 8.5 0 0 1-3.103-1.52c.79-.117 2.556-.292 3.833-.058.487.116.973.408.852.993M9.51 1.463c.121-.176.303-.292.487-.292.547 0 .669.643.669 1.17a12.4 12.4 0 0 1-.73 3.625c-.912-2.34-.73-3.977-.426-4.503m-.122 11.344c.487-.936 1.156-2.573 1.4-3.275.547.877 1.46 1.93 1.946 2.398 0 .058-1.886.41-3.346.877M5.8 15.146c-1.398 2.222-2.858 3.626-3.65 3.626-.12 0-.242-.058-.365-.117q-.273-.175-.182-.526c.182-.82 1.764-1.93 4.198-2.983"
      />
    </svg>
  );
}
