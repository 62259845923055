import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/(unathenticated)/auth/_layout')({
  beforeLoad: async ({ context }) => {
    if (context.auth.isAuthenticated && !context.auth.loading) {
      const searchParams = new URLSearchParams(location.search);
      const redirectPath = searchParams.get('redirect');
      throw redirect({
        to: redirectPath ?? '/dashboard/home',
      });
    }
  },
  component: LayoutComponent,
});

//Auth Layout - generic
function LayoutComponent(): JSX.Element {
  return (
    <div className="dark">
      <Outlet />
    </div>
  );
}
