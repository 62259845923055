import Section from '@/components/base/section';
import { Skeleton } from '@/components/base/skeleton';
import { cn } from '@/utils';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowTrendDown,
  faArrowTrendUp,
  faCalculator,
  faChartLineDown,
  faChartLineUp,
  faCircleMinus,
  faCirclePlus,
  faClock,
  faHashtag,
  faPercent,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AccountTradingPositionAction,
  useGetClosedPositionsQuery,
  type AccountTradingPositionFragment,
} from '@graphql/index';
import { intervalToDuration } from 'date-fns';
import { type ReactNode } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

class Analysis {
  private trades: AccountTradingPositionFragment[] = [];
  constructor(trades: AccountTradingPositionFragment[]) {
    this.trades = trades;
  }

  formatDecimals(value: number): number {
    return Number(Number(value).toFixed(2));
  }

  totalTrades(): number {
    return this.trades.length;
  }

  averageWin(): number {
    const profitableTrades = this.trades.filter((t) => Number(t.profit) > 0);

    if (profitableTrades.length === 0) {
      return 0;
    }

    const total = profitableTrades.reduce(
      (acc, t) => acc + Number(t.profit),
      0,
    );

    return this.formatDecimals(total / profitableTrades.length);
  }

  averageLoss(): number {
    const unprofitableTrades = this.trades.filter((t) => Number(t.profit) < 0);

    if (unprofitableTrades.length === 0) {
      return 0;
    }

    const total = unprofitableTrades.reduce(
      (acc, t) => acc + Number(t.profit),
      0,
    );

    return this.formatDecimals(total / unprofitableTrades.length);
  }

  profitability(): number {
    const profitableTrades = this.trades.filter((t) => Number(t.profit) > 0);

    if (profitableTrades.length === 0) {
      return 0;
    }

    return this.formatDecimals(profitableTrades.length / this.trades.length);
  }

  longsWon(): number {
    const longsWon = this.trades
      .filter((t) => t.action === AccountTradingPositionAction.Buy)
      .filter((t) => Number(t.profit) > 0);

    return longsWon.length;
  }

  shortsWon(): number {
    const longsWon = this.trades
      .filter((t) => t.action === AccountTradingPositionAction.Sell)
      .filter((t) => Number(t.profit) > 0);

    return longsWon.length;
  }

  worstTrade(): number {
    const unprofitableTrades = this.trades.filter((t) => Number(t.profit) < 0);

    if (unprofitableTrades.length === 0) {
      return 0;
    }

    return unprofitableTrades.reduce((acc, t) => {
      if (Number(t.profit) < acc) {
        return Number(t.profit);
      }
      return acc;
    }, 0);
  }

  bestTrade(): number {
    const profitableTrades = this.trades.filter((t) => Number(t.profit) > 0);

    if (profitableTrades.length === 0) {
      return 0;
    }

    return profitableTrades.reduce((acc, t) => {
      if (Number(t.profit) > acc) {
        return Number(t.profit);
      }
      return acc;
    }, 0);
  }

  totalLots(): number {
    return this.formatDecimals(
      this.trades.reduce((acc, t) => acc + Number(t.lots), 0),
    );
  }

  averageDuration(): number {
    const totalDuration = this.formatDecimals(
      this.trades.reduce((acc, t) => acc + Number(t.duration), 0),
    );

    return this.formatDecimals(totalDuration / this.trades.length);
  }

  symbolDistribution(): unknown {
    const symbols = {};

    this.trades.forEach((t) => {
      // @ts-expect-error -- ok
      if (!symbols[String(t?.symbol)]) {
        // @ts-expect-error -- ok
        symbols[String(t.symbol)] = 1;
      } else {
        // @ts-expect-error -- ok
        symbols[String(t.symbol)] += 1;
      }
    });

    return symbols;
  }
}

function FormattedMoneyValue({ value }: { value: number }): JSX.Element {
  const intl = useIntl();

  return (
    <span
      className={cn(
        { 'text-green-500': Number(value) > 0 },
        { 'text-red-500': Number(value) < 0 },
      )}
    >
      {intl.formatNumber(value ?? 0, {
        style: 'currency',
        currency: 'USD',
      })}
    </span>
  );
}

function FormattedPercentage({ value }: { value: number }): JSX.Element {
  const intl = useIntl();

  return (
    <span
      className={cn(
        { 'text-green-500': Number(value) > 0 },
        { 'text-red-500': Number(value) < 0 },
      )}
    >
      {intl.formatNumber(value ?? 0, {
        style: 'percent',
      })}
    </span>
  );
}

function FormattedTimeValue({ value }: { value: number }): JSX.Element {
  const helperDate = intervalToDuration({
    start: 0,
    end: Number(value) * 1000,
  });
  const zeroPad = (num: number | undefined): string =>
    num ? String(num).padStart(2, '0') : '00';
  return (
    <span>
      {zeroPad(helperDate.hours)}:{zeroPad(helperDate.minutes)}:
      {zeroPad(helperDate.seconds)}
    </span>
  );
}

function Tile({
  title,
  value,
  icon,
  'data-testid': dataTestId,
}: {
  title: string;
  value: string | ReactNode | number;
  icon?: IconProp;
  'data-testid'?: string;
}): JSX.Element {
  return (
    <Section variant="secondary" className="relative !p-4">
      <div data-testid={dataTestId} className="flex w-full flex-col gap-2">
        {icon && (
          <div className="absolute right-3 top-2">
            <FontAwesomeIcon icon={icon} />
          </div>
        )}
        <h3 className="w-full text-center text-xs uppercase">{title}</h3>
        <span className="text-center font-bold">{value}</span>
      </div>
    </Section>
  );
}

export function SymbolChart({ analysis }: { analysis: Analysis }): JSX.Element {
  const symbols = analysis.symbolDistribution();
  return (
    <Chart
      type="pie"
      width="100%"
      height="100%"
      // @ts-expect-error -- ok
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- ok
      series={Object.keys(symbols).map((key) => symbols[key])}
      options={{
        chart: {
          type: 'pie',
          height: '100%',
          width: '100%',
        },
        // @ts-expect-error -- ok
        labels: Object.keys(symbols),
        stroke: {
          colors: ['#fff'],
        },
        fill: {
          opacity: 0.8,
        },
        // yaxis: {
        //   labels: { show: false },
        // },
      }}
    />
  );
}

export function AdvancedAnalysis({
  accountId,
}: {
  accountId: string;
}): JSX.Element {
  const { t } = useTranslation();
  const { data, loading } = useGetClosedPositionsQuery({
    variables: { login: accountId },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <>
        <div className="grid h-40 w-full grid-cols-2 gap-2 sm:grid-cols-3 lg:grid-cols-5">
          <Skeleton className="h-full max-h-20 w-full rounded-2xl" />
          <Skeleton className="h-full max-h-20 w-full rounded-2xl" />
          <Skeleton className="h-full max-h-20 w-full rounded-2xl" />
          <Skeleton className="h-full max-h-20 w-full rounded-2xl" />
          <Skeleton className="h-full max-h-20 w-full rounded-2xl" />
          <Skeleton className="h-full max-h-20 w-full rounded-2xl" />
          <Skeleton className="h-full max-h-20 w-full rounded-2xl" />
          <Skeleton className="h-full max-h-20 w-full rounded-2xl" />
          <Skeleton className="h-full max-h-20 w-full rounded-2xl" />
          <Skeleton className="h-full max-h-20 w-full rounded-2xl" />
        </div>
        <Skeleton className="mt-8  h-full min-h-[350px] w-1/2 rounded-2xl" />
      </>
    );
  }

  const analysis = new Analysis(
    (data?.closedPositions as AccountTradingPositionFragment[]) ?? [],
  );

  return (
    <>
      <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 lg:grid-cols-5">
        <Tile
          icon={faChartLineUp}
          title={t('advancedAnalysis.averageWin')}
          value={<FormattedMoneyValue value={analysis.averageWin()} />}
          data-testid="average-win"
        />
        <Tile
          icon={faChartLineDown}
          title={t('advancedAnalysis.averageLoss')}
          value={<FormattedMoneyValue value={analysis.averageLoss()} />}
          data-testid="average-loss"
        />
        <Tile
          icon={faHashtag}
          title={t('advancedAnalysis.trades')}
          value={analysis.totalTrades()}
          data-testid="total-trades"
        />
        <Tile
          icon={faPercent}
          title={t('advancedAnalysis.winRate')}
          value={<FormattedPercentage value={analysis.profitability()} />}
          data-testid="win-rate"
        />
        <Tile
          icon={faClock}
          title={t('advancedAnalysis.avgTradeLength')}
          value={<FormattedTimeValue value={analysis.averageDuration()} />}
          data-testid="avg-trade-length"
        />
        <Tile
          icon={faCirclePlus}
          title={t('advancedAnalysis.bestTrade')}
          value={<FormattedMoneyValue value={analysis.bestTrade()} />}
          data-testid="best-trade"
        />
        <Tile
          icon={faCircleMinus}
          title={t('advancedAnalysis.worstTrade')}
          value={<FormattedMoneyValue value={analysis.worstTrade()} />}
          data-testid="worst-trade"
        />
        <Tile
          icon={faArrowTrendUp}
          title={t('advancedAnalysis.longsWon')}
          value={analysis.longsWon()}
          data-testid="longs-won"
        />
        <Tile
          icon={faArrowTrendDown}
          title={t('advancedAnalysis.shortsWon')}
          value={analysis.shortsWon()}
          data-testid="shorts-won"
        />
        <Tile
          icon={faCalculator}
          title={t('advancedAnalysis.totalLots')}
          value={analysis.totalLots()}
          data-testid="total-lots"
        />
      </div>
      <div className="mt-8 w-full">
        <Section variant="secondary" className="w-1/2">
          <div
            data-testid="symbol-chart"
            className="h-full min-h-[350px] w-full"
          >
            <SymbolChart analysis={analysis} />
          </div>
        </Section>
      </div>
    </>
  );
}
