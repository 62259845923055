/* eslint-disable @typescript-eslint/no-unsafe-assignment -- for props*/
/* eslint-disable @typescript-eslint/no-explicit-any -- for ref*/
import { cn } from '@/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { Loading } from './loading';

const buttonVariants = cva(
  'inline-flex items-center justify-center transition-all duration-200 whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
  {
    variants: {
      variant: {
        default: 'bg-primary text-white hover:bg-primary/80',
        secondary: 'bg-white text-black shadow-white hover:shadow-lg',
        label:
          'bg-default-gray-700 dark:bg-default-gray dark:text-default-gray-400 text-white hover:opacity-90',
        link: 'text-default-gray-400 underline-offset-4',
        gray: 'dark:bg-default-gray-950 bg-default-gray-200 dark:text-white text-default-gray-950 rounded-md hover:bg-default-gray-400 dark:hover:bg-default-gray-900',
        outlined:
          'bg-transparent border border-default-gray-200 dark:border-default-gray-800 text-default-gray-950 dark:text-white hover:bg-default-gray-100 hover:dark:bg-default-gray-800',
      },
      size: {
        default: 'p-[14px] text-sm',
        sm: 'py-[12px]',
        lg: 'py-[18px]',
        icon: 'h-10 w-10',
        xsmall: 'rounded-md py-1 px-2',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps extends VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  block?: boolean;
  children?: React.ReactNode;
}

export type ExtendedButtonLinkProps = ButtonProps &
  (
    | (JSX.IntrinsicElements['a'] & { as?: 'link' })
    | (JSX.IntrinsicElements['button'] & { as: 'button' })
  );

const Button = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ExtendedButtonLinkProps
>(
  (
    {
      className,
      variant,
      loading,
      children,
      icon,
      size,
      block,
      as = 'button',
      ...props
    },
    ref,
  ) => {
    const Comp = as === 'link' ? 'a' : 'button';

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), {
          'w-full': block,
        })}
        ref={ref as any}
        {...(props as any)}
      >
        {loading ? (
          <Loading />
        ) : (
          <div className="flex items-center justify-center gap-2">
            {icon && icon}
            {children}
          </div>
        )}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
