import { Button } from '@/components/base/button';
import { FormField } from '@/components/base/form/form-field';
import { Input } from '@/components/base/form/input';
import {
  useGetForgottenPasswordTokenLazyQuery,
  useResetPasswordMutation,
} from '@graphql/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { useSnackbar } from 'notistack';
import { useEffect, type FormEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { Header } from '../auth-form-layout';

const userSchema = z
  .object({
    password: z.string().min(1, {
      message: 'Password is required',
    }),
    confirmPassword: z.string().min(1, {
      message: 'Confirm password is required',
    }),
  })
  .refine((data) => data.confirmPassword === data.password, {
    message: 'Passwords must match',
    path: ['confirmPassword'],
  });

type User = z.infer<typeof userSchema>;

export function ChangePassword(): JSX.Element {
  const navigate = useNavigate();
  const search: { token: string } = useSearch({
    strict: false,
  });

  const { t } = useTranslation();
  const token = search.token;

  const { enqueueSnackbar } = useSnackbar();
  const [getForgottenPasswrodToken] = useGetForgottenPasswordTokenLazyQuery();
  const [resetPasswordMutation, { loading }] = useResetPasswordMutation();

  const methods = useForm<User>({
    resolver: zodResolver(userSchema),
    mode: 'onChange',
  });
  const { handleSubmit } = methods;

  useEffect(() => {
    async function checkToken(): Promise<void> {
      try {
        if (token) {
          const { error } = await getForgottenPasswrodToken({
            variables: { id: token || '' },
          });
          if (error) {
            throw new Error('Invalid token');
          }
        } else {
          throw new Error('Invalid token');
        }
      } catch {
        navigate({ to: '/auth/invalid-link' });
      }
    }
    void (async () => {
      await checkToken();
    })();
  }, [token]);

  async function submit(data: User): Promise<void> {
    try {
      await resetPasswordMutation({
        variables: { id: token || '', password: data.password },
      });
      enqueueSnackbar({
        message: t('changePassword.successMessage'),
        variant: 'success',
      });
      navigate({ to: '/auth/login' });
    } catch (e) {
      enqueueSnackbar({
        message: t('changePassword.errorMessage'),
        variant: 'error',
      });
    }
  }

  async function handleClickSubmit(
    e: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    e.preventDefault();
    await handleSubmit(submit)();
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => {
          handleClickSubmit(e);
        }}
        className="flex h-full w-full justify-center overflow-y-scroll rounded-none bg-default-gray-950 p-5 text-white shadow-xl sm:p-10 lg:rounded-tl-[20px]"
      >
        <div className="relative mx-auto h-full w-full">
          <Header
            onClickBack={() => {
              navigate({ to: '/auth/forgotten-password' });
            }}
            showGoBackOption
            arrowBackText={t('changePassword.arrowBackText')}
          />
          <div className="mx-auto flex h-full max-w-[528px] flex-col items-center justify-center gap-5">
            <span>{t('changePassword.createNewPassword')}</span>

            <FormField
              name="password"
              className="w-full"
              label={t('changePassword.passwordLabel')}
            >
              <Input type="password" />
            </FormField>
            <FormField
              name="confirmPassword"
              className="w-full"
              label={t('changePassword.confirmPasswordLabel')}
            >
              <Input type="password" />
            </FormField>
            <div className="flex w-full items-center justify-end">
              <Button type="submit" loading={loading}>
                {t('changePassword.resetButton')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
