import { Transition } from '@headlessui/react';
import { type ReactNode } from 'react';

interface MenuLabelProps {
  children: ReactNode;
  isOpen?: boolean;
}

export default function MenuLabel({
  children,
  isOpen = true,
}: MenuLabelProps): JSX.Element {
  return (
    <span className="px-6 font-bold text-default-gray">
      <Transition
        as="div"
        show={isOpen}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {children}
      </Transition>
    </span>
  );
}
