import { Button } from '@/components/base/button';
import { PageHeading } from '@/components/base/page-heading';
import WidgetWithImage from '@/components/base/widget-with-image';
import { useTranslation } from 'react-i18next';
import { JoinSocialsSection } from '../calendar/help-section';
import { UserCertificatesWithData } from './certificates-table';

export function UserCertificatesPage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <div className="my-8">
        <WidgetWithImage
          img="/images/payout-certificates.png"
          header={<>{t('certificate.header')}</>}
          subheader={t('certificate.subheader')}
        >
          <Button
            as="link"
            className="dark:text-white"
            href="https://certificates.monevis.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('certificate.showAllButton')}
          </Button>
        </WidgetWithImage>
      </div>

      <UserCertificatesWithData />

      <JoinSocialsSection>
        <PageHeading subheading={t('resource.subheading')}>
          {t('resource.join')}
        </PageHeading>
      </JoinSocialsSection>
    </>
  );
}
