import { Twitter } from '@/components/icons';
import { useAppConfig } from '@/context/app-config-context';
import { Button } from '../button';

export function TwitterButton(): JSX.Element {
  const {
    AppConfig: { TWITTER_AUTH_URL },
  } = useAppConfig();

  return (
    <Button
      as="link"
      className="py-3"
      variant="secondary"
      href={TWITTER_AUTH_URL}
      icon={<Twitter className="h-5 w-5 sm:h-6 sm:w-6" />}
    />
  );
}
