import { FormattedDate } from '@/components/base/formatted-date';
import Section from '@/components/base/section';
import { Skeleton } from '@/components/base/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/base/table';
import { Tabs, TabsList, TabsTrigger } from '@/components/base/tabs';
import { cn } from '@/utils';
import {
  useCurrentWeekCalendarDataQuery,
  type CalendarRecordFragment,
} from '@graphql/index';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format, parseISO } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { defaultColumns } from './columns';
import { Impact, ImpactFilter } from './impact-filter';

export enum Tab {
  ThisWeek = 'This Week',
}

export function CalendarTable({
  calendarData,
}: {
  calendarData: (CalendarRecordFragment | null)[];
}): JSX.Element {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(Tab.ThisWeek);
  const [selectedImpacts, setSelectedImpacts] = useState<Impact[]>([
    Impact.MID,
    Impact.HIGH,
    Impact.LOW,
    Impact.HL,
  ]);

  const columns = useMemo(() => defaultColumns({ t }), [t]);

  const getGroupedData = useCallback(
    (data: (CalendarRecordFragment | null)[]) => {
      const groupedData = data.reduce<Record<string, CalendarRecordFragment[]>>(
        (
          acc: Record<string, CalendarRecordFragment[]>,
          row: CalendarRecordFragment | null,
        ) => {
          if (row === null) {
            return acc;
          }
          const date = format(parseISO(row.date ?? ''), 'yyyy-MM-dd');
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(row);
          return acc;
        },
        {},
      );
      const result = Object.entries(groupedData).map(([date, rows]) => ({
        date,
        rows,
      }));
      return result;
    },
    [],
  );

  const data = useMemo(() => getGroupedData(calendarData), []);
  const rows = useMemo(
    () => getGroupedData(calendarData).flatMap((group) => group.rows),
    [selectedTab],
  );

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const filteredData = data.filter((group) =>
    group.rows.some((row: CalendarRecordFragment) => {
      return selectedImpacts.includes(row.impact as Impact);
    }),
  );
  const translatedThisWeek = t(Tab.ThisWeek);

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <Tabs value={selectedTab}>
          <TabsList>
            <TabsTrigger
              onClick={() => {
                setSelectedTab(Tab.ThisWeek);
              }}
              value={Tab.ThisWeek}
            >
              {translatedThisWeek}
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <div className="flex items-center">
          <ImpactFilter
            selectedImpacts={selectedImpacts}
            setSelectedImpacts={setSelectedImpacts}
          />
        </div>
      </div>
      <Section className="p-0 sm:p-0" variant="outline">
        <Table data-testid="calendar-table">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                className="[&>*:first-child]:pl-5 [&>*:last-child]:pr-5"
                id={headerGroup.id}
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header, i) => {
                  const isLastCell = i === 6;
                  return (
                    <TableHead
                      key={header.id}
                      className={cn('w-[100px] px-1 md:px-5', {
                        'text-right': isLastCell,
                      })}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                      {header.column.getCanFilter() ? (
                        <div>
                          <input
                            onChange={() => {
                              header.column.setFilterValue(selectedImpacts);
                            }}
                            hidden
                            type="text"
                            value={selectedImpacts}
                          />
                        </div>
                      ) : null}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {filteredData.flatMap((group) => (
              <>
                <TableRow key={group.date}>
                  <TableCell colSpan={columns.length}>
                    <strong>
                      <FormattedDate
                        value={group.date}
                        dateFormat="eeee, MMMM d, yyyy"
                      />
                    </strong>
                  </TableCell>
                </TableRow>
                {group.rows
                  .filter((row) =>
                    selectedImpacts.includes(row.impact as Impact),
                  )
                  .map((row, id) => (
                    <TableRow
                      data-testid={`row-${id}`}
                      className="[&>*:first-child]:pl-5 [&>*:last-child]:pr-5"
                      key={row.id}
                    >
                      {table
                        .getRowModel()
                        .rows.find((r) => r.original.id === row.id)
                        ?.getVisibleCells()
                        .map((cell, cellIndex) => {
                          const isFourthCell = cellIndex === 3;
                          const isLastCell = cellIndex === 6;
                          return (
                            <TableCell
                              className={cn(
                                'text-nowrap px-1 text-xs md:px-5 md:text-sm',
                                { truncate: isFourthCell },
                                { 'text-right': isLastCell },
                              )}
                              align={isLastCell ? 'right' : 'left'}
                              key={cell.id}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  ))}
              </>
            ))}
          </TableBody>
          {table.getRowModel().rows.length === 0 && (
            <TableRow className="border-none">
              <TableCell
                colSpan={columns.length}
                className="whitespace-nowrap px-3 py-3 sm:px-6"
              >
                <div className="flex w-full items-center justify-center text-default-gray-950 dark:text-white">
                  <span>{t('calendar.noData')}</span>
                </div>
              </TableCell>
            </TableRow>
          )}
        </Table>
      </Section>
      <span className="flex w-full justify-end text-default-gray-600">
        {t('calendar.powered')}
      </span>
    </div>
  );
}

export function CalendarTableWithData(): JSX.Element {
  const { data, loading } = useCurrentWeekCalendarDataQuery();

  if (loading) {
    return (
      <div className="mx-auto flex h-full w-full flex-col gap-4">
        <div className="flex h-10 w-full items-center justify-between">
          <Skeleton className="h-full max-h-10 w-full max-w-48 rounded-xl" />
          <Skeleton className="h-full max-h-10 w-full max-w-24 rounded-xl" />
        </div>
        <Skeleton className="h-full max-h-32 w-full rounded-xl" />
      </div>
    );
  }

  invariant(data?.currentWeekCalendarData, `Calendar Missing`);

  return <CalendarTable calendarData={data?.currentWeekCalendarData} />;
}
