import { Button } from '@/components/base/button';
import WidgetWithImage from '@/components/base/widget-with-image';
import { useGetBrokerAccountsQuery } from '@graphql/index';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export default function NewChallenge(): JSX.Element {
  const { data: brokerAccounts } = useGetBrokerAccountsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const accounts = brokerAccounts?.me?.brokerAccounts ?? [];
  const { t } = useTranslation();

  return (
    <WidgetWithImage
      img="/images/phones.png"
      header={
        <>
          {accounts.length > 0
            ? t('newChallenge.challenge')
            : t('newChallenge.firstChallenge')}
        </>
      }
      subheader={t('newChallenge.faq')}
    >
      <Link className="flex w-full flex-col" to="/order">
        <Button>{t('newChallenge.startChallenge')}</Button>
      </Link>

      <Link to="/trial">
        <Button variant="outlined">{t('newChallenge.freeTrial')}</Button>
      </Link>
      <Button
        as="link"
        variant="link"
        className="underline dark:text-white"
        href="https://www.monevis.com/en/trading-rules"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('newChallenge.tradingRules')}
      </Button>
    </WidgetWithImage>
  );
}
