import { InvalidLink } from '@/components/(unathenticated)/invalid-link';
import { AuthLayout } from '@/components/(unathenticated)/layout/auth.layout';
import { PageWrapper } from '@/components/base/page-wrapper';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/(unathenticated)/auth/_layout/invalid-link',
)({
  beforeLoad: async ({ context }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({
        to: '/',
      });
    }
  },
  component: () => (
    <PageWrapper title="Invalid link - Monevis">
      <InvalidLinkPage />
    </PageWrapper>
  ),
});

function InvalidLinkPage(): JSX.Element {
  return (
    <AuthLayout>
      <InvalidLink />
    </AuthLayout>
  );
}
