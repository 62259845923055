import Section from '@/components/base/section';
import {
  type MonevisAccountTradingDiaryFragment,
  type MonevisAnalysisRuleFragment,
} from '@graphql/index';
import invariant from 'tiny-invariant';
import { ChartComponent } from './chart';

export function StatsChart({
  analysis,
  diary,
  currentBalance,
  currentEquity,
}: {
  analysis: (MonevisAnalysisRuleFragment | null)[];
  diary: (MonevisAccountTradingDiaryFragment | null)[] | undefined;
  currentBalance: number;
  currentEquity: number;
}): JSX.Element {
  const categories =
    diary?.map((item) => {
      return `${item?.day}.${item?.month}.${item?.year}`;
    }) ?? [];
  const startingBalances = diary?.map((item) => item?.startingBalance);
  const startingEquities = diary?.map((item) => item?.startingEquity);
  startingBalances?.splice(-1, 1, currentBalance);
  startingEquities?.splice(-1, 1, currentEquity);

  const tradingData = [
    {
      name: 'Equity',
      data: startingEquities,
    },
    {
      name: 'Balance',
      data: startingBalances,
    },
  ];

  const balanceData = analysis.find((item) => {
    return item?.identifier === 'TargetReturn';
  });
  const dailyDrawdownData = analysis.find((item) => {
    return item?.identifier === 'MaxBalanceDailyDrawdown';
  });
  const totalDrawdownData = analysis.find((item) => {
    return item?.identifier === 'MaxStaticDrawdown';
  });

  // invariant(balanceData, 'Balance data must be defined');
  invariant(dailyDrawdownData, 'Daily drawdown data must be defined');
  invariant(totalDrawdownData, 'Rotal drawdown data must be defined');
  return (
    <Section variant="secondary" className="h-full p-1 sm:p-1">
      <div
        className="flex h-full w-full flex-col justify-center"
        data-testid="statistics-chart"
      >
        {/* <div className="flex flex-wrap justify-between">
          <TabSelector
            selectedValue={selectedTab}
            onClick={(value) => {
              setSelectedTab(value as string);
            }}
            data={Object.values(chartContentType)}
          />
        </div>*/}
        <ChartComponent
          limit={totalDrawdownData.targetAbsoluteValue}
          target={balanceData?.targetAbsoluteValue}
          dailyLimit={dailyDrawdownData.targetAbsoluteValue}
          series={tradingData}
          categories={categories}
        />
      </div>
    </Section>
  );
}
