import { zodResolver } from '@hookform/resolvers/zod';
import { type ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

const orderSchema = z.object({
  firstName: z.string().min(1, { message: 'First name is required' }),
  lastName: z.string().min(1, { message: 'Last name is required' }),
  email: z.string().min(1, { message: 'Email is required' }).email(),
  city: z.string().min(1, { message: 'City is required' }),
  street: z.string().min(1, { message: 'Street is required' }),
  zip: z.string().min(1, { message: 'Postal code is required' }),
  countryCode: z.string().min(1, { message: 'Country is required' }),
  password: z.string().optional(),
  termsPolicy: z.literal<boolean>(true),
  refundPolicy: z.literal<boolean>(true),
});

export type Order = z.infer<typeof orderSchema>;

export function OrderFormWrapper({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const methods = useForm<Order>({
    resolver: zodResolver(orderSchema),
    mode: 'onChange',
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
}
