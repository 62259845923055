import { type PropsWithChildren } from 'react';

export function MainWrapper({
  children,
  title,
}: PropsWithChildren & { title?: string }): JSX.Element {
  return (
    <div className="h-full w-full">
      {title && (
        <div className="border-b border-default-gray-300 p-6 dark:border-default-gray-900">
          <h1 className="text-xl">{title}</h1>
        </div>
      )}
      <div className="h-full p-6">{children}</div>
    </div>
  );
}
