import { cn } from '@/utils';
import { cva, type VariantProps } from 'class-variance-authority';

const VerticalDividerVariants = cva(
  'bg-divider-900 rounded-2xl h-full min-h-6',
  {
    variants: {
      size: {
        default: 'w-[1px]',
        lg: 'w-[2px]',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
);

interface VerticalDividerProps
  extends VariantProps<typeof VerticalDividerVariants> {
  className?: string;
}

export function VerticalDivider({
  size,
  className,
}: VerticalDividerProps): JSX.Element {
  return <div className={cn(VerticalDividerVariants({ size, className }))} />;
}
