import { Badge } from '@/components/base/badge';
import { TableBadge } from '@/components/base/table-badge';
import { faHourglassStart } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrderStatus, type OrderResponseFragment } from '@graphql/index';
import { Link } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { type IntlShape } from 'react-intl';

const statusBadgeVariant: Record<OrderStatus, 'default' | 'yellow' | 'green'> =
  {
    [OrderStatus.Created]: 'yellow',
    [OrderStatus.Completed]: 'green',
    [OrderStatus.Provisioning]: 'default',
  };
const columnHelper = createColumnHelper<OrderResponseFragment>();

export const defaultColumns = (
  intl: IntlShape,
  { t }: { t: (key: string) => string },
) => [
  columnHelper.accessor('createdAt', {
    id: 'createdAt',
    header: () => t('table.Date'),
    cell: (info) => {
      const { createdAt, orderNumber } = info.row.original;
      const dateDisplay = createdAt
        ? (() => {
            const date = new Date(createdAt);
            return !isNaN(date.getTime())
              ? format(date, 'MMM d, yyyy HH:mm:ss')
              : t('table.InvalidDate');
          })()
        : t('table.InvalidDate');

      return (
        <div>
          <div className="text-white">{dateDisplay}</div>
          <div className="text-xs">{orderNumber}</div>
        </div>
      );
    },
    enableGlobalFilter: false,
    enableColumnFilter: false,
    sortingFn: 'datetime',
  }),
  columnHelper.accessor('initialBalance', {
    id: 'initialBalance',
    header: () => t('table.InitialBalance'),
    cell: (info) => {
      const { initialBalance } = info.row.original;
      return (
        <span className="text-default-gray-950 dark:text-white">
          {intl.formatNumber(initialBalance, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          })}
        </span>
      );
    },
    enableGlobalFilter: false,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('total', {
    id: 'display',
    header: t('table.Display'),
    cell: (info) => {
      const { total, currency } = info.row.original;
      const validCurrency = currency ?? 'EUR';
      return (
        <div className="flex flex-col gap-1">
          <span className="text-default-gray-950 dark:text-white">
            {intl.formatNumber(total, {
              style: 'currency',
              currency: validCurrency,
              maximumFractionDigits: 2,
            })}
          </span>
        </div>
      );
    },
    enableGlobalFilter: false,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: () => t('table.Status'),
    cell: (info) => {
      const { status } = info.row.original;
      const badgeVariant = statusBadgeVariant[status] || 'default';

      if (status === OrderStatus.Created) {
        return (
          <Badge variant="yellow">
            <FontAwesomeIcon icon={faHourglassStart} className="h-4 w-4 p-1" />
          </Badge>
        );
      }

      return <TableBadge variant={badgeVariant}>{status}</TableBadge>;
    },
    enableGlobalFilter: false,
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.orderNumber, {
    id: 'payNow',
    header: () => t('table.PayNow'),
    cell: (info) => {
      const { orderNumber, status } = info.row.original;
      if (status === OrderStatus.Created) {
        return (
          <div>
            <Link
              className="rounded-lg border border-blue-500 bg-blue-500/50 p-2 text-white"
              to={`/order/pay/${orderNumber}`}
              params={{ 'order-id': orderNumber }}
            >
              {t('table.PayNow')}
            </Link>
          </div>
        );
      }

      return null;
    },
    enableGlobalFilter: false,
    enableColumnFilter: false,
  }),
  // columnHelper.display({
  //   id: 'invoice',
  //   header: 'Invoice',
  //   cell: (info) => {
  //     const { id } = info.row.original;
  //     return (
  //       <a
  //         className="text-default-gray-950 dark:text-white"
  //         rel="noopener"
  //         target="_blank"
  //         href={https://certificates.monevis.com/api/certificate?id=${id}&output=image}
  //         download="certificate.png"
  //       >
  //         <FontAwesomeIcon className="h-4" icon={faArrowDownToBracket} />
  //       </a>
  //     );
  //   },
  // }),
  //
];
