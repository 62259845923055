import type { SVGProps } from 'react';

export default function SvgRiseLogo(
  props: SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={115}
      height={40}
      fill="none"
      viewBox="0 0 90 60"
      {...props}
    >
      <path
        fill="#5E59EC"
        d="M20.662 1.443c-10.207 0-18.484 8.307-18.484 18.554s8.274 18.557 18.484 18.557c10.206 0 18.483-8.308 18.483-18.557 0-10.247-8.274-18.554-18.483-18.554m5.23 27.802-.037-11.553L14.33 29.248l-5.646-.003L23.122 14.76h-5.279a2.967 2.967 0 0 0-2.34 4.778l-2.83 2.848a6.96 6.96 0 0 1-1.78-4.654c0-3.847 3.115-6.978 6.95-6.978h12.04v18.493z"
      />
      <path
        className="fill-[#1C1F32] dark:fill-white"
        d="M53.664 31.922h-5.586V13.08h5.586v4.558c.966-3.51 3.376-4.962 5.304-4.962 1.327 0 2.33.241 3.214.687l-1.003 5.042c-1.004-.484-1.97-.566-2.854-.566-3.214 0-4.66 2.543-4.66 7.506zM67.848 2.83c1.887 0 3.416 1.41 3.416 3.267s-1.526 3.31-3.416 3.31c-1.888 0-3.496-1.453-3.496-3.31S65.96 2.83 67.848 2.83m2.774 29.091h-5.545V13.08h5.545zM80.628 24.175c-5.627-1.533-5.665-4.397-5.627-6.133.041-2.785 3.375-5.366 7.315-5.366 3.736 0 7.676 1.653 7.717 6.253h-5.266c-.041-1.411-1.245-1.653-2.131-1.653-1.207 0-2.011.566-1.97 1.412.041.565.523 1.128 1.647 1.411l3.176.849c4.54 1.25 5.105 4.276 5.105 5.487 0 3.993-4.18 5.891-8.12 5.891-3.819 0-7.796-2.299-7.837-6.495h5.225c.04 1.09 1.244 2.178 2.774 2.178 1.244-.041 2.29-.604 2.29-1.574 0-.807-.482-1.25-1.85-1.615zM111.773 28.492c-1.887 2.823-5.387 3.834-8.56 3.834-5.386 0-9.807-3.955-9.807-9.884 0-5.932 4.421-9.763 9.807-9.763 5.346 0 9.605 3.993 9.605 9.925 0 .483-.041 1.25-.079 1.736H98.996c.281 2.178 2.653 3.147 4.62 3.147 1.526 0 3.176-.566 4.1-1.774zm-4.582-7.665c-.282-2.299-2.372-3.227-3.94-3.227-1.567 0-3.698.849-4.18 3.227z"
      />
    </svg>
  );
}
