import type { SVGProps } from 'react';

export default function SvgSlovakia(
  props: SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
      <path fill="#204c9e" d="M1 11h30v10H1z" />
      <path
        fill="#fff"
        d="M5 4h22c2.208 0 4 1.792 4 4v4H1V8c0-2.208 1.792-4 4-4"
      />
      <path
        fill="#db362f"
        d="M27 28H5c-2.208 0-4-1.792-4-4v-4h30v4c0 2.208-1.792 4-4 4"
      />
      <path
        d="M27 4H5a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h22a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4m3 20c0 1.654-1.346 3-3 3H5c-1.654 0-3-1.346-3-3V8c0-1.654 1.346-3 3-3h22c1.654 0 3 1.346 3 3z"
        opacity={0.15}
      />
      <path
        fill="#fff"
        d="M27 5H5a3 3 0 0 0-3 3v1a3 3 0 0 1 3-3h22a3 3 0 0 1 3 3V8a3 3 0 0 0-3-3"
        opacity={0.2}
      />
      <path
        fill="#db362f"
        d="M15.65 10.232s.178 1.784.178 5.353c-.025 3.645-2.751 5.49-4.752 6.46-2.001-.97-4.726-2.815-4.752-6.46 0-3.569.178-5.353.178-5.353z"
      />
      <path
        fill="#fff"
        d="M10.673 19.945v-4.462c-1.511 0-1.94.134-2.392.27a7.5 7.5 0 0 0 0-1.371c.452.137.881.27 2.392.27v-1.24c-.477 0-1.135.019-1.892.277a7.3 7.3 0 0 0 0-1.374c.757.254 1.415.274 1.892.274 0-.359-.111-1.132-.251-1.772q.655.059 1.31 0c-.14.639-.251 1.412-.251 1.772.477 0 1.135-.019 1.892-.274a7.3 7.3 0 0 0 0 1.374c-.757-.258-1.415-.277-1.892-.277v1.24c1.511 0 1.94-.134 2.392-.27a7.5 7.5 0 0 0 0 1.371c-.452-.137-.881-.27-2.392-.27v4.462z"
      />
      <path
        fill="none"
        d="M15.65 10.232s.178 1.784.178 5.353c-.025 3.645-2.751 5.49-4.752 6.46-2.001-.97-4.726-2.815-4.752-6.46 0-3.569.178-5.353.178-5.353z"
      />
      <path
        fill="#204c9e"
        d="M11.076 22.044c1.241-.602 2.761-1.542 3.742-3.038q-.01-.023-.017-.045c-.56-1.184-1.576-1.367-2.268-.409a3.3 3.3 0 0 0-.304-.565c-.805-1.181-1.973-.929-2.609.565-.287-.397-.645-.614-1.014-.614-.518 0-.973.424-1.268 1.072.982 1.494 2.499 2.432 3.739 3.034Z"
      />
      <path
        fill="#fff"
        d="m11.076 22.235-.075-.036c-1.348-.654-2.385-1.395-3.169-2.266-1.103-1.225-1.668-2.687-1.679-4.347 0-3.537.177-5.353.179-5.371l.015-.155h9.459l.015.155c.002.018.179 1.834.179 5.37-.012 1.661-.577 3.123-1.679 4.348-.785.871-1.821 1.613-3.169 2.266zM6.66 10.404c-.041.505-.164 2.246-.164 5.181.011 1.572.546 2.958 1.591 4.118.74.822 1.718 1.526 2.989 2.151 1.271-.625 2.249-1.329 2.989-2.151 1.045-1.16 1.58-2.546 1.591-4.119 0-2.934-.122-4.675-.164-5.18z"
      />
    </svg>
  );
}
