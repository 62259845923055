import { Button } from '@/components/base/button';
import { Input } from '@/components/base/form/input';
import { PageHeading } from '@/components/base/page-heading';
import { useTranslation } from 'react-i18next';

export function ChooseAmount({
  withdrawable,
  eligible,
  amount,
  onChange,
  error,
}: {
  withdrawable: number;
  eligible: boolean;
  amount: number;
  error?: string;
  onChange: (values: number) => void;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <div>
      <PageHeading subheading={t('payout.chooseSubheading')} />
      <div>
        <Input
          data-testid="select-amount-input"
          disabled={eligible}
          variant="secondary"
          inputSize="sm"
          min={0}
          icon={
            <Button
              onClick={() => {
                onChange(Number(withdrawable));
              }}
              type="button"
              size="xsmall"
              data-testid="max-amount-button"
            >
              {t('payout.max')}
            </Button>
          }
          onChange={(e) => {
            onChange(Number(e.target.value));
          }}
          type="number"
          value={amount}
          max={Number(withdrawable)}
        />
      </div>
      <span className="text-danger">{error}</span>
    </div>
  );
}
