import { Button } from '@/components/base/button';
import { FormField } from '@/components/base/form/form-field';
import { Input } from '@/components/base/form/input';
import { faFloppyDisk } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useChangePasswordMutation } from '@graphql/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'notistack';
import { type FormEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const passwordSchema = z
  .object({
    oldPassword: z.string().min(1, {
      message: 'Old password is required',
    }),
    newPassword: z.string().min(1, {
      message: 'New password is required',
    }),
    confirmPassword: z.string().min(1, {
      message: 'Confirm password is required',
    }),
  })
  .refine((data) => data.confirmPassword === data.newPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword'],
  });

type Password = z.infer<typeof passwordSchema>;

export function EditPassword({
  onSubmit,
  loading,
}: {
  loading: boolean;
  onSubmit: (values: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => Promise<void>;
}): JSX.Element {
  const { t } = useTranslation();

  const methods = useForm<Password>({
    resolver: zodResolver(passwordSchema),
    mode: 'onChange',
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const { handleSubmit } = methods;

  function handleClickSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    return void (async () => {
      handleSubmit((v) => {
        onSubmit(v);
      })();
    })();
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleClickSubmit}>
        <div className="flex flex-col gap-6">
          <fieldset>
            <FormField
              labelClassName="dark:text-white text-default-gray-950"
              label={t('settings.oldPasswordLabel')}
              name="oldPassword"
              error-data-testid="old-password-error"
              data-testid="old-password-field"
            >
              <Input
                data-testid="old-password"
                type="password"
                variant="secondary"
                placeholder={t('settings.oldPasswordPlaceholder')}
              />
            </FormField>
          </fieldset>

          <fieldset>
            <FormField
              labelClassName="dark:text-white text-default-gray-950"
              label={t('settings.newPasswordLabel')}
              name="newPassword"
              error-data-testid="new-password-error"
              data-testid="new-password-field"
            >
              <Input
                data-testid="new-password"
                type="password"
                variant="secondary"
                placeholder={t('settings.newPasswordPlaceholder')}
              />
            </FormField>
          </fieldset>

          <fieldset>
            <FormField
              labelClassName="dark:text-white text-default-gray-950"
              label={t('settings.confirmPasswordLabel')}
              name="confirmPassword"
              error-data-testid="confirm-password-error"
              data-testid="confirm-password-field"
            >
              <Input
                data-testid="confirm-password"
                type="password"
                variant="secondary"
                placeholder={t('settings.confirmPasswordPlaceholder')}
              />
            </FormField>
          </fieldset>

          <div className="flex w-full justify-end">
            <Button
              data-testid="submit"
              as="button"
              className="w-full md:w-fit"
              loading={loading}
              type="submit"
              icon={<FontAwesomeIcon icon={faFloppyDisk} />}
            >
              {t('settings.saveChangesButton')}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export function EditPasswordWithMuation(): JSX.Element {
  const [changePassword, { loading }] = useChangePasswordMutation();
  const { enqueueSnackbar } = useSnackbar();

  async function submit(values: Password): Promise<void> {
    try {
      await changePassword({
        variables: {
          payload: {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          },
        },
      });
      enqueueSnackbar({
        message: 'Password changed successfully',
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar({
          message: e.message,
          variant: 'error',
        });
      }
    }
  }
  return <EditPassword loading={loading} onSubmit={submit} />;
}
