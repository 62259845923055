import { FormattedDate } from '@/components/base/formatted-date';
import { TableBadge } from '@/components/base/table-badge';
import { type TransactionFragment } from '@graphql/index';
import { createColumnHelper } from '@tanstack/react-table';
import { type IntlShape } from 'react-intl';

export enum PayoutStatus {
  Failed = 'FAILED',
  PendingVerification = 'PENDING_VERIFICATION',
  Completed = 'COMPLETED',
}

export enum PayoutType {
  Withdrawal = 'WITHDRAWAL',
  Deposit = 'DEPOSIT',
}
interface StatusInfo {
  title: string;
  variant: 'green' | 'yellow' | 'red' | 'default';
}

const columnHelper = createColumnHelper<TransactionFragment>();
export const defaultColumns = (
  intl: IntlShape,
  { t }: { t: (key: string) => string },
) => [
  columnHelper.accessor('createdAt', {
    id: 'createdAt',
    header: () => t('table.Date'),
    cell: (info) => <FormattedDate value={info.getValue()} />,
    enableGlobalFilter: false,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('id', {
    id: 'id',
    header: () => t('table.PayoutID'),
    cell: (info) => info.getValue(),
    enableGlobalFilter: false,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: () => t('table.Status'),
    cell: (info) => {
      const status = info.getValue()!;

      const statusMap: { [key in PayoutStatus]: StatusInfo } = {
        [PayoutStatus.Completed]: {
          title: t('table.Success'),
          variant: 'green',
        },
        [PayoutStatus.Failed]: { title: t('table.Failed'), variant: 'red' },
        [PayoutStatus.PendingVerification]: {
          title: t('table.Pending'),
          variant: 'yellow',
        },
      };
      const { title, variant } = statusMap[status as PayoutStatus] || {
        title: '',
        variant: 'default',
      };

      return <TableBadge variant={variant}>{title}</TableBadge>;
    },
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('amount', {
    id: 'amount',
    header: () => t('table.Amount'),
    cell: (info) =>
      intl.formatNumber(info.getValue(), {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }),
  }),

  // columnHelper.display({
  //   id: 'certificate',
  //   header: () => t('table.Certificate'),
  //   cell: (props) => (
  //     <a
  //       className="text-white"
  //       rel="noopener"
  //       target="_blank"
  //       // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- .
  //       href={`https://certificates.monevis.com/certificate/${props.row.getValue('id')}`}
  //       download="certificate.png"
  //     >
  //       <FontAwesomeIcon className="h-4" icon={faArrowUpRightFromSquare} />
  //     </a>
  //   ),
  // }),
];
