import { faArrowLeft, faHouse } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../base/button';
import { Logo, LogoVariant } from '../base/logo';
import { NavLink } from '../base/nav-link';

export function Header({
  showGoBackOption = true,
  onClickBack,
  arrowBackText,
}: {
  showGoBackOption?: boolean;
  onClickBack: () => void;
  arrowBackText: string;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col gap-3 sm:gap-6">
      <div className="flex w-full items-center justify-between text-base text-default-gray-400">
        <div className="flex w-[37px] items-center sm:w-[86px]">
          {showGoBackOption && (
            <Button
              as="button"
              icon={<FontAwesomeIcon icon={faArrowLeft} />}
              onClick={onClickBack}
              variant="link"
              className="p-2 text-2xl hover:underline sm:text-base"
            >
              <span className="hidden sm:block">{arrowBackText}</span>
            </Button>
          )}
        </div>

        <div className="flex h-6 items-center justify-center text-white sm:h-10">
          <Logo variant={LogoVariant.Monevis} />
        </div>

        <div className="h-fit">
          <NavLink
            asChild
            className="flex h-fit p-2 text-base text-default-gray-400"
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.monevis.com/sk"
            >
              <FontAwesomeIcon className="block h-6 sm:hidden" icon={faHouse} />
              <span className="hidden sm:block">
                {t('formLayout.homepage')}
              </span>
            </a>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export function FormLayout({
  children,
  onClickBack,
  showGoBackOption = true,
  arrowBackText,
  subtitleText,
  link,
}: {
  children: ReactNode;
  showGoBackOption?: boolean;
  onClickBack: () => void;
  arrowBackText: string;
  subtitleText?: string;
  link: ReactNode;
}): JSX.Element {
  return (
    <div className="flex h-full w-full flex-col items-center justify-between gap-3">
      <div className="flex w-full flex-col gap-3 sm:gap-6">
        <Header
          onClickBack={onClickBack}
          showGoBackOption={showGoBackOption}
          arrowBackText={arrowBackText}
        />
      </div>

      <div className="flex h-fit w-full max-w-md flex-col gap-5">
        {/*TOP TEXT */}
        <div className="mb-8 flex flex-col items-center gap-1">
          <h1 className="text-2xl font-bold">{subtitleText}</h1>
        </div>

        {/*MIDDLE CONTENT (form)*/}
        <div>{children}</div>
        <div className="mx-auto w-fit text-sm">{link}</div>
      </div>
      <div />
    </div>
  );
}
