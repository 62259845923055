import { UserCertificatesPage } from '@/components/(authenticated)/user-certificates';
import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { usePageLayout } from '@/context/layout-context';
import { useTranslation } from 'react-i18next';

import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/user-certificates',
)({
  component: () => (
    <PageWrapper title="Certificates - Monevis">
      <HomePage />
    </PageWrapper>
  ),
});

function HomePage(): JSX.Element {
  const { t } = useTranslation();
  usePageLayout({ title: t('userCertificatesPage.title') });

  return (
    <ScreenContainer className="justify-between">
      <UserCertificatesPage />
    </ScreenContainer>
  );
}

export default HomePage;
