import { FormCheckbox } from '@/components/base/form/form-checkbox';
import { useAppConfig } from '@/context/app-config-context';
import { type InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

interface TermsCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  description?: string;
  'data-testid'?: string;
}

export function TermsCheckbox({
  name,
  description,
  'data-testid': dataTestId = 'terms-checkbox',
  ...props
}: TermsCheckboxProps): JSX.Element {
  const { t } = useTranslation();
  const {
    AppConfig: { TERMS_CONDITIONS_URL, PRIVACY_POLICY_URL },
  } = useAppConfig();

  return (
    <div>
      <FormCheckbox data-testid={dataTestId} {...props} name={name}>
        {description}{' '}
        <a
          className="underline underline-offset-2"
          rel="noopener"
          target="_blank"
          href={TERMS_CONDITIONS_URL}
        >
          {t('checkobox.termsOfUse')}
        </a>
        <span> {t('checkobox.and')} </span>
        <a
          target="_blank"
          rel="noreferrer"
          href={PRIVACY_POLICY_URL}
          className="underline underline-offset-2"
        >
          {t('checkobox.privacyPolicy')}
        </a>
      </FormCheckbox>
    </div>
  );
}
